import { SyntheticEvent, forwardRef, useEffect, useRef, useState } from 'react';
import '../App.css';
import { Autocomplete, Backdrop, Box, Button, Slide, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, MenuItem, Stack, Tab, Tabs, TextField, ThemeProvider, Tooltip, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import Pagination from '@mui/material/Pagination';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import UploadIcon from '@mui/icons-material/Upload';
import FolderIcon from '@mui/icons-material/Folder';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DiskSize from '../components/DiskSize';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import OutboxIcon from '@mui/icons-material/Outbox';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Save from '@mui/icons-material/Save';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TransitionProps } from '@mui/material/transitions';
import ArchiveFileUpload from '../components/ArchiveFileUpload';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


import { useParams } from 'react-router-dom';

const APIURL = process.env.REACT_APP_APIURL;
const poname = localStorage.getItem('poname') || "";
const pouser = localStorage.getItem('pouser') || "";
const pouserid = localStorage.getItem('pouserid') || 0;
const poteam = localStorage.getItem('poteamname') || "";
const poteamid = localStorage.getItem('poteamid') || 0;
const potoken = localStorage.getItem("potoken") || "";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        fontFamily: [
            '"Bebas Neue"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        body1: {
            fontFamily: "sans-serif",
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// interface Option {
//     id: number;
//     label: string;
//   }


interface Option {
    id: string;
    label: string;
}

function ArchiveDetails(this: any) {
    const [isLoading, setisLoading] = useState(true);
    const { archhash } = useParams();
    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        document.title = 'Saksarkiv - ParaOffice';
        // setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);






    // const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    //     event.preventDefault();

    //     const dataTransfer = event.dataTransfer;
    //     const files = dataTransfer.files;

    //     if (files.length > 0) {
    //         const file = files[0];
    //         if (file.type === 'message/rfc822') {
    //             parseEmail(file);
    //         } else {
    //             setErrorMessage('Invalid file type. Please drop an email in RFC822 format.');
    //         }
    //     }
    // };

    // const parseEmail = (file: File) => {
    //     const reader = new FileReader();

    //     reader.onload = (e: ProgressEvent<FileReader>) => {
    //         const emailContent = e.target?.result as string;
    //         // Process the email content as needed
    //         console.log(emailContent);
    //         setErrorMessage(null);
    //     };

    //     reader.readAsText(file);
    // };













    const duetoday = new Date();
    const duedate = duetoday.setDate(duetoday.getDate() + 3);
    const duedatedefault = new Date(duedate).toISOString().split('T')[0] // yyyy-mm-dd

    const today = new Date();
    const todaydate = today.setDate(today.getDate());
    const todaydatedefault = new Date(todaydate).toISOString().split('T')[0] // yyyy-mm-dd

    const [archivenew, setArchiveNew] = useState<any[]>([]);
    const [archiveall, setArchiveAll] = useState<any[]>([]);
    const [archiveCase, setArchiveCase] = useState<any[]>([]);
    const [activecase, setActiveCase] = useState("");

    const [archivePreview, setArchivePreview] = useState<any[]>([]);
    const [modalPreviewFile, setModalPreviewFile] = useState(false);
    const [modalNewArchive, setModalNewArchive] = useState(false);


    const [archiveLastMailButton, setArchiveLastMailButton] = useState(false);


    const [archiveCategories, setArchiveCategories] = useState<any[]>([]);
    const [archiveCategory, setArchiveCategory] = useState("");
    const [archiveCategoryId, setArchiveCategoryId] = useState(null);
    const [archiveStatuses, setArchiveStatuses] = useState<any[]>([]);
    const [archiveStatus, setArchiveStatus] = useState("");
    const [activeDocument, setActiveDocument] = useState<any[]>([]);
    const [archiveStatusId, setArchiveStatusId] = useState(null);
    const [archiveUser, setArchiveUser] = useState("");
    const [archiveUserId, setArchiveUserId] = useState(0);
    const [archiveTeam, setArchiveTeam] = useState("");
    const [archiveTeamId, setArchiveTeamId] = useState(0);
    const [archiveAccessName, setArchiveAccessName] = useState("");
    const [archiveAccessId, setArchiveAccessId] = useState(null);
    const [archiveAccess, setArchiveAccess] = useState("");
    const [archiveDocumentType, setArchiveDocumentType] = useState(null);
    const [archiveDocumentTypeId, setArchiveDocumentTypeId] = useState("");

    const [archiveAccessList, setArchiveAccessList] = useState<any[]>([]);
    const [archiveUserList, setArchiveUserList] = useState<any[]>([]);
    const [archiveTeamList, setArchiveTeamList] = useState<any[]>([]);
    const [locationList, setLocationList] = useState<any[]>([]);
    const [myArchive, setMyArchive] = useState<any[]>([]);
    const [archiveDocumentTypeOptions, setArchiveDocumentTypeOptions] = useState<any[]>([]);


    const [optionstxttype, setoptionstxttype] = useState<any[]>([]);
    const [newTXTType, setNewTXTType] = useState<any[]>([]);

    // const customIsOptionEqualToValue = (label: Option, id: Option) => id.id == archiveContactId;

    const [archiveContact, setArchiveContact] = useState("");
    const [archiveContactId, setArchiveContactId] = useState(0);

    const [AssignedToMe, setAssignedToMe] = useState(true);
    const [activePage, setActivePage] = useState("all");

    const [archiveLastMail, setArchiveLastMail] = useState<any[]>([]);


    const [modalArchiveContact, setModalArchiveContact] = useState(false);
    const [modalArchiveLocation, setModalArchiveLocation] = useState(false);
    const [modalArchiveEvent, setModalArchiveEvent] = useState(false);
    const [modalWipeArchiveLocation, setModalWipeArchiveLocation] = useState(false);
    const [modalArchiveSendEmail, setmodalArchiveSendEmail] = useState(false);
    const [modalArchiveNewTXT, setmodalArchiveNewTXT] = useState(false);
    const [modalUploadFiles, setmodalUploadFiles] = useState(false);
    const [modalEditDocument, setModalEditDocument] = useState(false);
    const [modalWipeArchiveEvent, setModalWipeArchiveEvent] = useState(false);





    const [modalArchiveEdit, setModalArchiveEdit] = useState(false);
    const [modalWipeArchiveContact, setModalWipeArchiveContact] = useState(false);

    const [TabValue, setTabValue] = useState(0);
    const [contacts, setContacts] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>([]);

    const defaultContact: Option | null = contacts.find(contacts => contacts.id === archiveContactId) || null;

    const [newContact, setnewContact] = useState(0);
    const [newEvent, setnewEvent] = useState(0);
    const [filterContact, setFilterContact] = useState(0);
    const [filterStatus, setFilterStatus] = useState(0);
    const [filterCategory, setFilterCategory] = useState(0);
    const [filterUserId, setfilterUserId] = useState(0);
    const [filterTeamId, setfilterTeamId] = useState(0);
    const [newDocContact, setNewDocContact] = useState(0);

    const [newLocation, setnewLocation] = useState(0);

    const [newArchiveCategory, setnewArchiveCategory] = useState(0);

    const [sendEmailText, setsendEmailText] = useState("\n\n" + "Med vennlig hilsen,\n" + poname + "\n" + poteam + "\n\n");

    const cancelTokenSourceRef = useRef(axios.CancelToken.source());

    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleFileUpload = (
        file: File,
        onUploadProgress: (event: ProgressEvent) => void,
        onUploadSuccess: () => void,
        onUploadError: (error: string) => void
    ) => {
        const formData = new FormData();
        formData.append("token", potoken);
        formData.append("archhash", activecase);
        formData.append("file", file);
        axios({
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            url: "https://poarch01.paraoffice.com/upload.php",
            onUploadProgress,
            cancelToken: cancelTokenSourceRef.current.token
        })
            .then(() => {
                onUploadSuccess();
            })
            .catch((err: Error) => {
                onUploadError(err.message);
            });
    };

    const handleFileCancel = () => {
        cancelTokenSourceRef.current.cancel("Upload cancelled");
    };




    async function gettxtoptions() {
        await axios.get(APIURL + "/v4/archtxtoptions/options", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setoptionstxttype(response.data);
            })
    }


    const handleMenuPage = async (page: string) => {
        await localStorage.setItem('archivecase', "");
        await setActiveCase("");
        await setActivePage(page);
        if (page == "all") {
            localStorage.setItem('archivecase', "");
            setActiveCase("");
            setFilterStatus(0);
            setfilterUserId(0);
            setfilterTeamId(0);
        }
        if (page == "assigned") {
            localStorage.setItem('archivecase', "");
            setActiveCase("");
            setFilterStatus(0);
            setfilterUserId(Number(pouserid));
            setfilterTeamId(0);
        }
        if (page == "group") {
            localStorage.setItem('archivecase', "");
            setActiveCase("");
            setFilterStatus(0);
            setfilterUserId(0);
            setfilterTeamId(Number(poteamid));
        }
        if (page == "new") {
            localStorage.setItem('archivecase', "");
            setActiveCase("");
            setFilterStatus(1);
            setfilterUserId(0);
            setfilterTeamId(0);
        }
    };

    const handleOpenCase = (archivehash: any) => {
        window.location.href = '/archive/' + archivehash;
        // setisLoading(true);
        // setActiveCase(archivehash);
        // getArchiveCase(archivehash);
        // localStorage.setItem('archivecase', archivehash);
    }
    const handleCloseCase = async () => {
        localStorage.removeItem('archivecase');
        setActiveCase("");
        window.location.assign("/archive");
        // setActivePage("all");
    }

    const ChangeEmailValue = (e: any) => {
        setsendEmailText(e);
    }



    const handleOpenCaseFilePreview = (documenthash: any) => {
        getArchivePreview(documenthash);
        setModalPreviewFile(true);
    }

    const handleOpenModalEditDocument = async (documenthash: any) => {
        setisLoading(true);
        await getArchiveDocumentTypes();
        await axios.get(APIURL + "/v4/archivedocument/" + documenthash, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setActiveDocument(response.data);
                    setisLoading(false);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
        setModalEditDocument(true);
        setisLoading(false);
    }

    const handleCloseModalEditDocument = () => {
        setModalEditDocument(false);
    }



    const handleSelectNewEvent = (event: any, values: any) => {
        setnewEvent(values['id']);
    }
    const handleSelectNewContact = (event: any, values: any) => {
        setnewContact(values['id']);
    }
    const handleSelectFilterContact = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setFilterContact(values['id']);
        }
        else {
            setFilterContact(0);
        }
        setisLoading(false);
    }

    const handleSelectFilterStatus = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setFilterStatus(values['id']);
        }
        else {
            setFilterStatus(0);
        }
        setisLoading(false);
    }

    const handleSelectFilterCategory = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setFilterCategory(values['id']);
        }
        else {
            setFilterCategory(0);
        }
        setisLoading(false);
    }



    const handleSelectNewLocation = (event: any, values: any) => {
        setnewLocation(values['id']);
    }

    const handleSelectNewArchiveCategory = (event: any, values: any) => {
        setnewArchiveCategory(values['id']);
    }




    const submitNewContact = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalArchiveContact(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            contactid: newContact,
        };
        axios.put(APIURL + "/v3/archivecontact/" + activecase, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    const submitNewLocation = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalArchiveLocation(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            locationid: newLocation,
        };
        axios.put(APIURL + "/v4/arch/" + activecase + " /location", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    const submitNewEvent = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalArchiveEvent(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            eventid: newEvent,
        };
        axios.put(APIURL + "/v4/arch/" + activecase + " /event", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    const submitWipeArchiveEvent = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalWipeArchiveEvent(false);
        setisLoading(true);
        await axios.delete(APIURL + "/v3/archiveevent/" + activecase, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const submitWipeArchiveContact = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalWipeArchiveContact(false);
        setisLoading(true);
        await axios.delete(APIURL + "/v3/archivecontact/" + activecase, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const submitWipeArchiveLocation = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalWipeArchiveLocation(false);
        setisLoading(true);
        await axios.delete(APIURL + "/v4/arch/" + activecase + "/location", { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }



    const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        localStorage.setItem('archivetab', newValue.toString());
    };

    function getArchivePreview(documenthash: string) {
        axios.get(APIURL + "/v3/archivedocumenttxt/" + documenthash, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchivePreview(response.data);
                    getArchiveCase(activecase);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function getDocument(documenthash: string) {
        axios.get(APIURL + "/v4/archivedocument/" + documenthash, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setActiveDocument(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }


    function getArchiveCategories() {
        axios.get(APIURL + "/v4/archcategory/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveCategories(response.data);
                    console.log(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function getArchiveStatuses() {
        axios.get(APIURL + "/v4/archstatus/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveStatuses(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }



    function getUsers() {
        axios.get(APIURL + "/v4/user/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveUserList(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function getAccess() {
        axios.get(APIURL + "/v4/archaccess/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveAccessList(response.data);
                    console.log(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }
    function getTeams() {
        axios.get(APIURL + "/v4/team/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveTeamList(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function getArchiveDocumentTypes() {
        axios.get(APIURL + "/v4/archivedocumenttypeoptions", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveDocumentTypeOptions(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }


    function getlocations() {
        axios.get(APIURL + "/v4/options/location", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setLocationList(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    const submitUpdateArchive = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalArchiveEdit(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            archdate: data.get('archdate'),
            archdatedue: data.get('archdatedue'),
            archusername: archiveUser,
            archuserid: archiveUserId,
            archteamname: archiveTeam,
            archteamid: archiveTeamId,
            archcategoryname: archiveCategory,
            archcategoryid: archiveCategoryId,
            archstatusname: archiveStatus,
            archstatusid: archiveStatusId,
            archtitle: data.get('archtitle'),
            archdescription: data.get('archdescription'),
            archpublic: data.get('archpublic'),
            archaccessid: archiveAccessId,
            archaccessname: archiveAccessName,
        };
        axios.put(APIURL + "/v4/arch/" + activecase, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setArchiveCase(response.data);
                    setArchiveCategory(response.data[0].archcategoryname);
                    setArchiveCategoryId(response.data[0].archcategoryid);
                    setArchiveStatus(response.data[0].archstatusname);
                    setArchiveStatusId(response.data[0].archstatusid);
                    setArchiveAccess(response.data[0].archaccessname);
                    setArchiveUserId(response.data[0].archuserid);
                    setArchiveUser(response.data[0].archusername);
                    setArchiveTeam(response.data[0].archteamname);
                    setArchiveTeamId(response.data[0].archteamid);
                    setArchiveAccessId(response.data[0].archiveaccessid);
                    setArchiveContact(response.data[0].archcontactname);
                    setArchiveContactId(response.data[0].archcontactid);
                    if (archiveUserId == pouserid) {
                        setAssignedToMe(false);
                    }
                    else {
                        setAssignedToMe(true);
                    }
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }



    const submitEditDocument = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setisLoading(true);
        setModalEditDocument(false);
        var data = new FormData(e.currentTarget);
        var dochash = activeDocument[0]?.archivedocumenthash;
        var formdata = {
            archivedocumenttypeid: archiveDocumentTypeId,
            archivedocumenttype: archiveDocumentType,
            archivedocumentdate: data.get('archivedocumentdate'),
            archivedocumentname: data.get('archivedocumentname')
        };
        await axios.put(APIURL + "/v4/archivedocument/" + dochash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getArchiveCase(activecase);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }



    const submitNewArchive = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalNewArchive(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            archdate: data.get('archdate'),
            archdatedue: data.get('archdatedue'),
            archcontactid: newContact,
            archusername: archiveUser,
            archuserid: archiveUserId,
            archteamname: archiveTeam,
            archteamid: archiveTeamId,
            archcategoryname: archiveCategory,
            archcategoryid: archiveCategoryId,
            archtitle: data.get('archtitle'),
            archdescription: data.get('archdescription'),
            archpublic: data.get('archpublic'),
            archaccessid: archiveAccessId,
            archaccessname: archiveAccessName,
        };
        console.log(formdata);
        axios.post(APIURL + "/v4/arch", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setArchiveCase(response.data);
                    handleOpenCase(response.data[0].archhash);
                    // setArchiveCategory(response.data[0].archcategoryname);
                    // setArchiveCategoryId(response.data[0].archcategoryid);
                    // setArchiveStatus(response.data[0].archstatusname);
                    // setArchiveStatusId(response.data[0].archstatusid);
                    // setArchiveAccess(response.data[0].archaccessname);
                    // setArchiveUserId(response.data[0].archuserid);
                    // setArchiveUser(response.data[0].archusername);
                    // setArchiveTeam(response.data[0].archteamname);
                    // setArchiveTeamId(response.data[0].archteamid);
                    // setArchiveAccessId(response.data[0].archiveaccessid);
                    // setArchiveContact(response.data[0].archcontactname);
                    // setArchiveContactId(response.data[0].archcontactid);
                    if (archiveUserId == pouserid) {
                        setAssignedToMe(false);
                    }
                    else {
                        setAssignedToMe(true);
                    }

                }
                else {
                    // console.log(response.data);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }



    const submitArchiveSendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setmodalArchiveSendEmail(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            archemailsubject: data.get('archemailsubject'),
            archemailtext: data.get('archemailtext'),
            archemail: data.get('archemail')
        };
        axios.post(APIURL + "/archivedocumentsend/" + activecase, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getArchiveCase(activecase);
                    setTimeout(() => {
                        getArchiveCase(activecase);
                    }, 10000);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    const submitArchiveNewTXT = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setmodalArchiveNewTXT(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            archivedocumentdate: data.get('archivedocumentdate'),
            archivedocumentname: data.get('archivedocumentname'),
            archivedocumenttext: data.get('archivedocumenttext'),
            archivedocumentcontactid: newDocContact,
            archivedocumenttypeid: newTXTType
        };
        axios.post(APIURL + "/v3/archivedocumenttxt/" + activecase, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getArchiveCase(activecase);
                    setTimeout(() => {
                        getArchiveCase(activecase);
                    }, 10000);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }





    const handleSelectNewDocContact = (event: any, values: any) => {
        setNewDocContact(values['id']);
    }

    const handleSelectNewTXTType = (event: any, values: any) => {
        setNewTXTType(values['id']);
    }


    const handleOpenModalPreviewFile = async (documenthash: any) => {
        setModalPreviewFile(true);
    };
    const handleCloseModalPreviewFile = () => {
        setModalPreviewFile(false);
    };

    const handleOpenNewArchiveModal = async () => {
        setisLoading(true);
        await getArchiveCategories();
        await getTeams();
        await getcontacts();
        await getAccess();
        await getUsers();
        await setisLoading(false);
        setArchiveTeam(poteam);
        setArchiveTeamId(Number(poteamid));
        setArchiveUser(pouser);
        setArchiveUserId(Number(pouserid));
        setModalNewArchive(true);
    };

    const handleCloseNewArchiveModal = () => {
        setModalNewArchive(false);
    };



    const handleOpenModalArchiveContact = async () => {
        setisLoading(true);
        await getcontacts();
        setModalArchiveContact(true);
        await setisLoading(false);
    };

    const handleCloseModalArchiveContact = () => {
        setModalArchiveContact(false);
    };

    const handleOpenModalArchiveEvent = async () => {
        setisLoading(true);
        await getevents();
        setModalArchiveEvent(true);
        await setisLoading(false);
    };

    const handleCloseModalArchiveEvent = () => {
        setModalArchiveEvent(false);
    };



    const handleOpenModalArchiveLocation = async () => {
        setisLoading(true);
        await getlocations();
        setModalArchiveLocation(true);
        setisLoading(false);
    };

    const handleCloseModalArchiveLocation = () => {
        setModalArchiveLocation(false);
    };

    const handleOpenModalArchiveSendEmail = async () => {
        setmodalArchiveSendEmail(true);
    };

    const handleCloseModalArchiveSendEmail = () => {
        setArchiveLastMailButton(false);
        setmodalArchiveSendEmail(false);
    };

    const handleOpenModalUploadFiles = async () => {
        setmodalUploadFiles(true);
    };

    const handleCloseModalUploadFiles = async () => {
        setisLoading(true);
        setmodalUploadFiles(false);
        await getArchiveCase(activecase);
        setisLoading(false);
    };


    const handleOpenModalArchiveNewTXT = async () => {
        gettxtoptions();
        getcontacts();
        setmodalArchiveNewTXT(true);
    };

    const handleCloseModalArchiveNewTXT = () => {
        setmodalArchiveNewTXT(false);
    };


    const handleOpenModalArchiveEdit = async () => {
        setisLoading(true);
        await getcontacts();
        await getArchiveCategories();
        await getUsers();
        await getTeams();
        await getAccess();
        await getArchiveStatuses();
        setModalArchiveEdit(true);
        setisLoading(false);
    };

    const handleCloseModalArchiveEdit = () => {
        setModalArchiveEdit(false);
    };


    const handleOpenModalWipeArchiveContact = async () => {
        setModalWipeArchiveContact(true);
    };

    const handleCloseModalWipeArchiveContact = () => {
        setModalWipeArchiveContact(false);
    };

    const handleOpenModalWipeArchiveEvent = async () => {
        setModalWipeArchiveEvent(true);
    };

    const handleCloseModalWipeArchiveEvent = () => {
        setModalWipeArchiveEvent(false);
    };


    const handleOpenModalWipeArchiveLocation = async () => {
        setModalWipeArchiveLocation(true);
    };

    const handleCloseModalWipeArchiveLocation = () => {
        setModalWipeArchiveLocation(false);
    };

    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setContacts(response.data);
            })
    }

    function getevents() {
        axios.get(APIURL + "/v2/optionsevents", { withCredentials: true })
            .then((response: any) => {
                setEvents(response.data);
            })
    }

    function getAllArchive() {
        var body = {
            archcontactid: filterContact,
            archstatusid: filterStatus,
            archsearch: archSearch,
            archcategoryid: filterCategory,
            archuserid: filterUserId,
            archteamid: filterTeamId,
            page: page
        };
        axios.post(APIURL + "/v3/archiveget", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveAll(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }


    const [archSearch, setarchSearch] = useState<string>('');

    const handlearchSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setarchSearch(event.target.value);
    };
    function getArchiveLastMail() {
        axios.get(APIURL + "/v3/archivelastmail/" + activecase, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveLastMail(response.data[0].archivedocumenttext);
                    setsendEmailText(sendEmailText + "\n-----------------------------------------------------------------------------------------------------------------------\n: " + response.data[0].contactname + "\n: " + response.data[0].archivedocumentdate + "\n: " + response.data[0].archivedocumentname + "\n-----------------------------------------------------------------------------------------------------------------------\n" + response.data[0].archivedocumenttext);
                    setArchiveLastMailButton(true);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
        setisLoading(false);
    }

    function getArchiveCase(archivehash: string) {
        axios.get(APIURL + "/v3/archive/" + archivehash, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveCase(response.data);
                    setArchiveCategory(response.data[0].archcategoryname);
                    setArchiveCategoryId(response.data[0].archcategoryid);
                    setArchiveStatus(response.data[0].archstatusname);
                    setArchiveStatusId(response.data[0].archstatusid);
                    setArchiveAccess(response.data[0].archaccessname);
                    setArchiveContact(response.data[0].archcontactname);
                    setArchiveContactId(response.data[0].archcontactid);
                    setArchiveUser(response.data[0].archusername);
                    setArchiveTeam(response.data[0].archteamname);
                    setArchiveTeamId(response.data[0].archteamid);
                    setArchiveAccessId(response.data[0].archiveaccessid);
                    if (response.data[0].archuserid == pouserid) {
                        setAssignedToMe(false);
                    }
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
        setisLoading(false);
    }

    useEffect(() => {
        document.title = 'Saksarkiv - ParaOffice';
        if (archhash) {
            setActiveCase(archhash);
            getArchiveCase(archhash);
        }
        else {
            window.location.href = '/archive';
        }
        getcontacts();
        getArchiveStatuses();
        getArchiveCategories();
        setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>








                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalPreviewFile}
                    onClose={handleCloseModalPreviewFile}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <PlagiarismIcon /> {archivePreview[0]?.archivedocumentfilename}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="text.secondary">
                            {archivePreview[0]?.archtitle}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {archivePreview[0]?.archdatedisp}
                        </Typography>
                        <Box className="pobox" p={2} mt={2}>
                            {archivePreview[0]?.archivedocumenttext?.split('\n').map((item: any, key: any) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            href="#"
                            variant="outlined"
                            color="success"
                            sx={{ mr: 1 }}
                        >
                            <DownloadIcon className='pobtnicon' />
                            Last ned
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseModalPreviewFile}
                        >
                            <CloseIcon className='pobtnicon' />
                            Lukk
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveContact}
                    onClose={handleCloseModalArchiveContact}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre kontaktperson på saken
                    </DialogTitle>

                    <Box component="form" onSubmit={submitNewContact}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={contacts}
                                    onChange={(e, v) => handleSelectNewContact(e, v)}
                                    getOptionLabel={(option) => option.label}
                                    value={(contacts.find(contacts => contacts.id === archiveContactId) || null)}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={"c" + option.id}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Person" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveContact}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveLocation}
                    onClose={handleCloseModalArchiveLocation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre lokasjon på saken
                    </DialogTitle>

                    <Box component="form" onSubmit={submitNewLocation}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={locationList}
                                    onChange={(e, v) => handleSelectNewLocation(e, v)}
                                    // groupBy={(option) => option.groupname}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Person" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveLocation}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveEvent}
                    onClose={handleCloseModalArchiveEvent}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre event på saken
                    </DialogTitle>

                    <Box component="form" onSubmit={submitNewEvent}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={events}
                                    onChange={(e, v) => handleSelectNewEvent(e, v)}
                                    // groupBy={(option) => option.groupname}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Event" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveEvent}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalEditDocument}
                    onClose={handleCloseModalEditDocument}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre dokument {activeDocument[0]?.archivedocumentfilename}
                    </DialogTitle>
                    {!isLoading &&
                        <Box component="form" onSubmit={submitEditDocument}>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={6} p={1} pt={3}>
                                        <Autocomplete
                                            fullWidth
                                            value={(archiveDocumentTypeOptions.find(archiveDocumentTypeOptions => archiveDocumentTypeOptions.id === activeDocument[0]?.archivedocumenttypeid) || null)}
                                            onChange={(event, newValue) => {
                                                setArchiveDocumentType(newValue.label);
                                                setArchiveDocumentTypeId(newValue.id);
                                            }}
                                            ListboxProps={{ style: { maxHeight: 300 } }}
                                            options={archiveDocumentTypeOptions}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" label="Kategori" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} p={1}>
                                        <TextField
                                            size="small"
                                            type="date"
                                            margin="normal"
                                            fullWidth
                                            id="archivedocumentdate"
                                            label="Dokumentdato"
                                            name="archivedocumentdate"
                                            defaultValue={activeDocument[0]?.archivedocumentdate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} p={1}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="archivedocumentname"
                                            label="Dokumentnavn"
                                            name="archivedocumentname"
                                            defaultValue={activeDocument[0]?.archivedocumentname}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    color="success"
                                >
                                    <Save className='pobtnicon' />
                                    Lagre
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCloseModalEditDocument}
                                >
                                    <CloseIcon className='pobtnicon' />
                                    Avbryt
                                </Button>
                            </DialogActions>
                        </Box>
                    }
                </Dialog>




                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveEdit}
                    onClose={handleCloseModalArchiveEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre sak {archiveCase[0]?.archref}
                    </DialogTitle>
                    <Box component="form" onSubmit={submitUpdateArchive}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} p={1}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="Tittel"
                                        name="archtitle"
                                        defaultValue={archiveCase[0]?.archtitle}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <Autocomplete
                                        fullWidth
                                        value={archiveCategory}
                                        onChange={(event, newValue) => {
                                            setArchiveCategory(newValue.label);
                                            setArchiveCategoryId(newValue.id);
                                        }}
                                        ListboxProps={{ style: { maxHeight: 300 } }}
                                        options={archiveCategories}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Kategori" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <Autocomplete
                                        fullWidth
                                        value={archiveStatus}
                                        onChange={(event, newValue) => {
                                            setArchiveStatus(newValue.label);
                                            setArchiveStatusId(newValue.id);
                                        }}
                                        ListboxProps={{ style: { maxHeight: 300 } }}
                                        options={archiveStatuses}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Status" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        margin="normal"
                                        fullWidth
                                        id="archdate"
                                        label="Saksdato (start)"
                                        name="archdate"
                                        defaultValue={archiveCase[0]?.archdate}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        margin="normal"
                                        fullWidth
                                        id="archdatedue"
                                        label="Frist"
                                        name="archdatedue"
                                        defaultValue={archiveCase[0]?.archdatedue}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <Autocomplete
                                        fullWidth
                                        value={archiveUser}
                                        onChange={(event, newValue) => {
                                            setArchiveUser(newValue.label);
                                            setArchiveUserId(newValue.id);
                                        }}
                                        ListboxProps={{ style: { maxHeight: 300 } }}
                                        options={archiveUserList}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Saksbehandler" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <Autocomplete
                                        fullWidth
                                        value={archiveTeam}
                                        onChange={(event, newValue) => {
                                            setArchiveTeam(newValue.label);
                                            setArchiveTeamId(newValue.id);
                                        }}
                                        ListboxProps={{ style: { maxHeight: 300 } }}
                                        options={archiveTeamList}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Team" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <Autocomplete
                                        fullWidth
                                        value={archiveAccess}
                                        onChange={(event, newValue) => {
                                            setArchiveAccessName(newValue.label);
                                            setArchiveAccessId(newValue.id);
                                        }}
                                        ListboxProps={{ style: { maxHeight: 300 } }}
                                        options={archiveAccessList}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Tilgangsnivå" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1}>
                                    <TextField
                                        size="small"
                                        select
                                        fullWidth
                                        label="Offentlig"
                                        name="archpublic"
                                        defaultValue={archiveCase[0]?.archpublic}
                                    >
                                        <MenuItem value="0">Nei</MenuItem>
                                        <MenuItem value="1">Ja</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <TextField
                                        multiline
                                        rows="5"
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        defaultValue={archiveCase[0]?.archdescription}
                                        label="Beskrivelse"
                                        name="archdescription"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveEdit}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeArchiveLocation}
                    onClose={handleCloseModalWipeArchiveLocation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Fjerne lokasjon
                    </DialogTitle>

                    <Box component="form" onSubmit={submitWipeArchiveLocation}>
                        <DialogContent>
                            Er du sikker på at du vil fjerne lokasjon på saken?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalWipeArchiveLocation}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeArchiveContact}
                    onClose={handleCloseModalWipeArchiveContact}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Fjerne kontaktperson
                    </DialogTitle>

                    <Box component="form" onSubmit={submitWipeArchiveContact}>
                        <DialogContent>
                            Er du sikker på at du vil fjerne kontaktperson på saken?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalWipeArchiveContact}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeArchiveEvent}
                    onClose={handleCloseModalWipeArchiveEvent}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Fjern event fra saken
                    </DialogTitle>

                    <Box component="form" onSubmit={submitWipeArchiveEvent}>
                        <DialogContent>
                            Er du sikker på at du vil fjerne event på saken?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalWipeArchiveEvent}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveSendEmail}
                    onClose={handleCloseModalArchiveSendEmail}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Send e-post svar på sak {archiveCase[0]?.archref}
                    </DialogTitle>
                    <Box component="form" onSubmit={submitArchiveSendEmail}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={6} pr={1} >
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="Mottaker"
                                        name="archcontactname"
                                        defaultValue={archiveCase[0]?.archcontactname}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="E-post"
                                        name="archemail"
                                        defaultValue={archiveCase[0]?.archcontactemail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        defaultValue={archiveCase[0]?.archdescription + " [" + archiveCase[0]?.archref + "]"}
                                        label="Tittel"
                                        name="archemailsubject"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={getArchiveLastMail}
                                        disabled={archiveLastMailButton}
                                    >
                                        <GetAppIcon className='pobtnicon' />
                                        Quote siste epost
                                    </Button>
                                    <TextField
                                        multiline
                                        rows="20"
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        // defaultValue={"\n\n" + "Med vennlig hilsen,\n" + poname + "\n" + archiveCase[0]?.archteamname + "\n\n"}
                                        // defaultValue={sendEmailText}
                                        value={sendEmailText}
                                        onChange={e => ChangeEmailValue(e.target.value)}
                                        label="Epost innhold"
                                        name="archemailtext"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Send e-post
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveSendEmail}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={modalUploadFiles}
                    onClose={handleCloseModalUploadFiles}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>Last opp fil(er)</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseModalUploadFiles}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div className="App">
                            <ArchiveFileUpload onFileUpload={handleFileUpload} onCancel={handleFileCancel} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseModalUploadFiles}
                        >
                            <CloseIcon className='pobtnicon' />
                            Lukk
                        </Button>
                    </DialogActions>
                </Dialog>




                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalArchiveNewTXT}
                    onClose={handleCloseModalArchiveNewTXT}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Opprett nytt tekstdokument {archiveCase[0]?.archref}
                    </DialogTitle>
                    <Box component="form" onSubmit={submitArchiveNewTXT}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} p={1} >
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        ListboxProps={{ style: { maxHeight: 400 } }}
                                        options={optionstxttype}
                                        onChange={(e, v) => handleSelectNewTXTType(e, v)}
                                        // groupBy={(option) => option.groupname}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Dokumenttype" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} p={1} pt={2} >
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        ListboxProps={{ style: { maxHeight: 400 } }}
                                        options={contacts}
                                        onChange={(e, v) => handleSelectNewDocContact(e, v)}
                                        value={(contacts.find(contacts => contacts.id === archiveContactId) || null)}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={"c" + option.id}>
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Kontaktperson" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        type="date"
                                        fullWidth
                                        label="Dokumentdato"
                                        name="archivedocumentdate"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="Dokumentnavn"
                                        name="archivedocumentname"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        rows="15"
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="Tekst"
                                        name="archivedocumenttext"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <Save className='pobtnicon' />
                                Opprett tekstdokument
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalArchiveNewTXT}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>








                {isLoading ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Box m={2}>
                        <Grid container>
                            <Grid item xs={4} pr={2}>
                                <Grid container>
                                    <Grid item xs={12} pr={2}>

                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="overline" color="text.secondary" className='potypeicon'>
                                                <InventoryIcon className='poheadericon' /> {archiveCase[0]?.archref}
                                            </Typography>
                                        </Grid>


                                        <Box className="pobox" sx={{ p: 2, mb: 4 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Tittel
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archtitle}
                                                        {/* {(eventfile[0]?.efreviewstatusid === 0) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <FiberNewIcon className='pobody1icon poiconnew' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            }
                                                            {(eventfile[0]?.efreviewstatusid === 1) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <DonutSmallIcon className='pobody1icon poiconnew' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            }
                                                            {(eventfile[0]?.efreviewstatusid === 2) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <CheckIcon className='pobody1icon pogreen' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            } */}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Status
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archstatusname}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Dato
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archdatedisp}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Frist
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {(archiveCase[0]?.archdateduedisp) ?
                                                            archiveCase[0]?.archdateduedisp
                                                            :
                                                            <Typography variant="caption" className='potypeicon text-success' gutterBottom>
                                                                Ingen frist satt
                                                            </Typography>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Kategori
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archcategoryname}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Saksnummer
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archref}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Opprettet
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archcrtsdisp}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Sist oppdatert
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archcrtsmoddisp}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Saksbehandler
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {(archiveCase[0]?.archusername) ?
                                                            archiveCase[0]?.archusername
                                                            :
                                                            <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                                Ikke tildelt saksbehandler
                                                            </Typography>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Team
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {(archiveCase[0]?.archteamname) ?
                                                            archiveCase[0]?.archteamname
                                                            :
                                                            <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                                Ikke tildelt team
                                                            </Typography>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Tilgangsnivå
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {archiveCase[0]?.archaccessname}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Offentlig
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                        {(archiveCase[0]?.archpublic == "1") ?
                                                            <Typography variant="caption" className='potypeicon text-primary' gutterBottom>
                                                                Ja
                                                            </Typography>
                                                            :
                                                            <Typography variant="caption" className='potypeicon' gutterBottom>
                                                                Nei
                                                            </Typography>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} pb={2}>
                                                    <Typography variant="overline" color="text.secondary" className='potypeicon' pt={1}>
                                                        Saksbeskrivelse
                                                    </Typography>
                                                    <Box className="poboxin" p={1}>
                                                        <Typography variant="caption" color="text.primary" className='potypeicon' gutterBottom>
                                                            {(archiveCase[0]?.archdescription) ?
                                                                archiveCase[0]?.archdescription
                                                                :
                                                                <Typography variant="caption" className='potypeicon text-warning' gutterBottom>
                                                                    Saksdetaljer mangler
                                                                </Typography>
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid container justifyContent="flex-end">
                                                    <Button
                                                        size="small"
                                                        onClick={handleOpenModalArchiveEdit}
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        <EditIcon className='pobtnicon' />
                                                        Endre
                                                    </Button>

                                                    {/* <Link href="#" onClick={handleOpenModalWipeArchiveContact}><DeleteForever className='poheadericon pored' /></Link> */}
                                                </Grid>


                                            </Grid>
                                        </Box>

                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="overline" color="text.secondary" className='potypeicon'>
                                                <PersonIcon className='poheadericon' /> Kontaktperson
                                            </Typography>
                                            <Typography className='potypeicon' sx={{ mb: 2 }} >
                                                <Link href="#" onClick={handleOpenModalArchiveContact}><EditIcon className='poheadericon logiconlink' /></Link>
                                                <Link href="#" onClick={handleOpenModalWipeArchiveContact}><DeleteForever className='poheadericon pored' /></Link>
                                            </Typography>
                                        </Grid>
                                        <Box className="pobox" sx={{ p: 2, mb: 4 }}>
                                            {(archiveCase[0]?.archcontacthash) ?
                                                <Grid container>
                                                    <Grid item>
                                                        <Box>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ maxHeight: 100 }}
                                                                image={"https://poimg01.paraoffice.com/contact/" + archiveCase[0]?.archcontacthash + ".png?" + Date.now()}
                                                                alt={archiveCase[0]?.archcontactname}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                        {(archiveCase[0]?.archcontactwiped == "0") ?
                                                            <Link href={"/contact/" + archiveCase[0]?.archcontacthash} underline="hover">
                                                                {archiveCase[0]?.archcontactname}
                                                            </Link>
                                                            :
                                                            <Link href={"/contact/" + archiveCase[0]?.archcontacthash} underline="hover" className="powiped">
                                                                {archiveCase[0]?.archcontactname}
                                                            </Link>
                                                        }
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <SubtitlesIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontacttitle} &nbsp;
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <EmailIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactemail} &nbsp; <PhoneIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactcell}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon pored'>
                                                            Ingen kontaktperson er tilknyttet saken
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Box>




                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="overline" color="text.secondary" className='potypeicon'>
                                                <LocationOnIcon className='poheadericon' /> Lokasjon
                                            </Typography>
                                            <Typography className='potypeicon' sx={{ mb: 2 }} >
                                                <Link href="#" onClick={handleOpenModalArchiveLocation}><EditIcon className='poheadericon logiconlink' /></Link>
                                                <Link href="#" onClick={handleOpenModalWipeArchiveLocation}><DeleteForever className='poheadericon pored' /></Link>
                                            </Typography>
                                        </Grid>
                                        <Box className="pobox" sx={{ p: 2, mb: 4 }}>
                                            {(archiveCase[0]?.archlocationhash) ?
                                                <Grid container>
                                                    <Grid item>
                                                        <Box>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ maxHeight: 100 }}
                                                                image={"https://poimg01.paraoffice.com/location/" + archiveCase[0]?.archlocationhash + ".png?" + Date.now()}
                                                                alt={archiveCase[0]?.archlocationname}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                        <Link href={"/location/" + archiveCase[0]?.archlocationhash} underline="hover">{archiveCase[0]?.archlocationname}</Link>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <SubtitlesIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archlocationname} &nbsp;
                                                        </Typography>
                                                        {/* <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <EmailIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactemail} &nbsp; <PhoneIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactcell}
                                                                    </Typography> */}
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon pored'>
                                                            Saken er ikke tilknyttet en lokasjon
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Box>

                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="overline" color="text.secondary" className='potypeicon'>
                                                <BusinessCenterIcon className='poheadericon' /> Event
                                            </Typography>
                                            <Typography className='potypeicon' sx={{ mb: 2 }} >
                                                <Link href="#" onClick={handleOpenModalArchiveEvent}><EditIcon className='poheadericon logiconlink' /></Link>
                                                <Link href="#" onClick={handleOpenModalWipeArchiveEvent}><DeleteForever className='poheadericon pored' /></Link>
                                            </Typography>
                                        </Grid>

                                        <Box className="pobox" sx={{ p: 2 }}>
                                            {(archiveCase[0]?.archeventhash) ?
                                                <Grid container>
                                                    <Grid item>
                                                        <Box>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ maxHeight: 100 }}
                                                                image={"https://poimg01.paraoffice.com/event/" + archiveCase[0]?.archeventhash + ".png?" + Date.now()}
                                                                alt={archiveCase[0]?.archeventname}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                        <Link href={"/event/" + archiveCase[0]?.archeventhash} underline="hover">{archiveCase[0]?.archeventname}</Link>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <SubtitlesIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archeventname} &nbsp;
                                                        </Typography>
                                                        {/* <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <EmailIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactemail} &nbsp; <PhoneIcon sx={{ mr: 1 }} className='potextico' /> {archiveCase[0]?.archcontactcell}
                                                                    </Typography> */}
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon pored'>
                                                            Saken er ikke tilknyttet et event
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Box>


                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} p={2} mb={3}>
                                    <Tabs value={TabValue} onChange={handleChangeTab} aria-label="tabs" variant="fullWidth">
                                        <Tab icon={<FolderIcon />} label={"Saksdokumenter (" + archiveCase[0]?.documents?.length + ")"} {...a11yProps(0)} />
                                        {/* <Tab icon={<FormatListBulletedIcon />} label={"Kommentarer (0)"} {...a11yProps(1)} /> */}
                                        <Tab icon={<FormatListBulletedIcon />} label={"Logger (" + archiveCase[0]?.logs?.length + ")"} {...a11yProps(2)} />
                                    </Tabs>
                                </Box>

                                <TabPanel value={TabValue} index={0}>
                                    <Typography variant="overline" color="text.secondary" gutterBottom className='potypeicon'>
                                        <Button
                                            size="small"
                                            href="#"
                                            variant="outlined"
                                            color="primary"
                                            sx={{ mr: 1 }}
                                            onClick={handleOpenModalUploadFiles}
                                        // disabled={AssignedToMe}
                                        >
                                            <UploadIcon className='pobtnicon' />
                                            Last opp nytt dokument i saken
                                        </Button>
                                        <Button
                                            size="small"
                                            href="#"
                                            variant="outlined"
                                            color="primary"
                                            sx={{ mr: 1 }}
                                            onClick={handleOpenModalArchiveNewTXT}
                                        // disabled={AssignedToMe}
                                        >
                                            <OutboxIcon className='pobtnicon' />
                                            Legg til nytt tekstdokument
                                        </Button>
                                        <Button
                                            size="small"
                                            href="#"
                                            variant="outlined"
                                            color="primary"
                                            sx={{ mr: 1 }}
                                            onClick={handleOpenModalArchiveSendEmail}
                                        // disabled={AssignedToMe}
                                        >
                                            <OutboxIcon className='pobtnicon' />
                                            Send ny e-post
                                        </Button>
                                    </Typography>

                                    <Box sx={{ p: 2 }}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Fil
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Beskrivelse
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Type
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Avsender
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Dokumentdato
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="overline" color="text.secondary">
                                                    Alternativer
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {archiveCase[0]?.documents && archiveCase[0]?.documents.map(({ archivedocumenthash, archivedocumentemailsent, archivedocumentemailstatus, archivedocumentseen, archivedocumentdate, archivedocumentuploadts, archivedocumentteamid, archivedocumentcontactname, archivedocumentcontactid, archivedocumentnum, archivedocumenttype, archivedocumenttypeid, archivedocumentname, archivedocumentfilename, archivedocumentfilenamedisk, archivedocumentmime, archivedocumentmd, archivedocumentsha, archivedocumentsize, archivedocumenttext, archivedocumentway, archivedocumentcruserid, archivedocumentcruser, archivedocumentcruseridmod, archivedocumentcrts, archivedocumentcrtsmod, archivedocumentcrtsmoddisp }: any, index: number) => {
                                            return <Grid container className="archivefilerow">
                                                <Grid item xs={3}>
                                                    <Typography variant="subtitle1" color="text.secondary" className='potypeicon'>
                                                        {(archivedocumentway === 1) &&
                                                            <ArrowDownwardIcon className="pogreen" />
                                                        }
                                                        {(archivedocumentway === 2) &&
                                                            <ArrowUpwardIcon className="pored" />
                                                        }
                                                        {(archivedocumentway === 3) &&
                                                            <ArrowDownwardIcon className="pogreen" />
                                                        }
                                                        {((archivedocumenttypeid === 3) && (archivedocumentemailsent === 0)) &&
                                                            <ReportProblemIcon className="text-warning" />
                                                        }
                                                        {/* {((archivedocumenttypeid === 3) && (archivedocumentemailsent === 1)) &&
                                                            <Tooltip title={archivedocumentemailstatus}><GridCheckCircleIcon className="pogreen archlogo" /></Tooltip>
                                                        } */}
                                                        {((archivedocumenttypeid === 3) && (archivedocumentemailsent === 2)) &&
                                                            <Tooltip title={archivedocumentemailstatus}><ReportProblemIcon className="pored" /></Tooltip>
                                                        }
                                                        {(archivedocumentmime == "text/plain") &&
                                                            <TextSnippetIcon />
                                                        }
                                                        &nbsp;
                                                        {(archivedocumenttext) ?
                                                            (archivedocumentseen == 0) ?
                                                                <Tooltip title={<div>{"Filstørrelse: " + DiskSize(archivedocumentsize)}<br />Lastet opp av: " + {archivedocumentcruser} + "</div>} placement="bottom-start">
                                                                    <Link href="#" onClick={e => handleOpenCaseFilePreview(archivedocumenthash)} underline="hover" className="ponewfile">{archivedocumentfilename}</Link>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title={<div>{"Filstørrelse: " + DiskSize(archivedocumentsize)}<br />{"Lastet opp av: " + archivedocumentcruser}<br />{"Tidspunkt lastet opp: " + archivedocumentuploadts}</div>} placement="bottom-start">
                                                                    <Link href="#" onClick={e => handleOpenCaseFilePreview(archivedocumenthash)} underline="hover">{archivedocumentfilename}</Link>
                                                                </Tooltip>
                                                            :
                                                            (archivedocumentseen == 0) ?
                                                                <Link href={"https://poarch01.paraoffice.com/download.php?t=" + potoken + "&i=" + archivedocumenthash} underline="hover" className="ponewfile">{archivedocumentfilename}</Link>
                                                                :
                                                                <Link href={"https://poarch01.paraoffice.com/download.php?t=" + potoken + "&i=" + archivedocumenthash} underline="hover">{archivedocumentfilename}</Link>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {archivedocumentname}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {archivedocumenttype}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {archivedocumentcontactname}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {archivedocumentdate}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body1" color="text.secondary">
                                                        <Link href='#' onClick={e => handleOpenModalEditDocument(archivedocumenthash)}><EditIcon className="potextteam" /></Link> &nbsp; <DeleteForever className="pored" />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        )}

                                        {/* <div
                                            onDragOver={(e: SyntheticEvent) => e.preventDefault()}
                                            onDrop={handleDrop}
                                            style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
                                        >
                                            {errorMessage ? (
                                                <div style={{ color: 'red' }}>{errorMessage}</div>
                                            ) : (
                                                <div>Drop an email from Outlook here</div>
                                            )}
                                        </div> */}
                                    </Box>
                                </TabPanel>
                                <TabPanel value={TabValue} index={1}>
                                    {archiveCase[0]?.logs && archiveCase[0]?.logs.map(({ archiveloguser, archivelogtext, archivelogtimestmap }: any, index: number) => {
                                        return <div><Typography key={"archlog" + index} variant="caption" color="text.secondary">
                                            {archivelogtimestmap} - {archiveloguser} - {archivelogtext}
                                        </Typography></div>
                                    })
                                    }
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box >
        </ThemeProvider >
    );
}

export default ArchiveDetails;