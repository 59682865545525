import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Link, MenuItem, Snackbar, Switch, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PlaceIcon from '@mui/icons-material/Place';
import HomeIcon from '@mui/icons-material/Home';
import CakeIcon from '@mui/icons-material/Cake';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DiskSize from '../components/DiskSize';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import TimeHHMMSS from '../components/TimeHHMMSS';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../components/canvaspreview';
import { useDebounceEffect } from '../components/useDebounceEffect';

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const APIURL = process.env.REACT_APP_APIURL;
const APIFSURL = process.env.REACT_APP_APIFSURL;

const potoken = localStorage.getItem('potoken') || "";

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function DashFooter() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Forsiden
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                >
                    Profil
                </Typography>
            </Breadcrumbs>
        </Typography>
    );
}





interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Profile() {



    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale] = useState(1)
    const [rotate] = useState(0)
    const [aspect] = useState<number | undefined>(1 / 1)



    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        setisLoading(true);
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const formData = new FormData();
            formData.append("token", potoken);
            formData.append("file[]", blob);
            await axios.post("https://poimg01.paraoffice.com/profileupload.php", formData, { headers: { 'Content-Type': blob.type } })
                .then((response: any) => {
                    if (response.status === 200) {
                        getprofile();
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setisLoading(false);
                });
        })
        setImageModal(false);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )



    const [imageModal, setImageModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [confirmLeaveTeam, setconfirmLeaveTeam] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [MyAssignedFiles, setMyAssignedFiles] = useState<any[]>([]);



    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [logsLoaded, setLogsLoaded] = useState(false);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 2) {
            axios.get(APIURL + "/logs", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        setLogs(response.data);
                        setLogsLoaded(true);
                    }
                    else {
                    }
                })
                .catch((e: Error) => {
                });
        }
        setTabValue(newValue);
    };


    const handleClickImageModalOpen = () => {
        setImageModal(true);
    };

    const handleClickImageModalClose = () => {
        setImageModal(false);
    };

    const handleClickProfileModalOpen = () => {
        setProfileModal(true);
    };

    const handleClickProfileModalClose = () => {
        setProfileModal(false);
    };

    const handleClickPasswordModalOpen = () => {
        setPasswordModal(true);
    };

    const handleClickPasswordModalClose = () => {
        setPasswordModal(false);
    };
    const handleCloseProfileUpdated = () => {
        setProfileUpdated(false);
    };
    const handleClosePasswordUpdated = () => {
        setPasswordUpdated(false);
    };
    const handleCloseLeaveTeam = () => {
        setconfirmLeaveTeam(false);
    };
    const handleOpenLeaveTeam = () => {
        setconfirmLeaveTeam(true);
    };




    const [isLoading, setisLoading] = useState(true);
    const [profile, setProfile] = useState<any[]>([]);
    const [logs, setLogs] = useState<any[]>([]);
    const [pubInstagram, setPubInstagram] = useState(false);
    const [pubFacebook, setPubFacebook] = useState(false);
    const [pubEmail, setPubEmail] = useState(false);
    const [pubPhone, setPubPhone] = useState(false);
    const [pubSnapChat, setPubSnapChat] = useState(false);
    const [isTeamMember, setisTeamMember] = useState(false);
    const [isTeamAdmin, setisTeamAdmin] = useState(false);


    function getprofile() {
        axios.get(APIURL + "/profile", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfile(response.data);
                    setisLoading(false);
                    if (response.data[0].userpubinstagram === 1) {
                        setPubInstagram(true);
                    }
                    else {
                        setPubInstagram(false);
                    }
                    if (response.data[0].userpubemail === 1) {
                        setPubEmail(true);
                    }
                    else {
                        setPubEmail(false);
                    }
                    if (response.data[0].userpubfacebook === 1) {
                        setPubFacebook(true);
                    }
                    else {
                        setPubFacebook(false);
                    }
                    if (response.data[0].userpubphone === 1) {
                        setPubPhone(true);
                    }
                    else {
                        setPubPhone(false);
                    }
                    if (response.data[0].userpubsnapchat === 1) {
                        setPubSnapChat(true);
                    }
                    else {
                        setPubSnapChat(false);
                    }
                    if (response.data[0].teamid !== null) {
                        setisTeamMember(true);
                    }
                    else {
                        setisTeamMember(false);
                    }
                    if (response.data[0].teamaccessid === 1) {
                        setisTeamAdmin(true);
                    }
                    else {
                        setisTeamAdmin(false);
                    }
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    async function getmyassignedfiles() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/event/files/assignedme", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setMyAssignedFiles(response.data);
            })
        setisLoading(false);
    }




    useEffect(() => {
        document.title = 'ParaOffice';
        getprofile();
        getmyassignedfiles();

    }, []);

    const submitLeaveTeam = () => {
        setisLoading(true);
        setconfirmLeaveTeam(false);
        axios.delete(APIURL + "/teammember", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getprofile();
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleSubmitProfileImage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setImageModal(false);
        handleClickProfileModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            token: localStorage.getItem("potoken"),
            type: data.get('type'),
            file: data.get('file'),
        };
        axios.post(APIFSURL + "/upload.php", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getprofile();
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleSubmitProfile = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickProfileModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            userfullname: data.get('userfullname'),
            useraddress: data.get('useraddress'),
            userbirth: data.get('userbirth'),
            useremail: data.get('useremail'),
            userphone: data.get('userphone'),
            userzipcode: data.get('userzipcode'),
            usercity: data.get('usercity'),
            userdiscordid: data.get('userdiscordid'),
            usersnapchat: data.get('usersnapchat'),
            userfacebook: data.get('userfacebook'),
            userinstagram: data.get('userinstagram'),
            usergeolat: data.get('usergeolat'),
            usergeolong: data.get('usergeolong'),
            userpubsnapchat: data.get('userpubsnapchat'),
            userpubinstagram: data.get('userpubinstagram'),
            userpubfacebook: data.get('userpubfacebook'),
            userpubphone: data.get('userpubphone'),
            userpubemail: data.get('userpubemail'),
        };
        axios.put(APIURL + "/profile", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfileUpdated(true);
                    setProfile(response.data);
                    if (response.data[0].userpubinstagram === 1) {
                        setPubInstagram(true);
                    }
                    else {
                        setPubInstagram(false);
                    }
                    if (response.data[0].userpubemail === 1) {
                        setPubEmail(true);
                    }
                    else {
                        setPubEmail(false);
                    }
                    if (response.data[0].userpubfacebook === 1) {
                        setPubFacebook(true);
                    }
                    else {
                        setPubFacebook(false);
                    }
                    if (response.data[0].userpubphone === 1) {
                        setPubPhone(true);
                    }
                    else {
                        setPubPhone(false);
                    }
                    if (response.data[0].userpubsnapchat === 1) {
                        setPubSnapChat(true);
                    }
                    else {
                        setPubSnapChat(false);
                    }
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
        setisLoading(true);
        event.preventDefault();
        handleClickPasswordModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            userpassword: data.get('userpassword'),
            userpasswordsalt: data.get('userpasswordsalt'),
        };
        axios.put(APIURL + "/profilepassword", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfile(response.data);
                    setisLoading(false);
                    setPasswordUpdated(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }





    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        {!isLoading ?
                            <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={profileUpdated} autoHideDuration={6000} onClose={handleCloseProfileUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <Alert onClose={handleCloseProfileUpdated} severity="success" sx={{ width: '100%' }}>
                                        Din profil ble oppdatert!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={passwordUpdated} autoHideDuration={6000} onClose={handleClosePasswordUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <Alert onClose={handleClosePasswordUpdated} severity="success" sx={{ width: '100%' }}>
                                        Passordet ditt ble oppdatert!
                                    </Alert>
                                </Snackbar>
                                <main>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChange}
                                        aria-label="tabs"
                                    >
                                        <Tab icon={<AccountCircle />} iconPosition="start" label="Min profil" {...a11yProps(0)} />
                                        <Tab icon={<GroupsIcon />} iconPosition="start" label="Team" {...a11yProps(1)} />
                                        <Tab icon={<ListIcon />} iconPosition="start" label="Logger" {...a11yProps(2)} />
                                        <Tab icon={<WorkHistoryIcon />} iconPosition="start" label="Pågående oppgaver" {...a11yProps(4)} />
                                    </Tabs>
                                    <Dialog
                                        open={profileModal}
                                        onClose={handleClickProfileModalClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Endre profil
                                        </DialogTitle>
                                        <Box component="form" onSubmit={handleSubmitProfile} noValidate>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="userfullname"
                                                        label="Fullt navn"
                                                        name="userfullname"
                                                        defaultValue={profile[0]?.userfullname}
                                                        autoFocus
                                                    />
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        name="useraddress"
                                                        label="Adresse"
                                                        id="useraddress"
                                                        defaultValue={profile[0]?.useraddress}
                                                    />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="userzipcode"
                                                                label="Postnummer"
                                                                id="userzipcode"
                                                                defaultValue={profile[0]?.userzipcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="usercity"
                                                                label="Sted"
                                                                id="usercity"
                                                                defaultValue={profile[0]?.usercity}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="usergeolat"
                                                                label="Geolokasjon Latitude"
                                                                id="usergeolat"
                                                                defaultValue={profile[0]?.usergeolat}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="usergeolong"
                                                                label="GeoLokasjon Longitude"
                                                                id="usergeolong"
                                                                defaultValue={profile[0]?.usergeolong}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <TextField
                                                        type="date"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        name="userbirth"
                                                        label="Fødselsdato"
                                                        id="userbirth"
                                                        defaultValue={profile[0]?.userbirth}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        name="userdiscordid"
                                                        label="Discord ID"
                                                        id="userdiscordid"
                                                        defaultValue={profile[0]?.userdiscordid}
                                                    />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="userphone"
                                                                label="Mobilnummer"
                                                                id="userphone"
                                                                defaultValue={profile[0]?.userphone}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <VpnLockIcon color="error" className='potexticon' />
                                                            <Switch defaultChecked={pubPhone} id='userpubphone' name='userpubphone' value='1' />
                                                            <PublicIcon color="success" className='potexticon' />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="useremail"
                                                                label="E-post"
                                                                id="useremail"
                                                                defaultValue={profile[0]?.useremail}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <VpnLockIcon color="error" className='potexticon' />
                                                            <Switch defaultChecked={pubEmail} id='userpubemail' name='userpubemail' value='1' />
                                                            <PublicIcon color="success" className='potexticon' />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="usersnapchat"
                                                                label="SnapChat brukernavn"
                                                                id="usersnapchat"
                                                                defaultValue={profile[0]?.usersnapchat}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <VpnLockIcon color="error" className='potexticon' />
                                                            <Switch defaultChecked={pubSnapChat} id='userpubsnapchat' name='userpubsnapchat' value='1' />
                                                            <PublicIcon color="success" className='potexticon' />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="userfacebook"
                                                                label="Facebook ID/brukernavn"
                                                                id="userfacebook"
                                                                defaultValue={profile[0]?.userfacebook}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <VpnLockIcon color="error" className='potexticon' />
                                                            <Switch defaultChecked={pubFacebook} id='userpubfacebook' name='userpubfacebook' value='1' />
                                                            <PublicIcon color="success" className='potexticon' />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="userinstagram"
                                                                label="Instagram ID/brukernavn"
                                                                id="userinstagram"
                                                                defaultValue={profile[0]?.userinstagram}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <VpnLockIcon color="error" className='potexticon' />
                                                            <Switch defaultChecked={pubInstagram} id='userpubinstagram' name='userpubinstagram' value='1' />
                                                            <PublicIcon color="success" className='potexticon' />
                                                        </Grid>
                                                    </Grid>

                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SaveIcon className='pobtnicon' />
                                                    Lagre
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleClickProfileModalClose}
                                                >
                                                    <CloseIcon className='pobtnicon' />
                                                    Avbryt
                                                </Button>
                                            </DialogActions>
                                        </Box>
                                    </Dialog>
                                    <Dialog
                                        open={passwordModal}
                                        onClose={handleClickPasswordModalClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Endre passord
                                        </DialogTitle>
                                        <Box component="form" onSubmit={handleSubmitPassword} noValidate>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="userpassword"
                                                        label="Oppgi nytt passord"
                                                        name="userpassword"
                                                        type="password"
                                                        autoFocus
                                                    />
                                                    <p></p>
                                                    <Alert severity="info">Du kan velge styrke på krypteringsnøkkelen som blir brukt på passordet ditt i databasen. Det minste valget er 15 som er veldig sterkt i seg selv, men ønsker du sterkere kan du velge det. Merk at det tar lenger tid å logge inn på ParaOffice desto høyere styrke du har. Antall forventet sekunder innlogging tar står i parantes bak styrken i nedtrekksmenyen under.</Alert>
                                                    <p></p>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Krypterings-sikkerhetsnivå"
                                                        name="userpasswordsalt"
                                                        defaultValue={15}
                                                    >
                                                        <MenuItem key="15" value="15">15 (2s)</MenuItem>
                                                        <MenuItem key="16" value="16">16 (4s)</MenuItem>
                                                        <MenuItem key="17" value="17">17 (8s)</MenuItem>
                                                        <MenuItem key="18" value="18">18 (16s)</MenuItem>
                                                    </TextField>

                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SaveIcon className='pobtnicon' />
                                                    Lagre
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleClickPasswordModalClose}
                                                >
                                                    <CloseIcon className='pobtnicon' />
                                                    Avbryt
                                                </Button>
                                            </DialogActions>
                                        </Box>
                                    </Dialog>

                                    <Grid container spacing={2} p={2}>
                                        <Grid item xs={12} md={12}>
                                            <TabPanel value={tabValue} index={0}>
                                                <Card variant="outlined" sx={{ marginBottom: 2 }}>
                                                    <CardContent>
                                                        <Grid container spacing={2} p={2}>
                                                            <Grid item xs={12} md={3}>
                                                                <CardMedia
                                                                    component="img"
                                                                    sx={{ maxWidth: 250, display: { xs: 'block' }, paddingBottom: 2 }}
                                                                    image={"https://poimg01.paraoffice.com/profile/" + profile[0]?.userhash + ".png?" + Date.now()}
                                                                    alt={profile[0]?.username}
                                                                />
                                                                <Button variant="outlined" size="small" color="warning" onClick={handleClickImageModalOpen}><EditIcon className='pobtnicon' /> Endre bilde</Button>
                                                                <Dialog
                                                                    open={imageModal}
                                                                    onClose={handleClickImageModalClose}
                                                                    aria-labelledby="alert-dialog-title"
                                                                    aria-describedby="alert-dialog-description"
                                                                >
                                                                    <Box component="form" onSubmit={handleSubmitProfileImage} noValidate>
                                                                        <DialogTitle id="alert-dialog-title">
                                                                            Last opp nytt profilbilde
                                                                        </DialogTitle>
                                                                        <DialogContent>


                                                                            <input type="file" accept="image/*" onChange={onSelectFile} />
                                                                            {!!imgSrc && (
                                                                                <ReactCrop
                                                                                    crop={crop}
                                                                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                                                                    onComplete={(c) => setCompletedCrop(c)}
                                                                                    aspect={aspect}
                                                                                >
                                                                                    <img
                                                                                        ref={imgRef}
                                                                                        alt="Crop me"
                                                                                        src={imgSrc}
                                                                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                                                                        onLoad={onImageLoad}
                                                                                    />
                                                                                </ReactCrop>
                                                                            )}
                                                                            {!!completedCrop && (
                                                                                <>
                                                                                    <div>
                                                                                        <Typography>
                                                                                            Forhåndsvisning:
                                                                                        </Typography>
                                                                                        <canvas
                                                                                            ref={previewCanvasRef}
                                                                                            style={{
                                                                                                border: '1px solid black',
                                                                                                objectFit: 'contain',
                                                                                                width: 350,
                                                                                                height: 350,
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            )}


                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button onClick={onDownloadCropClick}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="success"
                                                                            >Last opp</Button>
                                                                            <Button onClick={handleClickImageModalClose}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="error"
                                                                            >Avbryt</Button>
                                                                        </DialogActions>
                                                                    </Box>
                                                                </Dialog>
                                                            </Grid>
                                                            <Grid item xs={12} md={9}>
                                                                <Typography variant="body1" gutterBottom>
                                                                    {profile[0]?.userfullname}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <CakeIcon className='potexticon' /> {profile[0]?.userbirthdisp} ({profile[0]?.userage} år)
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <AlternateEmailIcon className='potexticon' /> {profile[0]?.useremail} &nbsp; {pubEmail ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <PhoneAndroidIcon className='potexticon' /> {profile[0]?.userphone} &nbsp; {pubPhone ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <HomeIcon className='potexticon' /> {profile[0]?.useraddress}, {profile[0]?.userzipcode} ({profile[0]?.usercity})
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <PlaceIcon className='potexticon' /> {profile[0]?.usergeolat} / {profile[0]?.usergeolong}
                                                                </Typography>
                                                                <p></p>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                    <i>Passord sist endret {profile[0]?.userpassworddate} ({profile[0]?.userpasswordage} dager siden)</i>
                                                                </Typography>
                                                                {profile[0]?.userpasswordage > 60 &&
                                                                    <Alert severity="error">Passordet ditt er gammelt, og bør endres!</Alert>
                                                                }
                                                                <p></p>
                                                                <Button variant="outlined" size="small" color="warning" onClick={handleClickProfileModalOpen}><EditIcon className='pobtnicon' /> Endre opplysninger</Button> <Button variant="outlined" color="warning" size="small" onClick={handleClickPasswordModalOpen}><EditIcon className='pobtnicon' /> Endre passord</Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                                <br />
                                                                <Grid container>
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <WhatsAppIcon className='potexticonxl' /> {profile[0]?.userdiscordid}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <FacebookIcon className='potexticonxl' /> <Link href={"https://facebook.com/" + profile[0]?.userfacebook} target='_blank'>{profile[0]?.userfacebook}</Link> &nbsp; {pubFacebook ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <InstagramIcon className='potexticonxl' /> <Link href={"https://instagr.am/" + profile[0]?.userinstagram} target='_blank'>{profile[0]?.userinstagram}</Link> &nbsp; {pubInstagram ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <MonochromePhotosIcon className='potexticonxl' /> {profile[0]?.usersnapchat} &nbsp; {pubSnapChat ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </TabPanel>
                                            {isTeamMember &&
                                                <TabPanel value={tabValue} index={1}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Dialog
                                                                open={confirmLeaveTeam}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    Utmelding av team
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">
                                                                        Er du sikker på at du vil forlate teamet?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button
                                                                        size="small"
                                                                        type="submit"
                                                                        variant="outlined"
                                                                        color="success"
                                                                        onClick={submitLeaveTeam}
                                                                    >
                                                                        <CheckIcon className='pobtnicon' />
                                                                        Bekreft
                                                                    </Button>
                                                                    <Button
                                                                        size="small"
                                                                        variant="outlined"
                                                                        color="error"
                                                                        onClick={handleCloseLeaveTeam}
                                                                    >
                                                                        <CloseIcon className='pobtnicon' />
                                                                        Avbryt
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                            <Grid container spacing={2} p={2}>
                                                                <Grid item xs={12} md={3}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ maxWidth: 250, display: { xs: 'block' }, paddingBottom: 2 }}
                                                                        image={"https://poimg01.paraoffice.com/teams/" + profile[0]?.teamhash + ".jpg"}
                                                                        alt={profile[0]?.username}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={9}>
                                                                    <Typography variant="body1" gutterBottom>
                                                                        {profile[0]?.teamname}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <CakeIcon className='potexticon' /> {profile[0]?.teambirthdisp} ({profile[0]?.teamage} år)
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <BusinessCenterIcon className='potexticon' /> {profile[0]?.eventstotal}
                                                                    </Typography>
                                                                    <br />
                                                                    <Divider />
                                                                    <br />
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        Medlem siden {profile[0]?.teammemberjoindatedisp} ({profile[0]?.teammemberyears} år)<br />
                                                                    </Typography>
                                                                    {isTeamAdmin &&
                                                                        <Button variant="outlined" size="small" color="warning" href='/team/admin' sx={{ marginRight: 1 }}><EditIcon className='pobtnicon' /> Administrer team</Button>
                                                                    }
                                                                    <Button variant="outlined" color="error" size="small" onClick={handleOpenLeaveTeam}><DeleteForeverIcon className='pobtnicon' /> Meld meg ut</Button>
                                                                    <p></p>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </TabPanel>
                                            }
                                            {!isTeamMember &&
                                                <TabPanel value={tabValue} index={1}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid container spacing={2} p={2}>
                                                                <Grid item xs={12} md={9}>
                                                                    Du er ikke medlem av et team.
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </TabPanel>
                                            }
                                            {logsLoaded &&
                                                <TabPanel value={tabValue} index={2}>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Tidspunkt</TableCell>
                                                                    <TableCell>Type</TableCell>
                                                                    <TableCell>Klasse</TableCell>
                                                                    <TableCell>Detaljer</TableCell>
                                                                    <TableCell>IP</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    logs && logs.map(({ logsid, logsclassname, logstypeid, logstypename, logsts, logstsdisp, logstext, logsip }: any) => {
                                                                        return <TableRow
                                                                            key={logsid}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                {logstsdisp}
                                                                            </TableCell>
                                                                            <TableCell>{logsclassname}</TableCell>
                                                                            <TableCell>{logstypename}</TableCell>
                                                                            <TableCell>{logstext}</TableCell>
                                                                            <TableCell align="right">{logsip}</TableCell>
                                                                        </TableRow>
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </TabPanel>
                                            }
                                            <TabPanel value={tabValue} index={3}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <FileOpenIcon className='potexticonxl' /> Reserverte filer
                                                        </Typography>
                                                        <Box className='pobox'>
                                                            <Box m={2}>
                                                                <Grid item xs={12} ml={2} mr={2}>
                                                                    {MyAssignedFiles?.map(({ efid, efhash, efreviewreserved, efreviewts, efreviewtsdisp, devicename, eventname, effilename, effilesize, effilelength }: any, index: number) => {
                                                                        return <span>
                                                                            <Typography key={"mf" + index} variant="body1" color="text.secondary" gutterBottom>
                                                                                <Link href={"/eventfile/" + efhash} underline="hover">{effilename}</Link>
                                                                            </Typography>
                                                                            <Typography key={"mf" + index} variant="body2" color="text.secondary" gutterBottom>
                                                                                {eventname} - {devicename} ({DiskSize(effilesize)} / {TimeHHMMSS(effilelength)})
                                                                            </Typography>
                                                                            {(efreviewreserved > 259200) ?
                                                                                <Alert variant="outlined" severity="error">Reservert i {SecondsToHHMMSS(efreviewreserved)}</Alert>
                                                                                :
                                                                                <Alert severity="info">Reservert i {SecondsToHHMMSS(efreviewreserved)}</Alert>
                                                                            }
                                                                        </span>
                                                                    }
                                                                    )}
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </main>
                            </> : <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </>
                        }
                    </Box>
                    <Box component="footer" sx={{ p: 0, bgcolor: 'rgba(0, 0, 0, 0.3)' }}>
                        <DashFooter />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default Profile;