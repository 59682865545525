import * as React from 'react';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from '../components/Nav';
import Header from '../components/Header';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ListIcon from '@mui/icons-material/List';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import CardMedia from '@mui/material/CardMedia/CardMedia';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Percentage from '../components/Percentage';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import FolderIcon from '@mui/icons-material/Folder';
import Chip from '@mui/material/Chip/Chip';
import TodayIcon from '@mui/icons-material/Today';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import EventIcon from '@mui/icons-material/Event';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InventoryIcon from '@mui/icons-material/Inventory';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatar from '@mui/material/Avatar/Avatar';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import zIndex from '@mui/material/styles/zIndex';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Alert, CardHeader, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PaidIcon from '@mui/icons-material/Paid';
import { BakeryDining } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import TimeHHMMSS from '../components/TimeHHMMSS';

const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const poteam = localStorage.getItem('poteamname');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');

function isDateBeforeToday(date: any) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
}


let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 16,
                    },
                },
            },
        },
    },
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, height: 15 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const drawerWidth = 256;

export default function Dashboard() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingEventsNew, setisLoadingEventsNew] = useState(true);
    const [isLoadingEventsProg, setisLoadingEventsProg] = useState(true);
    const [isLoadingNews, setisLoadingNews] = useState(true);
    const [News, setNews] = useState<any[]>([]);
    const [ecoAccounts, setEcoAccounts] = useState<any[]>([]);
    const [myTeamMembers, setMyTeamMembers] = useState<any[]>([]);
    const [EventsNew, setEventsNew] = useState<any[]>([]);
    const [NewsDetails, setNewsDetails] = useState<any[]>([]);
    const [EventsProg, setEventsProg] = useState<any[]>([]);
    const [modalAddEvent, setmodalAddEvent] = useState(false);
    const [modalAddNews, setmodalAddNews] = useState(false);
    const [modalViewNews, setmodalViewNews] = useState(false);
    const [modalWipeNewsCom, setmodalWipeNewsCom] = useState(false);
    const [modalWipeNews, setmodalWipeNews] = useState(false);
    const [modalViewNewArchive, setModalViewNewArchive] = useState(false);
    const [isLoadingNewEvent, setisLoadingNewEvent] = useState(true);
    const [locations, setLocations] = useState<any[]>([]);
    const [eventclasses, setEventClasses] = useState<any[]>([]);
    const [eventtypes, setEventTypes] = useState<any[]>([]);
    const [eventaccesslevels, setEventAccessLevels] = useState<any[]>([]);
    const [archivePreview, setArchivePreview] = useState<any[]>([]);
    const [eventstorage, setEventStorage] = useState<any[]>([]);
    const [newEventName, setnewEventName] = useState('');
    const [snackSuccessMessage, setSnackSuccessMessage] = useState('');
    const [newEventId, setnewEventId] = useState(0);
    const [wipeComId, setwipeComId] = useState(0);
    const [wipeNewsId, setwipeNewsId] = useState(0);
    const [newsId, setNewsId] = useState(0);
    const [archivenew, setArchiveNew] = useState<any[]>([]);
    const [archiveGroupWork, setArchiveGroupWork] = useState<any[]>([]);
    const [openSnackSuccess, setOpenSnackSuccess] = useState(false);


    const handleOpenModalNewEvent = async () => {
        setisLoadingNewEvent(true);
        setmodalAddEvent(true);
        await axios.get(APIURL + "/v4/options/location", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocations(response.data);
            })
        await axios.get(APIURL + "/eventclasses", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventClasses(response.data);
            })
        await axios.get(APIURL + "/eventtypes", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventTypes(response.data);
            })
        await axios.get(APIURL + "/eventaccesslevels", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventAccessLevels(response.data);
            })
        await axios.get(APIURL + "/eventstorageoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStorage(response.data);
            })
        setisLoadingNewEvent(false);
    };
    const handleCloseModalNewEvent = () => {
        setmodalAddEvent(false);
    };


    const handleOpenCase = (archivehash: any) => {
        window.location.href = '/archive/' + archivehash
    }

    const handleOpenModalNewNews = async () => {
        setmodalAddNews(true);
    };
    const handleCloseModalNewNews = () => {
        setmodalAddNews(false);
    };

    const handleOpenModalViewNews = async (newsid: any) => {
        getNewsDetails(newsid);
        setmodalViewNews(true);
    };
    const handleCloseModalViewNews = () => {
        getNews();
        setmodalViewNews(false);
    };


    const handleOpenModalDeleteNewsCom = async (newscomid: any) => {
        setwipeComId(newscomid);
        setmodalWipeNewsCom(true);
    };
    const handleCloseModalDeleteNewsCom = () => {
        setmodalWipeNewsCom(false);
    };

    const handleOpenModalDeleteNews = async (newsid: any) => {
        setwipeNewsId(newsid);
        setmodalWipeNews(true);
    };
    const handleCloseModalDeleteNews = () => {
        setmodalWipeNews(false);
    };


    const handleCloseModalViewNewArchive = () => {
        setModalViewNewArchive(false);
        getNewArchive();
        setSnackSuccessMessage("Henvendelsen er markert som lest.");
        setOpenSnackSuccess(true);
    };

    const handleOpenModalViewNewArchive = async (archhash: any) => {
        getArchivePreview(archhash);
        setModalViewNewArchive(true);
    };



    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleCloseSnackSuccess = () => {
        setOpenSnackSuccess(false);
    };

    function getMyTeamMembers() {
        axios.get(APIURL + "/v5/myteammembers", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setMyTeamMembers(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }


    function getNewEvents() {
        axios.get(APIURL + "/v3/events/new", { withCredentials: true })
            .then(async (response: any) => {
                setisLoadingEventsNew(false);
                if (response.status === 200) {
                    setisLoadingEventsNew(false);
                    setEventsNew(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    function getNewArchive() {
        var body = {
            limit: 5
        };
        axios.post(APIURL + "/v3/archiveget/new", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveNew(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }


    function getArchiveGroupWork() {
        var body = {
            limit: 5
        };
        axios.post(APIURL + "/v3/archiveget/groupwork", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveGroupWork(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    function getArchivePreview(archhash: string) {
        axios.get(APIURL + "/v3/archiveget/preview/" + archhash, { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchivePreview(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }



    function getNews() {
        axios.get(APIURL + "/v3/news", { withCredentials: true })
            .then(async (response: any) => {
                setisLoadingEventsNew(false);
                if (response.status === 200) {
                    setisLoadingNews(false);
                    setNews(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    function getNewsDetails(newsid: any) {
        setNewsId(newsid);
        axios.get(APIURL + "/v3/news/" + newsid, { withCredentials: true })
            .then(async (response: any) => {
                setisLoadingEventsNew(false);
                if (response.status === 200) {
                    setisLoadingNews(false);
                    setNewsDetails(response.data);
                    getNewEvents();
                    setSnackSuccessMessage("Nyhet \"" + response.data[0].newssubject + "\" markert som lest.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }



    function getProgEvents() {
        axios.get(APIURL + "/v3/events/progress", { withCredentials: true })
            .then(async (response: any) => {
                setisLoadingEventsProg(false);
                if (response.status === 200) {
                    setisLoadingEventsProg(false);
                    setEventsProg(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }


    const submitJoinEvent = (eventid: number) => {
        setisLoading(true);
        var formdata = {};
        axios.post(APIURL + "/event/join/" + eventid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                getNewEvents();
            })
            .catch((e: Error) => {
            });
    }
    const submitLeaveEvent = (eventid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/event/join/" + eventid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                getNewEvents();
            })
            .catch((e: Error) => {
            });
    }


    const submitAddEvent = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalAddEvent(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eventlocationid: newEventId,
            eventname: data.get('eventname'),
            eventdate: data.get('eventdate'),
            eventtime: data.get('eventtime'),
            eventjoinslots: data.get('eventjoinslots'),
            eventtypeid: data.get('eventtypeid'),
            eventclassid: data.get('eventclassid'),
            eventaccesslevelid: data.get('eventaccesslevelid'),
            eventstorageid: data.get('eventstorageid'),
        };
        axios.post(APIURL + "/event", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    getNewEvents();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const submitAddNews = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalAddNews(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            newssubject: data.get('newssubject'),
            newstext: data.get('newstext'),
        };
        axios.post(APIURL + "/v3/news", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    getNews();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    const submitAddNewsComment = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var newsid = data.get('newsid');
        var formdata = {
            newsid: data.get('newsid'),
            newscomment: data.get('newscomment'),
        };
        event.currentTarget.reset();
        axios.post(APIURL + "/v3/newscomment/" + newsid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    getNewsDetails(newsid);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    const submitWipeNews = () => {
        setisLoading(true);
        setmodalViewNews(false);
        setmodalWipeNews(false);
        axios.delete(APIURL + "/v3/news/" + wipeNewsId, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getNews();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitWipeNewsCom = () => {
        setisLoading(true);
        setmodalWipeNewsCom(false);
        axios.delete(APIURL + "/v3/newscomment/" + wipeComId, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getNewsDetails(newsId);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }


    const handleNewEventSelectLocation = (event: any, values: any) => {
        setnewEventName(values['label']);
        setnewEventId(values['id']);
    }


    useEffect(() => {
        document.title = 'ParaOffice';
        getNewEvents();
        getProgEvents();
        getNews();
        getNewArchive();
        getMyTeamMembers();
        getArchiveGroupWork();
    }, []);



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }} className='pobody'>
                <CssBaseline />


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalViewNewArchive}
                    onClose={handleCloseModalViewNewArchive}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {archivePreview[0]?.archcontactname}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="text.secondary">
                            {archivePreview[0]?.archtitle}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {archivePreview[0]?.archdatedisp}
                        </Typography>
                        <Box className="pobox" p={2} mt={2}>
                            {archivePreview[0]?.document[0]?.archivedocumenttext?.split('\n').map((item: any, key: any) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            href="#"
                            variant="outlined"
                            color="success"
                            sx={{ mr: 1 }}
                            onClick={e => handleOpenCase(archivePreview[0]?.archhash)}
                        >
                            <SaveIcon className='pobtnicon' />
                            Gå til saken
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseModalViewNewArchive}
                        >
                            <CloseIcon className='pobtnicon' />
                            Lukk
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalAddEvent}
                    onClose={handleCloseModalNewEvent}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til nytt event
                    </DialogTitle>
                    <Box component="form" onSubmit={submitAddEvent}>
                        <DialogContent>
                            <Autocomplete
                                size="small"
                                ListboxProps={{ style: { maxHeight: 400 } }}
                                disablePortal
                                id="eventlocationid"
                                options={locations}
                                onChange={(e, v) => handleNewEventSelectLocation(e, v)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Lokasjon"
                                    name="eventlocationid"
                                />}
                            />
                            <TextField
                                key={"eventnamekey" + (10000 + Math.random() * (1000000 - 10000))}
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventname"
                                label="Navn på event"
                                name="eventname"
                                defaultValue={newEventName}
                            />
                            <TextField
                                type="date"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventdate"
                                label="Dato for event"
                                name="eventdate"
                            />
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventtime"
                                label="Klokkeslett (00:00:00 format)"
                                name="eventtime"
                                defaultValue="20:00:00"
                            />
                            <TextField
                                type="number"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventjoinslots"
                                label="Maks deltakere"
                                name="eventjoinslots"
                                defaultValue="10"
                            /><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Type event"
                                name="eventtypeid"
                                defaultValue={1}
                            >
                                {
                                    eventtypes && eventtypes.map(({ eventtypeid, eventtypename }: any) => {
                                        return <MenuItem key={"eventtypeid" + eventtypeid} value={eventtypeid}>{eventtypename}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Event klasse"
                                name="eventclassid"
                                defaultValue={1}
                            >
                                {
                                    eventclasses && eventclasses.map(({ eventclassid, eventclassname }: any) => {
                                        return <MenuItem key={"eventclassid" + eventclassid} value={eventclassid}>{eventclassname}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Tilgangsnivå"
                                name="eventaccesslevelid"
                                defaultValue={1}
                            >
                                {
                                    eventaccesslevels && eventaccesslevels.map(({ eventaccesslevelid, eventaccesslevelname }: any) => {
                                        return <MenuItem key={"eventaccesslevelid" + eventaccesslevelid} value={eventaccesslevelid}>{eventaccesslevelname}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Lagringsdisk"
                                name="eventstorageid"
                            >
                                {
                                    eventstorage && eventstorage.map(({ storageid, storageopen, storagename }: any) => {
                                        return <MenuItem key={"eventstorage" + storageid} value={storageid}>{storagename + " (" + storageopen + ")"}</MenuItem>
                                    })
                                }
                            </TextField>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til event
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalNewEvent}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingNewEvent}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalAddNews}
                    onClose={handleCloseModalNewNews}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til nyhet
                    </DialogTitle>
                    <Box component="form" onSubmit={submitAddNews}>
                        <DialogContent>
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id="newssubject"
                                label="Overskrift"
                                name="newssubject"
                                required
                            />
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id="newstext"
                                label="Tekst"
                                name="newstext"
                                multiline
                                rows={5}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til nyhet
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalNewNews}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeNewsCom}
                    onClose={handleCloseModalDeleteNewsCom}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Slett kommentar
                    </DialogTitle>
                    <Box component="form" onSubmit={submitWipeNewsCom}>
                        <DialogContent>
                            Er du sikker på at du vil slette kommentaren?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Bekreft
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalDeleteNewsCom}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeNews}
                    onClose={handleCloseModalDeleteNews}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Slett nyhet
                    </DialogTitle>
                    <Box component="form" onSubmit={submitWipeNews}>
                        <DialogContent>
                            Er du sikker på at du vil slette nyheten?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Bekreft
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalDeleteNews}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>





                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalViewNews}
                    onClose={handleCloseModalViewNews}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6" color="text.secondary">
                                {NewsDetails[0]?.newssubject}
                            </Typography>

                            {NewsDetails[0]?.newsuserid == pouserid &&
                                <IconButton color="error" aria-label="Slett kommentar" onClick={e => handleOpenModalDeleteNews(NewsDetails[0]?.newsid)} className='poiconex'>
                                    <DeleteForeverIcon sx={{ ml: 1 }} />
                                </IconButton>
                            }
                        </Grid>
                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                            <span className='pofiledetails potypeicon'><PersonIcon className='potextic' /> {NewsDetails[0]?.newsuser} <GroupIcon className='potextic' sx={{ ml: 1 }} /> {NewsDetails[0]?.newsteam} <AccessTimeIcon className='potextic' sx={{ ml: 1 }} /> {NewsDetails[0]?.newsdatetimedisp}</span>
                        </Typography>

                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Card variant="outlined" sx={{ mb: 1 }}>
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {NewsDetails[0]?.newstext.split('\n').map((item: any, key: any) => {
                                            return <span key={key}>{item}<br /></span>
                                        })}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <hr></hr>

                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                            <VisibilityIcon className='potextic' sx={{ mr: 1 }} />
                            {NewsDetails[0]?.newsviewedby?.map(({ nvid, nvusername }: any, indexnewview: number) => {
                                return <span className='poviewusers'>{nvusername}</span>
                            })
                            }
                        </Typography>




                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                            <ChatBubbleIcon className='potextic' /> Kommentarer ({NewsDetails[0]?.newscomments})
                        </Typography>


                        {NewsDetails[0]?.comments?.map(({ newscomid, newscomuserid, newscomuser, newscomuserhash, newscomts, newscomtext }: any, indexnewcom: number) => {
                            return <Card variant="outlined" key={indexnewcom} sx={{ mb: 1 }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    <Avatar alt={newscomuser} src={"https://poimg01.paraoffice.com/profile/" + newscomuserhash + ".png?" + Date.now()} className='pocomavatar' />
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {newscomuser} // {newscomts}
                                                    {newscomuserid == pouserid &&
                                                        <IconButton color="error" aria-label="Slett kommentar" onClick={e => handleOpenModalDeleteNewsCom(newscomid)} className='poiconex'>
                                                            <DeleteForeverIcon sx={{ ml: 1 }} />
                                                        </IconButton>
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" color="text.secondary">
                                                {newscomtext?.split('\n').map((item: any, key: any) => {
                                                    return <span key={key}>{item}<br /></span>
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        })
                        }
                        <hr></hr>
                        <Box component="form" onSubmit={submitAddNewsComment}>
                            <input type='hidden' name='newsid' value={NewsDetails[0]?.newsid}></input>
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id="newscomment"
                                label="Kommentar"
                                name="newscomment"
                                multiline
                                rows={3}
                                required
                            />

                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    color="success"
                                >
                                    <ChatBubbleIcon className='pobtnicon' />
                                    Legg til kommentar
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCloseModalViewNews}
                                >
                                    <CloseIcon className='pobtnicon' />
                                    Lukk
                                </Button>

                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>




                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoadingEventsNew}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoadingEventsProg}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={openSnackSuccess}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackSuccess}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {snackSuccessMessage}
                    </Alert>
                </Snackbar>


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        Ubehandlede henvendelser ({archivenew[0]?.archive_total_new})
                                    </div>
                                    <div className='pcardbody'>
                                        {archivenew[0]?.arch && archivenew[0]?.arch.map(({ archid, archhash, archref, archseen, archcontactname, archdatedisp, archdatedue, archdateduedisp, archtitle, contactname, archdescription, teamname }: any, index: number) => {
                                            return (archseen == 0) ?
                                                <Card key={"arch" + archid} className='cardlink cardnew' variant="outlined" sx={{ mb: 1 }} onClick={e => handleOpenModalViewNewArchive(archhash)}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {archdescription}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary" gutterBottom>
                                                            {archdatedisp}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                        <PersonIcon className='potexticon' /> {archcontactname} &nbsp; <InventoryIcon className='potexticon' />  {archref} &nbsp;<GroupIcon className='potexticon' /> {teamname} &nbsp;
                                                        {((archdatedue != null) && (isDateBeforeToday(new Date(archdatedue)))) ?
                                                            <span className='pored'><HourglassBottomIcon className='potexticon pored' /> {archdateduedisp}</span>
                                                            :
                                                            <span className='pogreen'><HourglassBottomIcon className='potexticon pogreen' /> {archdateduedisp}</span>
                                                        }
                                                    </Typography>
                                                </Card>
                                                :
                                                <Card key={"arch" + archid} className='cardlink' variant="outlined" sx={{ mb: 1 }} onClick={e => handleOpenModalViewNewArchive(archhash)}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {archdescription}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary" gutterBottom>
                                                            {archdatedisp}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                        <PersonIcon className='potexticon' /> {archcontactname} &nbsp; <InventoryIcon className='potexticon' />  {archref} &nbsp;<GroupIcon className='potexticon' /> {teamname} &nbsp;
                                                        {((archdatedue != null) && (isDateBeforeToday(new Date(archdatedue)))) ?
                                                            <span className='pored'><HourglassBottomIcon className='potexticon pored' /> {archdateduedisp}</span>
                                                            :
                                                            <span className='pogreen'><HourglassBottomIcon className='potexticon pogreen' /> {archdateduedisp}</span>
                                                        }
                                                    </Typography>
                                                </Card>
                                        }
                                        )}
                                        {(archivenew[0]?.arch.length === 0) &&
                                            <i>Ingen ubehandlede henvendelser</i>
                                        }
                                    </div>
                                </div>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                                Kommende events
                                            </span>
                                            <span className='pcardoptions'>
                                                <AddCircleIcon onClick={handleOpenModalNewEvent} className='pcardoptionicon' />
                                            </span>
                                        </Grid>
                                    </div>
                                    <div className='pcardbody'>
                                        {EventsNew.map(({ eventid, eventtypeid, eventhash, effilestotal, effilesnew, locationname, effilesnewlen, effilestotallen, eventownerteamid, eventjoined, eventaccesslevelname, teamname, eventtypename, eventname, eventtime, eventjoinslots, eventslotsjoined, eventdatedisp }: any, indexnewevent: number) => {
                                            return <Link href={"/event/" + eventhash} underline="none">
                                                <Card variant="outlined" key={eventid} sx={{ mb: 1 }} className="cardlink">
                                                    <Grid container>
                                                        <Grid item xs={1}>
                                                            {
                                                                (eventtypeid === 1) &&
                                                                <TravelExploreIcon className='potexticxl' />
                                                            }
                                                            {
                                                                (eventtypeid === 2) &&
                                                                <TravelExploreIcon className='potexticxl' />
                                                            }
                                                            {
                                                                (eventtypeid === 3) &&
                                                                <TransferWithinAStationIcon className='potexticxl' />
                                                            }
                                                            {
                                                                (eventtypeid === 4) &&
                                                                <ConnectWithoutContactIcon className='potexticxl' />
                                                            }
                                                            {
                                                                (eventtypeid === 5) &&
                                                                <SportsBarIcon className='potexticxl' />
                                                            }
                                                            {
                                                                (eventtypeid === 6) &&
                                                                <GroupIcon className='potexticxl' />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography variant="subtitle1" className='poeventname'>
                                                                    {eventname}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {eventdatedisp}
                                                                </Typography>
                                                            </Grid>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <LocationOnIcon className='potextic potexticred' /> <span className='potextlocation'>{locationname}</span> <Diversity3Icon className='potextic' sx={{ ml: 1 }} /> <span className='potextteam'>{teamname}</span> <SafetyDividerIcon className='potextic' sx={{ ml: 1 }} /> {eventslotsjoined} / {eventjoinslots} <LockPersonIcon className='potextic' sx={{ ml: 1 }} /> {eventaccesslevelname}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sx={{ mt: 1 }}>
                                                            {EventsNew[indexnewevent].investigators.map(({ userid, userhash, username }: any) => {
                                                                return (
                                                                    <Chip key={"u" + userid + "_" + eventid} avatar={<Avatar alt={username} src={"https://poimg01.paraoffice.com/profile/" + userhash + ".png?" + Date.now()} />} label={username} className='pochipjoin' sx={{ mr: 1, mb: 1 }} />
                                                                )
                                                            })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Link>
                                        })
                                        }
                                        {EventsNew?.length === 0 &&
                                            <i>ingen kommende events</i>
                                        }
                                    </div>
                                </div>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        Oppdrag under arbeid
                                    </div>
                                    <div className='pcardbody'>
                                        {!EventsProg &&
                                            <Typography variant="body2" color="text.secondary" gutterBottom><i>Ingen oppdrag under arbeid</i></Typography>
                                        }
                                        {EventsProg.map(({ eventid, eventtypeid, eventhash, effilestotal, effilesnew, effilesnewlen, effilestotallen, teamname, eventownerteamid, eventjoined, eventstatusname, eventaccesslevelname, eventteamname, eventtypename, eventname, eventtime, eventjoinslots, eventslotsjoined, eventdatedisp }: any, index: number) => {
                                            const efstats = effilestotallen - effilesnewlen;
                                            return <Link href={"/event/" + eventhash} underline="none">
                                                <Card variant="outlined" key={eventid} sx={{ mb: 1 }} className="cardlink">
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ maxHeight: 100 }}
                                                                image={"https://poimg01.paraoffice.com/event/" + eventhash + ".png?" + Date.now()}
                                                                alt={eventname}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10} pl={1}>
                                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography variant="subtitle1" className='poeventname'>
                                                                    {eventname}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {eventdatedisp}
                                                                </Typography>
                                                            </Grid>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <Diversity3Icon className='potextic' /> <span className='potextteam'>{teamname}</span> <LockPersonIcon className='potextic' sx={{ ml: 1 }} /> {eventaccesslevelname} &nbsp; <CheckBoxIcon className='potextic' sx={{ ml: 1 }} /> {eventstatusname}
                                                            </Typography>
                                                            {effilestotallen ?
                                                                <Box sx={{ width: '100%' }}>
                                                                    {((effilesnewlen === null) || (effilesnewlen === 0)) ?
                                                                        <div>
                                                                            <LinearProgressWithLabel color="success" value={100} />
                                                                            <span className='pofiledetails potypeicon'><FolderIcon className='potextic' /> {SecondsToHHMMSS(effilestotallen)}</span>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <LinearProgressWithLabel color="warning" value={Percentage(efstats, effilestotallen)} />
                                                                            <span className='pofiledetails potypeicon'><FolderIcon className='potextic' /> {SecondsToHHMMSS(effilestotallen)} <span className='potimeremaining'>&nbsp;[Gjenstår: {SecondsToHHMMSS(effilesnewlen)}]</span></span>
                                                                        </div>
                                                                    }
                                                                </Box>
                                                                : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Link>
                                        })
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} pl={2}>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        {poteam}
                                    </div>
                                    <div className='pcardbody'>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Navn</TableCell>
                                                        <TableCell>Rolle</TableCell>
                                                        <TableCell align="right">Oppdrag</TableCell>
                                                        <TableCell align="right">Gjennomgang</TableCell>
                                                        <TableCell align="right">Medlemskontingent</TableCell>
                                                        <TableCell align="right">Aktiv</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {myTeamMembers && myTeamMembers.map(({ username, teamaccessname, lastlogindays, teammemberuserid, teammemberteamid, teaminvestigationsyear, teaminvestigationsyearjoined, teammemberpaid, eventtotalfilestime, eventfilesmyrevtime }: any, index: number) => {
                                                        const joinperc = Number((teaminvestigationsyearjoined * 100 / teaminvestigationsyear).toFixed(0));
                                                        const revperc = Number((eventfilesmyrevtime * 100 / eventtotalfilestime).toFixed(0));
                                                        const myrevtime = TimeHHMMSS(eventfilesmyrevtime);
                                                        return ((joinperc > 59) && (teammemberpaid == 1) && (revperc > 9)) ?
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row" className='pogreen'>
                                                                    {username}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {teamaccessname}
                                                                </TableCell>
                                                                <TableCell align="right">{(joinperc > 59) ? <span className='pogreen'>{teaminvestigationsyearjoined} / {teaminvestigationsyear} ({joinperc}%)</span> : <span className='pored'>{teaminvestigationsyearjoined} / {teaminvestigationsyear} ({joinperc}%)</span>}</TableCell>
                                                                <TableCell align="right">{(revperc > 9) ? <span className='pogreen'>{myrevtime} ({revperc}%)</span> : <span className='pored'>{myrevtime} ({revperc}%)</span>}</TableCell>
                                                                <TableCell align="right">{(teammemberpaid == 1) ? <CheckIcon className='potextico pogreen' /> : <WarningIcon className='potextico pored' />}</TableCell>
                                                                <TableCell align="right">{(lastlogindays < 7) ? <CheckIcon className='potextico pogreen' /> : <WarningIcon className='potextico pored' />}</TableCell>
                                                            </TableRow>
                                                            :
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row" className='pored'>
                                                                    {username}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {teamaccessname}
                                                                </TableCell>
                                                                <TableCell align="right">{(joinperc > 59) ? <span className='pogreen'>{teaminvestigationsyearjoined} / {teaminvestigationsyear} ({joinperc}%)</span> : <span className='pored'>{teaminvestigationsyearjoined} / {teaminvestigationsyear} ({joinperc}%)</span>}</TableCell>
                                                                <TableCell align="right">{(revperc > 9) ? <span className='pogreen'>{myrevtime} ({revperc}%)</span> : <span className='pored'>{myrevtime} ({revperc}%)</span>}</TableCell>
                                                                <TableCell align="right">{(teammemberpaid == 1) ? <CheckIcon className='potextico pogreen' /> : <WarningIcon className='potextico pored' />}</TableCell>
                                                                <TableCell align="right">{(lastlogindays < 7) ? <CheckIcon className='potextico pogreen' /> : <WarningIcon className='potextico pored' />}</TableCell>
                                                            </TableRow>
                                                    }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        {poteam} - saker under arbeid ({archiveGroupWork[0]?.archive_total_new})
                                    </div>
                                    <div className='pcardbody'>
                                        {archiveGroupWork[0]?.arch && archiveGroupWork[0]?.arch.map(({ archid, archhash, archref, archseen, archcontactname, archdatedisp, archtitle, contactname, archdescription, teamname }: any, index: number) => {
                                            return (archseen == 0) ?
                                                <Card key={"arch" + archid} className='cardlink cardnew' variant="outlined" sx={{ mb: 1 }} onClick={e => handleOpenModalViewNewArchive(archhash)}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {archdescription}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary" gutterBottom>
                                                            {archdatedisp}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                        <PersonIcon className='potexticon' /> {archcontactname} &nbsp;  <InventoryIcon className='potexticon' />  {archref} &nbsp;<GroupIcon className='potexticon' /> {teamname}
                                                    </Typography>
                                                </Card>
                                                :
                                                <Card key={"arch" + archid} className='cardlink' variant="outlined" sx={{ mb: 1 }} onClick={e => handleOpenModalViewNewArchive(archhash)}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {archdescription}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary" gutterBottom>
                                                            {archdatedisp}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                        <PersonIcon className='potexticon' /> {archcontactname} &nbsp;  <InventoryIcon className='potexticon' />  {archref} &nbsp;<GroupIcon className='potexticon' /> {teamname}
                                                    </Typography>
                                                </Card>
                                        }
                                        )}

                                    </div>
                                </div>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                                Nyheter
                                            </span>
                                            <span className='pcardoptions'>
                                                <AddCircleIcon onClick={handleOpenModalNewNews} className='pcardoptionicon' />
                                            </span>
                                        </Grid>
                                    </div>
                                    <div className='pcardbody'>
                                        {News.map(({ newsid, newssubject, newstext, newsuser, newsteam, newsdatedisp, newscomments, newsviews, newsviewed, newsviewedcomments }: any, newsindex: number) => {
                                            return (newsviewed == 0) ?
                                                <Link href={"#"} underline="none" onClick={e => handleOpenModalViewNews(newsid)}>
                                                    <Card variant="outlined" key={newsindex} sx={{ mb: 1 }} className="cardlink cardnew">
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Typography variant="subtitle1" className='poeventname'>
                                                                        {newssubject}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {newsdatedisp}
                                                                    </Typography>
                                                                </Grid>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <span className='pofiledetails potypeicon'><VisibilityIcon className='potextic' /> {newsviews}
                                                                        {(newsviewedcomments == 0) ?
                                                                            <ChatBubbleIcon className='potextic poiconnew' sx={{ ml: 1 }} />
                                                                            :
                                                                            <ChatBubbleIcon className='potextic' sx={{ ml: 1 }} />
                                                                        }
                                                                        {newscomments}
                                                                        <PersonIcon className='potextic' sx={{ ml: 1 }} /> {newsuser} <GroupIcon className='potextic' sx={{ ml: 1 }} /> {newsteam}</span>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Link>
                                                :
                                                <Link href={"#"} underline="none" onClick={e => handleOpenModalViewNews(newsid)}>
                                                    <Card variant="outlined" key={newsindex} sx={{ mb: 1 }} className="cardlink">
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Typography variant="subtitle1" className='poeventname'>
                                                                        {(newsviewed == 0) &&
                                                                            <FiberNewIcon className='poiconnew' sx={{ mr: 1 }} />
                                                                        }
                                                                        {newssubject}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {newsdatedisp}
                                                                    </Typography>
                                                                </Grid>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <span className='pofiledetails potypeicon'><VisibilityIcon className='potextic' /> {newsviews}
                                                                        {(newsviewedcomments == 0) ?
                                                                            <ChatBubbleIcon className='potextic poiconnew' sx={{ ml: 1 }} />
                                                                            :
                                                                            <ChatBubbleIcon className='potextic' sx={{ ml: 1 }} />
                                                                        }
                                                                        {newscomments}
                                                                        <PersonIcon className='potextic' sx={{ ml: 1 }} /> {newsuser} <GroupIcon className='potextic' sx={{ ml: 1 }} /> {newsteam}</span>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Link>
                                        })
                                        }
                                    </div>
                                </div>



                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </Box >
        </ThemeProvider >
    );
}