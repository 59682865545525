import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, AppBar, Autocomplete, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, ThemeProvider, Toolbar, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CakeIcon from '@mui/icons-material/Cake';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useParams } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PlaceIcon from '@mui/icons-material/Place';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import BadgeIcon from '@mui/icons-material/Badge';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteForever from '@mui/icons-material/DeleteForever';
import AddCircle from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';


const APIURL = process.env.REACT_APP_APIURL;

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken');
const poteamid = localStorage.getItem('poteamid');
const poteamaccess = localStorage.getItem('pouserteamaccess');

function BankAccount() {
    const { bankaccountnumber } = useParams();
    const [modalAddTransfer, setmodalAddTransfer] = useState(false);
    const [modalViewTransaction, setmodalViewTransaction] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [bankaccount, setBankAccount] = useState<any[]>([]);
    const [transaction, settransaction] = useState<any[]>([]);

    const [users, setusers] = useState<any[]>([]);
    const [locations, setlocations] = useState<any[]>([]);
    const [contacts, setcontacts] = useState<any[]>([]);
    const [archive, setarchive] = useState<any[]>([]);
    const [events, setevents] = useState<any[]>([]);
    const [ecoplans, setecoplans] = useState<any[]>([]);
    const [newUser, setNewUser] = useState(0);
    const [newLocation, setNewLocation] = useState(0);
    const [newContact, setNewContact] = useState(0);
    const [newArchive, setNewArchive] = useState(0);
    const [newEvent, setNewEvent] = useState(0);
    const [newEcoPlan, setNewEcoPlan] = useState(0);

    function getusers() {
        axios.get(APIURL + "/v2/optionsusers", { withCredentials: true })
            .then((response: any) => {
                setusers(response.data);
            })
    }
    function getlocations() {
        axios.get(APIURL + "/v2/optionslocations", { withCredentials: true })
            .then((response: any) => {
                setlocations(response.data);
            })
    }
    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setcontacts(response.data);
            })
    }
    function getarchive() {
        axios.get(APIURL + "/v2/optionsarchive", { withCredentials: true })
            .then((response: any) => {
                setarchive(response.data);
            })
    }
    function getevents() {
        axios.get(APIURL + "/v2/optionsevents", { withCredentials: true })
            .then((response: any) => {
                setevents(response.data);
            })
    }

    function getecoplans() {
        axios.get(APIURL + "/v2/optionsecoplans", { withCredentials: true })
            .then((response: any) => {
                setecoplans(response.data);
            })
    }


    const submitWipeTransaction = (transactionid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v2/economy/transaction/" + transactionid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getaccountinfo();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const gettransaction = (transactionid: number) => {
        setisLoading(true);
        axios.get(APIURL + "/v2/economy/transaction/" + transactionid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    settransaction(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitNewTransfer = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalAddTransfer(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            ecoaid: bankaccount[0]?.ecoaid,
            ectdescription: data.get('ectdescription'),
            ectamount: data.get('ectamount'),
            ectdir: data.get('ectdir'),
            ectdate: data.get('ectdate'),
            ectsource: data.get('ectsource'),
            ectdestination: data.get('ectdestination'),
            ectplanid: newEcoPlan,
            ectarchiveid: newArchive,
            ectcontactid: newContact,
            ectuserid: newUser,
            ectlocationid: newLocation,
            ecteventid: newEvent
        };
        axios.post(APIURL + "/v2/bankaccount/transfer", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaccountinfo();
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    const handleSelectNewUser = (event: any, values: any) => {
        setNewUser(values['id']);
    }
    const handleSelectNewLocation = (event: any, values: any) => {
        setNewLocation(values['id']);
    }
    const handleSelectNewContact = (event: any, values: any) => {
        setNewContact(values['id']);
    }
    const handleSelectNewArchive = (event: any, values: any) => {
        setNewArchive(values['id']);
    }
    const handleSelectNewEvent = (event: any, values: any) => {
        setNewEvent(values['id']);
    }
    const handleSelectNewEcoPlan = (event: any, values: any) => {
        setNewEcoPlan(values['id']);
    }


    const handleCloseModalAddTransfer = () => {
        setmodalAddTransfer(false);
    };
    const handleOpenModalAddTransfer = () => {
        getusers();
        getlocations();
        getcontacts();
        getarchive();
        getevents();
        getecoplans();
        setmodalAddTransfer(true);
    };



    const handleViewTransaction = (ectid: number) => {
        gettransaction(ectid);
        setmodalViewTransaction(true);
    }
    const handleCloseModalViewTransaction = () => {
        setmodalViewTransaction(false);
    }

    function getaccountinfo() {
        axios.get(APIURL + "/v2/bankaccount/" + bankaccountnumber, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    console.log(response.data);
                    setisLoading(false);
                    setBankAccount(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        getaccountinfo()
        // eslint-disable-next-line
    }, []);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <Header onDrawerToggle={handleDrawerToggle} />
            <CssBaseline />
            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Forsiden
                </Link>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/teams"
                >
                    Bankkonto
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    {!isLoading ?
                        <span>{bankaccountnumber}</span>
                        : null
                    }
                </Typography>
            </Breadcrumbs>


            <Dialog
                fullWidth
                maxWidth="lg"
                open={modalAddTransfer}
                onClose={handleCloseModalAddTransfer}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }}>Legg til transaksjon</Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseModalAddTransfer}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box component="form" onSubmit={submitNewTransfer} noValidate>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="ectdescription"
                                    label="Beskrivelse"
                                    name="ectdescription"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="ectamount"
                                    label="Sum"
                                    name="ectamount"
                                />
                            </Grid>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid item xs={6} pr={1}>
                                    <TextField
                                        size="small"
                                        select
                                        fullWidth
                                        label="Type"
                                        id="ectdir"
                                        name="ectdir"
                                        defaultValue={1}
                                    >
                                        <MenuItem value='1'>Innbetaling</MenuItem>
                                        <MenuItem value='2'>Utbetaling</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="date"
                                        size="small"
                                        fullWidth
                                        id="ectdate"
                                        label="Transaksjonsdato"
                                        name="ectdate"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid item xs={6} pr={1}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="ectdestination"
                                        label="Mottaker"
                                        name="ectdestination"
                                        defaultValue={bankaccount[0]?.ecoteamname}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="ectsource"
                                        label="Avsender"
                                        name="ectsource"
                                        defaultValue={bankaccount[0]?.ecoteamname}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-ecoplan"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={ecoplans}
                                    onChange={(e, v) => handleSelectNewEcoPlan(e, v)}
                                    groupBy={(option) => option.subtitle2}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Kontoplan" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-user"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={users}
                                    onChange={(e, v) => handleSelectNewUser(e, v)}
                                    groupBy={(option) => option.groupname}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Medlem" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-location"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={locations}
                                    onChange={(e, v) => handleSelectNewLocation(e, v)}
                                    groupBy={(option) => option.locationstate}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Lokasjon" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-contact"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={contacts}
                                    onChange={(e, v) => handleSelectNewContact(e, v)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Kontaktperson" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-archive"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={archive}
                                    onChange={(e, v) => handleSelectNewArchive(e, v)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Arkiv" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="checkbox-event"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={events}
                                    onChange={(e, v) => handleSelectNewEvent(e, v)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Event" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            type="submit"
                            variant="outlined"
                            color="success"
                        >

                            Legg til
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseModalAddTransfer}
                        >
                            <CloseIcon className='pobtnicon' />
                            Avbryt
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>



            <Dialog
                fullWidth
                maxWidth="lg"
                open={modalViewTransaction}
                onClose={handleCloseModalViewTransaction}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }}>Transaksjonsdetaljer for ID #{transaction[0]?.ectid}</Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseModalAddTransfer}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Beskrivelse
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectdescription}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Avsender
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectsource}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Mottaker
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectdestination}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Inn/Ut
                            </Typography>
                            <Typography>
                                {(transaction[0]?.ectdir === 1) ?
                                    <span className='text-success'>Innbetaling</span>
                                    :
                                    <span className='text-danger'>Utbetaling</span>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Sum
                            </Typography>
                            <Typography>
                                <b>{transaction[0]?.ectamount} kr</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Kontoplan
                            </Typography>
                            <Typography>
                                {transaction[0]?.epname}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Gruppe
                            </Typography>
                            <Typography>
                                {transaction[0]?.eptnamesub1}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Tittel
                            </Typography>
                            <Typography>
                                {transaction[0]?.eptnamesub2}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Sak
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectarchivename}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Lokasjon
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectlocationname}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Event
                            </Typography>
                            <Typography>
                                {transaction[0]?.ecteventname}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Kontaktperson
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectcontactname}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Medlem
                            </Typography>
                            <Typography>
                                {transaction[0]?.ectusername}
                            </Typography>
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={handleCloseModalViewTransaction}
                    >
                        <CloseIcon className='pobtnicon' />
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>

            {!isLoading ?
                <main>
                    <Grid container>
                        <Grid item xs={6} p={2}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="body1" gutterBottom className='potypeicon'>
                                        {bankaccountnumber}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        Overføringer: {bankaccount[0].ecoatransfers}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        Totalt inn: &nbsp; <b>{bankaccount[0].total_in || 0} kr</b>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        Totalt ut: &nbsp; <b>{bankaccount[0].total_out || 0} kr</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon' sx={{ mt: 1 }}>
                                        Saldo: &nbsp; <b>{(bankaccount[0].total_in - bankaccount[0].total_out).toFixed(2)} kr</b>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} p={2}>
                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                    Transaksjoner
                                </Typography>
                                {((poteamid?.toString() === bankaccount[0]?.ecoateamid.toString()) && ((poteamaccess === "1") || (poteamaccess === "2"))) ?
                                    <IconButton color="primary" aria-label="Legg til transaksjon" onClick={handleOpenModalAddTransfer}>
                                        <AddCircleIcon />
                                    </IconButton>
                                    :
                                    null
                                }
                            </Grid>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="body1" gutterBottom className='potypeicon'>
                                        <TableContainer>
                                            <Table size="small" aria-label="Transaksjoner">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID #</TableCell>
                                                        <TableCell>Dato</TableCell>
                                                        <TableCell>Avsender</TableCell>
                                                        <TableCell>Mottaker</TableCell>
                                                        <TableCell>Beskrivelse</TableCell>
                                                        <TableCell align="right">Sum</TableCell>
                                                        <TableCell align="right">Valg</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bankaccount[0]?.transfers && bankaccount[0]?.transfers.map(({ ectid, ectdescription, ectdatedisp, ectsource, ectdestination, ectcontactid, ectuserid, ectamount }: any, index: number) => {
                                                        return <TableRow
                                                            key={ectid}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="td" scope="row">
                                                                #{ectid}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {ectdatedisp}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {ectsource}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {ectdestination}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {ectdescription}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row" align="right">
                                                                {ectamount} kr
                                                            </TableCell>
                                                            <TableCell component="td" scope="row" align="right">
                                                                <IconButton color="primary" aria-label="Detaljer" onClick={e => handleViewTransaction(ectid)}>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                                {((poteamid?.toString() === bankaccount[0]?.ecoateamid.toString()) && ((poteamaccess === "1") || (poteamaccess === "2"))) ?
                                                                    <IconButton color="error" aria-label="Slett" onClick={e => submitWipeTransaction(ectid)}>
                                                                        <DeleteForever />
                                                                    </IconButton>
                                                                    :
                                                                    null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </main>
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </ThemeProvider >
    );
}

export default BankAccount;