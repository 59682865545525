import React from "react";
import { useDropzone } from "react-dropzone";

import FileItem from "./FileItem";

interface UploadFilesProps {
    onFileUpload: (
        file: File,
        onUploadProgress: (event: ProgressEvent) => void,
        onUploadSuccess: () => void,
        onUploadError: (error: string) => void
    ) => void;
    onCancel: () => void;
    uploadInParallel?: number;
}

export type FileStatus = "idle" | "start" | "success" | "error";

export interface FileState {
    status: FileStatus;
    file: File;
    errorMessage?: string;
}

export default function ArchiveFileUpload(props: UploadFilesProps) {
    const { onFileUpload, onCancel, uploadInParallel = 2 } = props;

    const [fileStates, setFileStates] = React.useState<FileState[]>([]);

    const onDrop = React.useCallback((files: File[]) => {
        setFileStates(
            files.map((file) => ({
                file,
                status: "idle"
            }))
        );
        handleUpload();
    }, []);

    const handleUpload = React.useCallback(() => {
        let idleCount = 0;
        // setFileStates((prev) => {
        //   return prev.map((fs) => {
        //     let status = fs.status;
        //     if (fs.status === "idle" && idleCount < uploadInParallel) {
        //       status = "start";
        //       idleCount++;
        //     }
        //     return {
        //       ...fs,
        //       status
        //     };
        //   });
        // });
        setFileStates((prev) => {
            const firstIdleIndex = prev.findIndex((fs) => fs.status === "idle");
            const next = [...prev];
            if (firstIdleIndex !== -1) {
                next[firstIdleIndex] = {
                    ...next[firstIdleIndex],
                    status: "start"
                };
            }
            return next;
        });
    }, []);

    const handleFileUploadSuccess = React.useCallback((file: File) => {
        setFileStates((prev) => {
            const fileIndex = prev.findIndex((fs) => fs.file.name === file.name);
            const next = [...prev];
            next[fileIndex] = {
                ...next[fileIndex],
                status: "success"
            };
            const firstIdleIndex = next.findIndex((fs) => fs.status === "idle");
            if (firstIdleIndex !== -1) {
                next[firstIdleIndex] = {
                    ...next[firstIdleIndex],
                    status: "start"
                };
            }
            return next;
        });
    }, []);

    const handleFileUploadError = React.useCallback(
        (file: File, error: string) => {
            setFileStates((prev) => {
                const fileIndex = prev.findIndex((fs) => fs.file.name === file.name);
                const next = [...prev];
                next[fileIndex] = {
                    ...next[fileIndex],
                    status: "error",
                    errorMessage: error
                };
                const firstIdleIndex = next.findIndex((fs) => fs.status === "idle");
                if (firstIdleIndex !== -1) {
                    next[firstIdleIndex] = {
                        ...next[firstIdleIndex],
                        status: "start"
                    };
                }
                return next;
            });
        },
        []
    );

    const handleCancel = React.useCallback(() => {
        onCancel();
        setFileStates([]);
    }, [onCancel]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="UploadFiles__root">
            <div className="UploadFiles__dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Slipp filene her...</p>
                ) : (
                    <p>Drag'n drop filer eller trykk for å laste opp</p>
                )}
            </div>
            <div className="UploadFiles__files">
                {fileStates.map((fs) => (
                    <FileItem
                        key={fs.file.name}
                        file={fs.file}
                        onFileUpload={onFileUpload}
                        status={fs.status}
                        onSuccess={handleFileUploadSuccess}
                        onError={handleFileUploadError}
                        errorMessage={fs.errorMessage}
                    />
                ))}
            </div>
        </div>
    );
}