import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Logout from './pages/Logout'
import Profile from './pages/Profile'
import ForgotPassword from './pages/ForgotPassword'
import TeamAdmin from './pages/TeamAdmin'
import Teams from './pages/Teams'
import Team from './pages/Team'
import ProfileOldPassword from './pages/ProfileOldPassword'
import Event from './pages/Event'
import ModEventsBackup from './pages/ModEventsBackup'
import EventFile from './pages/EventFile'
import Events from './pages/Events'
import BankAccount from './pages/BankAccount'
import Locations from './pages/Locations'
import Location from './pages/Location'
import Contact from './pages/Contact'
import Contacts from './pages/Contacts'
import TV from './pages/TV'
import Members from './pages/Members'
import SupraQuiz from './pages/SupraQuiz'
import SupraQuizDetails from './pages/SupraQuizDetails'
import WWW from './pages/www'
import Instagram from './pages/Instagram'
import Archive from './pages/Archive'
import Agreement from './pages/Agreement'
import EventCases from './pages/EventCases'
import { jwtDecode } from 'jwt-decode'
import Filelogs from './pages/Filelogs'
import HelpLogging from './pages/HelpLogging'
import ArchiveEmailImport from './pages/ArchiveEmailImport'
import ArchiveDetails from './pages/ArchiveDetails'
import TODO from './pages/TODO'
import TVSeries from './pages/TVSeries'
import TVSerie from './pages/TVSerie'

const isValidToken = () => {
  const potoken: string | null = localStorage.getItem('potoken');
  try {
    if (potoken) {
      const tokendecoded: { exp: number } = jwtDecode(potoken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const timeoutcheck = tokendecoded.exp + 3600;
      if (timeoutcheck > currentTimestamp) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

const App = () => {
  return (
    <>
      {isValidToken() ?
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/oldpassword" element={<ProfileOldPassword />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/team/:teamhash" element={<Team />} />
              <Route path="/bankaccount/:bankaccountnumber" element={<BankAccount />} />
              <Route path="/supraquiz" element={<SupraQuiz />} />
              <Route path="/supraquiz/:quizhash" element={<SupraQuizDetails />} />
              <Route path="/events" element={<Events />} />
              <Route path="/eventcases" element={<EventCases />} />
              <Route path="/eventcases/:statusid" element={<EventCases />} />
              <Route path="/members" element={<Members />} />
              <Route path="/locations" element={<Locations />} />
              <Route path="/contact/:contacthash" element={<Contact />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/location/:locationhash" element={<Location />} />
              <Route path="/event/:eventhash" element={<Event />} />
              <Route path="/eventfile/:filehash" element={<EventFile />} />
              <Route path="/team/admin" element={<TeamAdmin />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/todo" element={<TODO />} />
              <Route path="/tv" element={<TV />} />
              <Route path="/tvseries" element={<TVSeries TVdash={[]} getTVepisodesFolder={function (tvfid: string): void {
                throw new Error('Function not implemented.')
              } } />} />
                            <Route path="/tvseries/:tvfid" element={<TVSerie TVdash={[]} getTVepisodesFolder={function (tvfid: string): void {
                throw new Error('Function not implemented.')
              } } />} />
              <Route path="/www" element={<WWW />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/archive/:archhash" element={<ArchiveDetails />} />
              <Route path="/agreement" element={<Agreement />} />
              <Route path="/mod/eventsbackup" element={<ModEventsBackup />} />
              <Route path="/filelogs" element={<Filelogs />} />
              <Route path="/helplogging" element={<HelpLogging />} />
              <Route path="/archive/emailimport" element={<ArchiveEmailImport />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </div>
        :
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </div>
      }
    </>
  )
}
export default App