import { useEffect, useState, useRef, SetStateAction } from 'react';
import '../App.css';
import { Alert, Autocomplete, Avatar, Backdrop, Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, getRadioUtilityClass, Grid, IconButton, Link, MenuItem, TextField, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import moment from "moment";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useSearchParams } from 'react-router-dom';
import { Save } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';


const APIURL = process.env.REACT_APP_APIURL;
const pouser = localStorage.getItem('pouser');
const poname = localStorage.getItem('poname');
const pouserid = localStorage.getItem('pouserid');
const poteam = localStorage.getItem('poteamname');
const poteamid = localStorage.getItem('poteamid');
const potoken = localStorage.getItem("potoken") || "";

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function ArchiveEmailImport() {
    useEffect(() => {
        document.title = 'ParaOffice';
        getArchveCases();
        getcontacts();
        setisLoading(false);
    }, []);


    const [isLoading, setisLoading] = useState(true);
    const [contacts, setContacts] = useState<any[]>([]);
    const [modalNewArchive, setModalNewArchive] = useState(false);


    const duetoday = new Date();
    const duedate = duetoday.setDate(duetoday.getDate() + 3);
    const duedatedefault = new Date(duedate).toISOString().split('T')[0] // yyyy-mm-dd

    const today = new Date();
    const todaydate = today.setDate(today.getDate());
    const todaydatedefault = new Date(todaydate).toISOString().split('T')[0] // yyyy-mm-dd

    const [archiveUserList, setArchiveUserList] = useState<any[]>([]);
    const [archiveTeamList, setArchiveTeamList] = useState<any[]>([]);


    const [archiveCases, setArchiveCases] = useState<any[]>([]);
    const [archiveCategories, setArchiveCategories] = useState<any[]>([]);


    function getUsers() {
        axios.get(APIURL + "/v4/user/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveUserList(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function getTeams() {
        axios.get(APIURL + "/v4/team/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveTeamList(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    const [archiveAccessList, setArchiveAccessList] = useState<any[]>([]);
    function getAccess() {
        axios.get(APIURL + "/v4/archaccess/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveAccessList(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    const handleOpenNewArchiveModal = async () => {
        setisLoading(true);
        await getArchiveCategories();
        await getTeams();
        await getcontacts();
        await getAccess();
        await getUsers();
        await setisLoading(false);
        setArchiveTeam(poteam || "");
        setArchiveTeamId(Number(poteamid));
        setArchiveUser(pouser || "");
        setArchiveUserId(Number(pouserid));
        setModalNewArchive(true);
    };

    function getArchiveCategories() {
        axios.get(APIURL + "/v4/archcategory/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveCategories(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }



    function getArchveCases() {
        axios.get(APIURL + "/v4/arch/opencasesoptions", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setArchiveCases(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setContacts(response.data);

                if ((emailSourceAddress != "") && (emailSourceAddress != null)) {
                    const matchedContact = response.data.find((contoptions: { label: string; }) => contoptions.label.toLowerCase().includes(emailSourceAddress.toLowerCase()));
                    if (matchedContact) {
                        setEmailSourceContactId(matchedContact.id);
                    } else {
                        setEmailSourceContactId(0);
                    }
                }
                if ((emailDestinationAddress != "") && (emailDestinationAddress != null)) {
                    const matchedContactDst = response.data.find((contoptions: { label: string; }) => contoptions.label.includes(emailDestinationAddress));
                    if (matchedContactDst) {
                        setEmailDestinationContactId(matchedContactDst.id);
                        setEmailDestinationName(matchedContactDst.label.split(' -')[0]);
                    } else {
                        setEmailDestinationContactId(0);
                    }
                }
            })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var formdata = {
            archid: archId,
            archivedocumentdate: data.get('emaildate'),
            archivedocumentts: data.get('emaildatets'),
            archivedocumentname: data.get('emailsubject'),
            archivedocumenttext: data.get('emailbody'),
            archivedocumentcontactname: emailSourceName,
            archivedocumentcontactemail: emailSourceAddress,
            archivedocumentcontactid: emailSourceContactId,
            archivedocumenttypeid: newTXTType,
            archivedocumentemailsrc: emailSourceAddress,
            archivedocumentcontactiddst: emailDestinationContactId,
            archivedocumentcontactdstemail: emailDestinationAddress,
            archivedocumentcontactdstname: emailDestinationName
        };
        axios.post(APIURL + "/v10/archivedocument", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    var archhash = response.data[0].archhash;
                    window.location.href = '/archive/' + archhash;
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    const [archId, setArchId] = useState(0);
    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [emailSender, setEmailSender] = useState('');
    const [emailSenderName, setEmailSenderName] = useState('');
    const [emailDst, setEmailSenderDst] = useState('');
    const [emailDstName, setSenderDstName] = useState('');
    const [emailDate, setEmailDate] = useState('');
    const [emailDateTime, setEmailDateTime] = useState('');
    const [emailDateDefault, setEmailDateDefault] = useState('');
    const [emailDateTimeDefault, setEmailDateTimeDefault] = useState('');
    const [archiveContact, setArchiveContact] = useState("");
    const [archiveContactId, setArchiveContactId] = useState("");
    const [archiveContactDst, setArchiveContactDst] = useState("");
    const [archiveContactDstId, setArchiveContactDstId] = useState("");
    const [newTXTType, setNewTXTType] = useState(2);








    const [emailSourceAddress, setEmailSourceAddress] = useState('');
    const [emailSourceName, setEmailSourceName] = useState('');
    const [emailSourceContactId, setEmailSourceContactId] = useState(0);

    const [emailDestinationAddress, setEmailDestinationAddress] = useState('');
    const [emailDestinationName, setEmailDestinationName] = useState('');
    const [emailDestinationContactId, setEmailDestinationContactId] = useState(0);



    const handleChangeSubject = (value: any) => {
        setEmailSubject(value);
    };

    const handleChangeDestinationName = (value: any) => {
        setEmailDestinationName(value);
    };

    const handleChangeSourceName = (value: any) => {
        setEmailSourceName(value);
    };




    const handleSelectNewTXTType = (event: any, values: any) => {
        setNewTXTType(values['id']);
    }


    const handleSelectCase = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setArchId(values['id']);
        }
        else {
            setArchId(0);
        }
        setisLoading(false);
    }

    const handleSelectContact = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setArchiveContactId(values['id']);
        }
        else {
            setArchiveContactId("");
        }
        setisLoading(false);
    }


    const handleSelectContactDst = async (event: any, values: any) => {
        setisLoading(true);
        if (values && values['id']) {
            setArchiveContactDstId(values['id']);
        }
        else {
            setArchiveContactDstId("");
        }
        setisLoading(false);
    }


    // const handleContactNameChange = (event: any) => {
    //     setEmailSenderName(event.target.value);
    // };



    const [newContact, setnewContact] = useState(0);
    const [archiveUser, setArchiveUser] = useState("");
    const [archiveUserId, setArchiveUserId] = useState(0);
    const [archiveTeam, setArchiveTeam] = useState("");
    const [archiveTeamId, setArchiveTeamId] = useState(0);
    const [archiveCategory, setArchiveCategory] = useState("");
    const [archiveCategoryId, setArchiveCategoryId] = useState(null);
    const [archiveAccessName, setArchiveAccessName] = useState("");
    const [archiveAccessId, setArchiveAccessId] = useState(null);

    const submitNewArchive = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setModalNewArchive(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            archdate: data.get('archdate'),
            archdatedue: data.get('archdatedue'),
            archcontactid: newContact,
            archusername: archiveUser,
            archuserid: archiveUserId,
            archteamname: archiveTeam,
            archteamid: archiveTeamId,
            archcategoryname: archiveCategory,
            archcategoryid: archiveCategoryId,
            archtitle: data.get('archtitle'),
            archdescription: data.get('archdescription'),
            archpublic: data.get('archpublic'),
            archaccessid: archiveAccessId,
            archaccessname: archiveAccessName,
        };
        axios.post(APIURL + "/v10/arch", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    getArchveCases();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const parmemailBody = searchParams.get('body')?.toString() || "";
        if (parmemailBody !== "") {
            setEmailBody(parmemailBody);
        }
        const parmemailSubject = searchParams.get('subject')?.toString() || "";
        if (parmemailSubject !== "") {
            setEmailSubject(parmemailSubject);
        }
        const pemailSourceAddress = searchParams.get('emailSourceAddress')?.toString() || "";
        if (pemailSourceAddress !== "") {
            setEmailSourceAddress(pemailSourceAddress);
        }

        const pemailSourceName = searchParams.get('emailSourceName')?.toString() || "";
        if (pemailSourceName !== "") {
            setEmailSourceName(pemailSourceName);
        }

        const pemailDestinationAddress = searchParams.get('emailDestinationAddress')?.toString() || "";
        if (pemailDestinationAddress !== "") {
            setEmailDestinationAddress(pemailDestinationAddress);
        }



        const parmemailDate = searchParams.get('date')?.toString() || "";
        if (parmemailDate !== "") {
            setEmailDateTime(parmemailDate);
            setEmailDate(parmemailDate.substring(0, 10).toString());
            setEmailDateDefault(parmemailDate.substring(6, 10) + "-" + parmemailDate.substring(3, 5) + "-" + parmemailDate.substring(0, 2));
            setEmailDateTimeDefault(parmemailDate.substring(6, 10) + "-" + parmemailDate.substring(3, 5) + "-" + parmemailDate.substring(0, 2) + "T" + parmemailDate.substring(11, 19));
        }
    }, []);

    const [optionstxttype, setoptionstxttype] = useState<any[]>([]);

    async function gettxtoptions() {
        await axios.get(APIURL + "/v4/archtxtoptions/options", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setoptionstxttype(response.data);
            })
    }


    const handleCloseNewArchiveModal = () => {
        setModalNewArchive(false);
    };


    useEffect(() => {
        getcontacts();
        gettxtoptions();
    }, [archiveCases]);


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={modalNewArchive}
                onClose={handleCloseNewArchiveModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Opprett ny sak
                </DialogTitle>
                <Box component="form" onSubmit={submitNewArchive}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} p={1}>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Tittel"
                                    name="archtitle"
                                    value={emailSubject}
                                    onChange={(e: any) => handleChangeSubject(e.target.value)}

                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <Autocomplete
                                    fullWidth
                                    value={(archiveCategories.find(archiveCategories => archiveCategories.id === archiveCategoryId) || null)}
                                    onChange={(event, newValue) => {
                                        setArchiveCategory(newValue ? newValue.label : '');
                                        setArchiveCategoryId(newValue ? newValue.id : null);
                                    }}
                                    ListboxProps={{ style: { maxHeight: 300 } }}
                                    options={archiveCategories}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label="Kategori" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <TextField
                                    size="small"
                                    type="date"
                                    margin="normal"
                                    fullWidth
                                    id="archdate"
                                    label="Saksdato (start)"
                                    name="archdate"
                                    defaultValue={emailDateDefault}
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <TextField
                                    size="small"
                                    type="date"
                                    margin="normal"
                                    fullWidth
                                    id="archdatedue"
                                    label="Frist"
                                    name="archdatedue"
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <Autocomplete
                                    fullWidth
                                    value={(archiveUserList.find(archiveUserList => archiveUserList.id === archiveUserId) || null)}
                                    onChange={(event, newValue) => {
                                        setArchiveUser(newValue ? newValue.label : '');
                                        setArchiveUserId(newValue ? newValue.id : null);
                                    }}
                                    ListboxProps={{ style: { maxHeight: 300 } }}
                                    options={archiveUserList}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label="Saksbehandler" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <Autocomplete
                                    fullWidth
                                    value={(archiveTeamList.find(archiveTeamList => archiveTeamList.id === archiveTeamId) || null)}
                                    onChange={(event, newValue) => {
                                        setArchiveTeam(newValue ? newValue.label : '');
                                        setArchiveTeamId(newValue ? newValue.id : null);
                                    }}
                                    ListboxProps={{ style: { maxHeight: 300 } }}
                                    options={archiveTeamList}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label="Team" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <Autocomplete
                                    fullWidth
                                    value={(archiveAccessList.find(archiveAccessList => archiveAccessList.id === archiveAccessId) || null)}
                                    onChange={(event, newValue) => {
                                        setArchiveAccessName(newValue ? newValue.label : '');
                                        setArchiveAccessId(newValue ? newValue.id : null);
                                    }}
                                    ListboxProps={{ style: { maxHeight: 300 } }}
                                    options={archiveAccessList}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label="Tilgangsnivå" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} p={1}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Offentlig"
                                    name="archpublic"
                                    defaultValue={"0"}
                                >
                                    <MenuItem value="0">Nei</MenuItem>
                                    <MenuItem value="1">Ja</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <TextField
                                    multiline
                                    rows="5"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Beskrivelse"
                                    name="archdescription"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            type="submit"
                            variant="outlined"
                            color="success"
                        >
                            <Save className='pobtnicon' />
                            Lagre
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseNewArchiveModal}
                        >
                            <CloseIcon className='pobtnicon' />
                            Avbryt
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>


            <Grid container>
                <Grid item xs={12} p={2}>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1 }}>
                            <AssignmentIcon />
                        </Avatar>
                        <Typography variant="overline" component="h2" pb={2}>
                            ParaOffice import e-post til sak
                        </Typography>


                        <Card>
                            <CardContent>
                                <Box component="form" onSubmit={handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={10} pb={2}>
                                            <Autocomplete
                                                sx={{ pb: 1 }}
                                                size="small"
                                                fullWidth
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                options={archiveCases}
                                                onChange={(e, v) => handleSelectCase(e, v)}
                                                getOptionLabel={(option) => option.label}
                                                value={(archiveCases.find(archiveCases => archiveCases.id === archId) || null)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props} key={"c" + option.id}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Velg sak" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box
                                                display="flex"
                                                justifyContent="right"
                                                alignItems="right"
                                            >
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleOpenNewArchiveModal}
                                                >
                                                    <AddIcon className='pobtnicon' />
                                                    Opprett ny sak
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                required
                                                fullWidth
                                                id="emailsender"
                                                label="Avsender e-post"
                                                name="emailsender"
                                                value={emailSourceAddress}
                                            // onChange={(event) => handleUsernameChange(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                fullWidth
                                                id="emailsendername"
                                                label="Avsender navn"
                                                name="emailsendername"
                                                value={emailSourceName}
                                                onChange={(e) => handleChangeSourceName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pt={2}>
                                            <Autocomplete
                                                sx={{ pb: 1 }}
                                                fullWidth
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                options={contacts}
                                                onChange={(e, v) => handleSelectContact(e, v)}
                                                getOptionLabel={(option) => option.label}
                                                // defaultValue={(contacts.find(contact => String(contact.label).includes(emailSenderName)) || null)}
                                                value={(contacts.find(contacts => contacts.id === emailSourceContactId) || null)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props} key={"c" + option.id}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Kontaktperson avsender" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                fullWidth
                                                id="emaildst"
                                                label="Mottaker e-post"
                                                name="emaildst"
                                                value={emailDestinationAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                fullWidth
                                                id="emaildstname"
                                                label="Mottaker navn"
                                                name="emaildstname"
                                                value={emailDestinationName}
                                                onChange={(e) => handleChangeDestinationName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pt={2}>
                                            <Autocomplete
                                                sx={{ pb: 1 }}
                                                fullWidth
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                options={contacts}
                                                onChange={(e, v) => handleSelectContactDst(e, v)}
                                                getOptionLabel={(option) => option.label}
                                                // defaultValue={(contacts.find(contact => String(contact.label).includes(emailSender)) || null)}
                                                value={(contacts.find(contacts => contacts.id === emailDestinationContactId) || null)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props} key={"c" + option.id}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Kontaktperson mottaker" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <Autocomplete
                                                sx={{ pt: 2 }}
                                                fullWidth
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                options={optionstxttype}
                                                onChange={(e, v) => handleSelectNewTXTType(e, v)}
                                                // groupBy={(option) => option.groupname}
                                                getOptionLabel={(option) => option.label}
                                                value={(optionstxttype.find(optionstxttype => optionstxttype.id === newTXTType) || null)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Dokumenttype" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <TextField
                                                type="datetime-local"
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="emaildatets"
                                                label="Dato og tid"
                                                name="emaildatets"
                                                value={emailDateTimeDefault}
                                            // onChange={(event) => handleUsernameChange(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="date"
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="emaildate"
                                                label="Dato"
                                                name="emaildate"
                                                value={emailDateDefault}
                                            // onChange={(event) => handleUsernameChange(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                size="small"
                                                fullWidth
                                                id="emailsubject"
                                                label="E-post tittel"
                                                name="emailsubject"
                                                value={emailSubject}
                                                onChange={(e: any) => handleChangeSubject(e.target.value)}
                                            />
                                            <TextField
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="emailbody"
                                                label="E-post"
                                                name="emailbody"
                                                defaultValue={emailBody}
                                                multiline
                                                rows="25"
                                            // onChange={(event) => handleUsernameChange(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <Save className='pobtnicon' />
                                        Importer epost
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>

                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default ArchiveEmailImport;