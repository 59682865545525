import { useEffect, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Button, CardContent, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import QuizIcon from '@mui/icons-material/Quiz';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function SupraQuiz() {
    const [isLoading, setisLoading] = useState(true);
    const [myQuizList, setMyQuizList] = useState<any[]>([]);
    const [addNewQuizModal, setAddNewQuizModal] = useState(false);
    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    async function getmyquizlist() {
        await axios.get(APIURL + "/v3/supraquiz", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setMyQuizList(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const handleOpenAddNewQuizModal = () => {
        setAddNewQuizModal(true);
    };

    const handleCloseAddNewQuizModal = () => {
        setAddNewQuizModal(false);
    };


    const handleSubmitNewQuiz = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAddNewQuizModal(false);
        setisLoading(true);
        var data = new FormData(e.currentTarget);
        var formdata = {
            supraquizname: data.get('supraquizname'),
        };
        axios.post(APIURL + "/v3/supraquiz", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getmyquizlist();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }


    const startSupraQuiz = (quizhash: string) => {
        setisLoading(true);
        var formdata = {};
        axios.post(APIURL + "/v3/supraquiz/start/" + quizhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getmyquizlist();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    useEffect(() => {
        document.title = 'SupraQuiz';
        getmyquizlist();
        setisLoading(false);
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={addNewQuizModal}
                    onClose={handleCloseAddNewQuizModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Lag ny SupraQuiz
                    </DialogTitle>
                    <Box component="form" onSubmit={handleSubmitNewQuiz}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizname"
                                            label="Navn på quizzen"
                                            name="supraquizname"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseAddNewQuizModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <QuizIcon className='potexticonxl' /> Mine SupraQuiz ({myQuizList?.length})
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} ml={1}>
                                <Button size="small" variant="outlined" color="primary" onClick={handleOpenAddNewQuizModal} sx={{ mb: 1 }}><AddCircleIcon className='btniconsmall' /> Lag ny quiz</Button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} p={2}>
                                {!isLoading ?
                                    <div>
                                        {myQuizList?.map(({ quizid, quizhash, quizname, quizquestions, quizactive }: any, index: number) => {
                                            return <Box className='pobox' key={"quiz" + index} sx={{ mb: 1 }}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom className='potypeicon'>
                                                                {quizname}
                                                                {(quizactive === 1) &&
                                                                    <PlayCircleFilledIcon className='pored' sx={{ ml: 2 }} />
                                                                }
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                {quizquestions} spørsmål
                                                            </Typography>
                                                            <Button
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                color="primary"
                                                                href={"/supraquiz/" + quizhash}
                                                                sx={{ mr: 2 }}
                                                            >
                                                                <AddCircleIcon className='pobtnicon' />
                                                                Endre
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                sx={{ mr: 2 }}
                                                                disabled
                                                            >
                                                                <AddCircleIcon className='pobtnicon' />
                                                                Slett
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                color="success"
                                                                onClick={e => startSupraQuiz(quizhash)}
                                                            >
                                                                <AddCircleIcon className='pobtnicon' />
                                                                Start SupraQuiz
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Box>
                                        })
                                        }
                                    </div>
                                    :
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default SupraQuiz;