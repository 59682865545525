import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Button, CircularProgress, createTheme, CssBaseline, Grid, MenuItem, TextField, ThemeProvider } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';

const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function ProfileOldPassword() {
    const [isLoading, setisLoading] = useState(false);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    useEffect(() => {
        document.title = 'Utløpt passord - ParaOffice';
    }, []);

    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
        setisLoading(true);
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        var formdata = {
            userpassword: data.get('userpassword'),
            userpasswordsalt: data.get('userpasswordsalt'),
        };
        axios.put(APIURL + "/profilepassword", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    window.location.assign("/dashboard");
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>


                {!isLoading ?
                    <>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Header onDrawerToggle={handleDrawerToggle} />
                            <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12}>
                                        <Alert severity="error">Passordet ditt er mer enn ett år gammelt, og må endres!</Alert>
                                        <Box component="form" onSubmit={handleSubmitPassword} noValidate>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="userpassword"
                                                label="Oppgi nytt passord"
                                                name="userpassword"
                                                type="password"
                                                autoFocus
                                            />
                                            <p></p>
                                            <Alert severity="info">Du kan velge styrke på krypteringsnøkkelen som blir brukt på passordet ditt i databasen. Det minste valget er 15 som er veldig sterkt i seg selv, men ønsker du sterkere kan du velge det. Merk at det tar lenger tid å logge inn på ParaOffice desto høyere styrke du har. Antall forventet sekunder innlogging tar står i parantes bak styrken i nedtrekksmenyen under.</Alert>
                                            <p></p>
                                            <TextField
                                                select
                                                fullWidth
                                                label="Krypterings-sikkerhetsnivå"
                                                name="userpasswordsalt"
                                                defaultValue={15}
                                            >
                                                <MenuItem key="15" value="15">15 (2s)</MenuItem>
                                                <MenuItem key="16" value="16">16 (4s)</MenuItem>
                                                <MenuItem key="17" value="17">17 (8s)</MenuItem>
                                                <MenuItem key="18" value="18">18 (16s)</MenuItem>
                                            </TextField>
                                            <p></p>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <SaveIcon className='pobtnicon' />
                                                Lagre
                                            </Button> &nbsp;
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                            >
                                                <CloseIcon className='pobtnicon' />
                                                Avbryt
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </> : <>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>
                }
            </Box>
        </ThemeProvider>
    );
}

export default ProfileOldPassword;