import { useEffect, useState } from 'react';
import '../App.css';
import { Autocomplete, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, MenuItem, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';




const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken');

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function Contacts() {

    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const [isLoading, setisLoading] = useState(true);
    const [AddNewContactModal, setAddNewContactModal] = useState(false);

    const [contacts, setContacts] = useState<any[]>([]);
    // const [filtercountry, setfiltercountry] = useState(1);
    // const [filtercountryname, setfiltercountryname] = useState("Norge");
    const [locations, setlocations] = useState<any[]>([]);
    const [filterlocation, setfilterlocation] = useState(0);
    const [filtercontactname, setfiltercontactname] = useState("");

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    async function getcontacts() {
        var body = {
            locationid: filterlocation,
            contactname: filtercontactname,
            page: page
        };
        await axios.post(APIURL + "/v2/contacts", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setContacts(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const handleSelectFilterLocation = (locationname: any, locationid: any) => {
        if (locationid?.id) {
            setfilterlocation(locationid.id);
        }
        else {
            setfilterlocation(0);
        }
    }

    // const handleSelectFilterState = (statename: any, stateid: any) => {
    //     if (stateid?.id) {
    //         setfilterstate(stateid.id);
    //     }
    //     else {
    //         setfilterstate(0);
    //     }
    // }

    const handleTypeContactName = (contactname: any) => {
        setfiltercontactname(contactname);
    }


    // async function getcountrys() {
    //     await axios.get(APIURL + "/v2/optionscountrys", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    //         .then((response: any) => {
    //             setCountrys(response.data);
    //         })
    // }
    async function getlocations() {
        await axios.get(APIURL + "/v2/optionslocations", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setlocations(response.data);
            })
    }

    const handleOpenAddNewContactModal = () => {
        setAddNewContactModal(true);
    };

    const handleCloseAddNewContactModal = () => {
        setAddNewContactModal(false);
    };

    const [contactAccessList, setContactAccessList] = useState<any[]>([]);
    const [contactAccessId, setContactAccessId] = useState(4);
    const [contactAccessName, setContactAccessName] = useState("Felles");

    function getContactAccess() {
        axios.get(APIURL + "/v4/contactaccess/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setContactAccessList(response.data);
                    console.log(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }


    const handleSubmitNewContact = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAddNewContactModal(false);
        setisLoading(true);
        var data = new FormData(e.currentTarget);
        var formdata = {
            contactname: data.get('contactname'),
            contacttitle: data.get('contacttitle'),
            contactbirthday: data.get('contactbirthday'),
            contactemail: data.get('contactemail'),
            contactphone: data.get('contactphone'),
            contactcell: data.get('contactcell'),
            contactaddress: data.get('contactaddress'),
            contactzip: data.get('contactzip'),
            contactcity: data.get('contactcity'),
            contactgeolat: data.get('contactgeolat'),
            contactgeolong: data.get('contactgeolong'),
            contactfacebook: data.get('contactfacebook'),
            contactinstagram: data.get('contactinstagram'),
            contactnick: data.get('contactnick'),
            contactdiscordid: data.get('contactdiscordid'),
            contactnote: data.get('contactnote'),
            contactprivate: 0,
            contactaccessid: contactAccessId
        };
        axios.post(APIURL + "/v3/contact", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.assign("/contact/" + response.data[0].contacthash);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }



    useEffect(() => {
        document.title = 'Kontaktpersoner - ParaOffice';
        getcontacts();
        getlocations();
        getContactAccess();
        setisLoading(false);
    }, [filterlocation, page]);

    // useEffect(() => {
    //     setisLoading(true);
    //     getcontacts();
    // }, [filterlocation]);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>






                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={AddNewContactModal}
                    onClose={handleCloseAddNewContactModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Opprett ny kontaktperson
                    </DialogTitle>
                    <Box component="form" onSubmit={handleSubmitNewContact}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="contactname"
                                            label="Fullt navn"
                                            name="contactname"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="contacttitle"
                                            label="Tittel"
                                            name="contacttitle"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            type="date"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactbirthday"
                                            label="Fødselsdato"
                                            id="contactbirthday"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactemail"
                                            label="E-post"
                                            id="contactemail"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactcell"
                                            label="Mobiltelefon"
                                            id="contactcell"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactphone"
                                            label="Telefon"
                                            id="contactphone"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactaddress"
                                            label="Adresse"
                                            id="contactaddress"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactzip"
                                            label="Postnummer"
                                            id="contactzip"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactcity"
                                            label="Sted"
                                            id="contactcity"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactgeolat"
                                            label="Geolokasjon Latitude"
                                            id="contactgeolat"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactgeolong"
                                            label="GeoLokasjon Longitude"
                                            id="contactgeolong"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactfacebook"
                                            label="Facebook brukernavn"
                                            id="contactfacebook"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactinstagram"
                                            label="Instagram brukernavn"
                                            id="contactinstagram"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactnick"
                                            label="Kallenavn"
                                            id="contactnick"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactdiscordid"
                                            label="Discord #ID"
                                            id="contactdiscordid"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} pt={1}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            value={(contactAccessList.find(contactAccessList => contactAccessList.id === contactAccessId) || null)}
                                            onChange={(event, newValue) => {
                                                setContactAccessName(newValue ? newValue.label : '');
                                                setContactAccessId(newValue ? newValue.id : null);
                                            }}
                                            ListboxProps={{ style: { maxHeight: 300 } }}
                                            options={contactAccessList}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" label="Tilgangsnivå" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="2"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="contactnote"
                                            label="Notat"
                                            id="contactnote"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseAddNewContactModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>








                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <EventIcon className='potexticonxl' /> Kontaktpersoner ({contacts[0]?.contactstotal})
                                    </Typography>
                                    <Stack spacing={2}>
                                        <Pagination count={Math.ceil(contacts[0]?.contactstotal / 10)} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                                    </Stack>
                                </Grid>
                                <Box m={1}>
                                    {!isLoading ?
                                        <div>
                                            {contacts[0]?.contacts.map(({ contactid, contacthash, contactname, contacttitle, contactemail, contactphone, contactaccessid, contactaccessname, contactcell, contactfacebook, contactinstagram, contactbirthday, cotnactcity, contactteamid, contactnote, contactprivate }: any, index: number) => {
                                                return <Box className='pobox' key={"contact" + contactid} sx={{ mb: 1 }}>
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item>
                                                                <Box>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ maxHeight: 100 }}
                                                                        image={"https://poimg01.paraoffice.com/contact/" + contacthash + ".png?" + Date.now()}
                                                                        alt={contactname}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                                <Link href={"/contact/" + contacthash}>{contactname}</Link>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <SubtitlesIcon sx={{ mr: 1 }} className='potextico' /> {contacttitle} &nbsp;
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <EmailIcon sx={{ mr: 1 }} className='potextico' /> {contactemail} &nbsp; <PhoneIcon sx={{ mr: 1 }} className='potextico' /> {contactphone}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <LockPersonIcon sx={{ mr: 1 }} className='potextico' /> {contactaccessname}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Box>
                                            })
                                            }
                                        </div>
                                        :
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={isLoading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box m={2}>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleOpenAddNewContactModal}
                                    >
                                        <AddCircleIcon className='pobtnicon' />
                                        Ny kontaktperson
                                    </Button>
                                    <p></p>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <ContentPasteSearchIcon className='potexticonxl' /> Søk og filtrering
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid item xs={12} p={2}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="contactname"
                                                    label="Navn på kontaktperson"
                                                    name="contactname"
                                                    onChange={(newValue) => handleTypeContactName(newValue.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            getcontacts();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationid"
                                                    options={locations}
                                                    onChange={(e, v) => handleSelectFilterLocation(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Lokasjon"
                                                        name="locationid"
                                                    />}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default Contacts;