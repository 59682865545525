import React from 'react';
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Breadcrumbs, Card, CardContent, CircularProgress, createTheme, CssBaseline, Dialog, Grid, Link, TextField, ThemeProvider, Typography, LinearProgress, LinearProgressProps, AppBar, IconButton, DialogContent, DialogActions, Button, Toolbar, Slide, DialogTitle, Badge, CardActionArea, CardActions, CardMedia } from '@mui/material';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import TVFileUpload from '../components/TVFileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';

const APIURL = process.env.REACT_APP_APIURL;

// const pouser = localStorage.getItem('pouser');
// const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
// const poadmin = localStorage.getItem('poadmin');
// const potoken = localStorage.getItem('potoken');


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface TVShow {
    tvfid: string;
    tvfhash: string;
    tvfname: string;
    episodes: number;
}

interface YourComponentProps {
    TVdash: TVShow[];
    getTVepisodesFolder: (tvfid: string) => void;
}

const TVSeries: React.FC<YourComponentProps> = () => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (scrollRef.current) {
            isDown = true;
            startX = e.pageX - scrollRef.current.offsetLeft;
            scrollLeft = scrollRef.current.scrollLeft;
        }
    };

    const handleMouseLeave = () => {
        isDown = false;
    };

    const handleMouseUp = () => {
        isDown = false;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDown) return;
        e.preventDefault();
        if (scrollRef.current) {
            const x = e.pageX - scrollRef.current.offsetLeft;
            const walk = (x - startX) * 2; // scroll-fast
            scrollRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const [isLoading, setisLoading] = useState(true);
    const [tvepisodes, setTVepisodes] = useState<any[]>([]);
    const [tvfolders, setTVfolders] = useState<any[]>([]);
    const [TVdash, setTVdash] = useState<any[]>([]);
    // const [tvfolder, setTVfolder] = useState(0);
    // const [droptvfid, setdroptvfid] = useState(0);
    const [dropupload, setdropupload] = useState<any[]>([]);
    const [isUploadingDropzone, setIsUploadingDropzone] = useState(false);
    const [modalFileUpload, setmodalFileUpload] = useState(false);
    const [modalNewFolder, setmodalNewFolder] = useState(false);

    const potoken = localStorage.getItem('potoken') || "";
    const cancelTokenSourceRef = useRef(axios.CancelToken.source());

    const [dragOver, setDragOver] = React.useState(false);

    // const handleDragOverStart = (tvfid: number) => {
    //     setdroptvfid(tvfid);
    //     console.log("dragover: " + tvfid);
    //     setDragOver(true);
    // }
    const handleDragOverStart = (event: React.DragEvent<HTMLDivElement>) => {
        // setdroptvfid(tvfid);
        event.currentTarget.classList.toggle('draggingover');
        setDragOver(true);
    }
    const handleDragOverEnd = (event: React.DragEvent<HTMLDivElement>) => {
        event.currentTarget.classList.toggle('draggingover');
        setDragOver(false);
    }
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('text', event.currentTarget.id);
        event.currentTarget.classList.add('dragging');

        // console.log("setter: " event.currentTarget.id);
        // event.dataTransfer.setData('text/plain', event.currentTarget.dataset.id)
    }
    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        const tvfolderid = event.currentTarget.dataset.tvfolderid;
        const tvid = Number(event.dataTransfer.getData('text/plain'));
        event.currentTarget.classList.toggle('draggingover');
        var body = {};
        axios.post(APIURL + "/tv/move/" + tvid + "/" + tvfolderid, body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getTVepisodes();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });



        // const id = event.dataTransfer.getData('text');
        // const folderid=event.target.getData('ariaLabel');
        // const folderid = event.dataTransfer.getData('id');
        // console.log("drop id: " + id + " folder: " + droptvfid);
        setDragOver(false);
    }


    const getFileUploadParams = ({ file, meta }: any) => {
        const body = new FormData();
        body.append('token', potoken);
        body.append('file', file);
        return { url: 'https://tv.paraoffice.com/tvupload.php', body }
    }
    const updateFileUploadStatus = (id: any, percent: any) => {
        setdropupload(
            dropupload.map((item) => {
                if (item.id === id) {
                    // console.log(item.id, percent);
                    return { ...item, percent };
                } else {
                    return item;
                }
            })
        );
    };


    const handleFileUpload = (
        file: File,
        onUploadProgress: (event: ProgressEvent) => void,
        onUploadSuccess: () => void,
        onUploadError: (error: string) => void
    ) => {
        const body = new FormData();
        body.append('token', potoken);
        body.append('file', file);
        axios({
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: body,
            url: "https://tv.paraoffice.com/tvupload.php",
            onUploadProgress,
            cancelToken: cancelTokenSourceRef.current.token
        })
            .then(() => {
                onUploadSuccess();
            })
            .catch((err: Error) => {
                onUploadError(err.message);
            });
    };

    const handleFileCancel = () => {
        cancelTokenSourceRef.current.cancel("Upload cancelled");
    };


    async function getTVdash() {
        await axios.get(APIURL + "/tv/dash", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTVdash(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }


    async function getTVepisodes() {
        var body = {
        };
        await axios.get(APIURL + "/tv/folder", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTVfolders(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });

        await axios.post(APIURL + "/v2/tvepisodes", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTVepisodes(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    async function getTVepisodesFolder(tvfolder: number) {
        var body = {
        };
        await axios.post(APIURL + "/v2/tvepisodes/" + tvfolder, body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTVfolders(([]));
                    setTVepisodes(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });

    }

    function handleOpenFileUploadModal() {
        setmodalFileUpload(true);
    };
    const handleCloseFileUploadModal = () => {
        setmodalFileUpload(false);

    };
    const handleOpenModalNewFolder = () => {
        setmodalNewFolder(true);
    };

    const handleCloseModalNewFolder = () => {
        setmodalNewFolder(false);
    };

    const submitNewFolder = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalNewFolder(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            tvfname: data.get('tvfname'),
        };
        axios.post(APIURL + "/tv/folder", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    // getTVepisodes();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    useEffect(() => {
        document.title = 'TV - ParaOffice';
        getTVdash();
        setisLoading(false);
    }, []);

    // useEffect(() => {
    //     setisLoading(true);
    //      getTVepisodes();
    // }, []);



    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Forsiden
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    TV
                </Typography>
            </Breadcrumbs>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={modalFileUpload}
                onClose={handleCloseFileUploadModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }}>Last opp fil(er)</Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseFileUploadModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className="App">
                        <TVFileUpload onFileUpload={handleFileUpload} onCancel={handleFileCancel} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        type="submit"
                        variant="outlined"
                        color="error"
                        onClick={handleCloseFileUploadModal}
                    >
                        <CloseIcon className='pobtnicon' />
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={modalNewFolder}
                onClose={handleCloseModalNewFolder}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Legg til ny mappe
                </DialogTitle>
                <Box component="form" onSubmit={submitNewFolder}>
                    <DialogContent>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Mappenavn"
                                name="tvfname"
                            >
                            </TextField>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            type="submit"
                            variant="outlined"
                            color="success"
                        >
                            <SaveIcon className='pobtnicon' />
                            Legg til
                        </Button>
                        <Button
                            size="small"
                            type="submit"
                            variant="outlined"
                            color="error"
                            onClick={handleCloseModalNewFolder}
                        >
                            <CloseIcon className='pobtnicon' />
                            Avbryt
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            <main>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box m={2}>
                            <p></p>
                            {!isLoading ?
                                <div>
                                    <Typography variant="h5" color="text.secondary">
                                        Norske serier
                                    </Typography>
                                    <Box
                                        className="scroll-container"
                                        ref={scrollRef}
                                        sx={{
                                            overflowX: 'scroll',
                                            whiteSpace: 'nowrap',
                                            padding: 2,
                                            cursor: 'grab'
                                        }}
                                        onMouseDown={handleMouseDown}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseUp={handleMouseUp}
                                        onMouseMove={handleMouseMove}
                                    >
                                        {TVdash[0]?.norsk.map(({ tvfid, tvfname, seasons, episodes }: any, index: React.Key | null | undefined) => (
                                            <Link href={"/tvseries/" + tvfid}>
                                                <Box
                                                    className='tvbox'
                                                    key={index}
                                                    sx={{
                                                        display: 'inline-block',
                                                        width: 300,
                                                        height: 400,
                                                        marginRight: 2,
                                                        textAlign: 'left',
                                                        bgcolor: 'background.paper',
                                                        borderRadius: 1,
                                                        boxShadow: 3,
                                                        cursor: 'pointer'
                                                    }}
                                                // onClick={() => getTVepisodesFolder(tvfid)}
                                                >
                                                    <img src="https://www.ghosthunters.no/pb.jpg" className="img-fluid"></img>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {tvfname}
                                                    </Typography>
                                                    <Typography component="div" variant="caption" color="text.secondary">
                                                        {seasons} sesong(er), {episodes} episode(r)
                                                    </Typography>
                                                </Box>
                                            </Link>
                                        ))}
                                    </Box>
                                    <p></p>
                                    <Typography variant="h5" color="text.secondary">
                                        Engelske serier
                                    </Typography>
                                    <Box
                                        className="scroll-container"
                                        ref={scrollRef}
                                        sx={{
                                            overflowX: 'scroll',
                                            whiteSpace: 'nowrap',
                                            padding: 2,
                                            cursor: 'grab'
                                        }}
                                        onMouseDown={handleMouseDown}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseUp={handleMouseUp}
                                        onMouseMove={handleMouseMove}
                                    >
                                        {TVdash[0]?.engelsk.map(({ tvfid, tvfname, seasons, episodes }: any, index: React.Key | null | undefined) => (
                                            <Link href={"/tvseries/" + tvfid}>
                                            <Box
                                                className='tvbox'
                                                key={index}
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 300,
                                                    height: 400,
                                                    marginRight: 2,
                                                    textAlign: 'left',
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1,
                                                    boxShadow: 3,
                                                    cursor: 'pointer'
                                                }}
                                                // onClick={() => getTVepisodesFolder(tvfid)}
                                            >
                                                <img src="https://www.ghosthunters.no/pb.jpg" className="img-fluid"></img>
                                                <Typography variant="body2" color="text.secondary">
                                                    {tvfname}
                                                </Typography>
                                                <Typography component="div" variant="caption" color="text.secondary">
                                                    {seasons} sesong(er), {episodes} episode(r)
                                                </Typography>
                                            </Box>
                                            </Link>
                                        ))}
                                    </Box>
                                    {/* <Grid container spacing={2}>
                                        {tvfolders.map(({ tvfid, tvfhash, tvfname }: any, index: number) => {
                                            return <Grid item xs={2}>
                                                <Link href='#' underline="none" onClick={e => getTVepisodesFolder(tvfid)} sx={{ ml: 0 }}>
                                                    <div onDragOver={enableDropping}
                                                        onDrop={handleDrop}
                                                        onDragEnter={handleDragOverStart}
                                                        onDragLeave={handleDragOverEnd}
                                                        color="text.secondary" className='tvseriesbox'
                                                        id={tvfhash}
                                                        data-tvfolderid={tvfid}
                                                        key={"folder" + index}
                                                    >
                                                        <Typography align="right" sx={{ mr: 2 }}>
                                                            ...
                                                        </Typography>
                                                        <CardMedia
                                                            component="img"
                                                            height="100%"
                                                            image="/images/tvmissinglogo.jpg"
                                                            aria-label={tvfid}
                                                        />
                                                        <Typography align="center">
                                                            {tvfname}
                                                        </Typography>
                                                    </div>
                                                </Link>
                                            </Grid>
                                        })
                                        }
                                    </Grid> */}
                                    <Grid container>
                                        {tvfolders.map(({ tvfid, tvfhash, tvfname, episodes }: any, index: number) => {
                                            return <Grid item xs={2}>
                                                <Link href='#' underline="none" onClick={e => getTVepisodesFolder(tvfid)} sx={{ ml: 0 }}>
                                                    <div onDragOver={enableDropping}
                                                        onDrop={handleDrop}
                                                        onDragEnter={handleDragOverStart}
                                                        onDragLeave={handleDragOverEnd}
                                                        color="text.secondary" className='tvseriesbox'
                                                        id={tvfhash}
                                                        data-tvfolderid={tvfid}
                                                        key={"folder" + index}
                                                    >
                                                        <Typography align="left">
                                                            <Badge badgeContent={episodes} color="primary" sx={{ mr: 2 }}>
                                                                <FolderIcon color="warning" />
                                                            </Badge>
                                                            {tvfname}
                                                        </Typography>
                                                    </div>
                                                </Link>
                                            </Grid>
                                        })
                                        }
                                    </Grid>
                                    <Grid container>
                                        {tvepisodes.map(({ tvid, tvfilename, tvdisk }: any, index: number) => {
                                            return <Grid item xs={4}><span key={"tvid" + tvid} draggable="true" onDragStart={handleDragStart} id={tvid} className='tvseriesname'>
                                                <Link href={"https://tv.paraoffice.com/disk1/" + tvfilename} underline="hover" className="tvepisodelist">
                                                    {tvfilename}
                                                </Link><br />
                                            </span></Grid>
                                        })
                                        }
                                    </Grid>
                                </div>
                                :
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            }
                        </Box>
                    </Grid>
                    {(pomod === "1") &&
                        <IconButton color="primary" aria-label="Legg til mappe" onClick={e => handleOpenModalNewFolder()}>
                            <CreateNewFolderIcon className='iconsmall' />
                        </IconButton>
                    }

                    <Grid item xs={4}>
                        <Box m={2}>
                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                <ContentPasteSearchIcon className='potexticonxl' /> Søk og filtrering
                            </Typography>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid item xs={12} p={2}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="tvfilename"
                                            label="Navn på TV serie/episode"
                                            name="tvfilename"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    getTVepisodes();
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {(pomod === "1") &&
                                        <IconButton color="primary" aria-label="Legg til filer" onClick={e => handleOpenFileUploadModal()}>
                                            <CloudUploadIcon className='iconsmall' />
                                        </IconButton>
                                    }

                                    {/* {(pomod === "1") &&
                                        <Dropzone
                                            getUploadParams={getFileUploadParams}
                                            onChangeStatus={handleFileChangeStatus}
                                            LayoutComponent={Layout}
                                            onSubmit={handleFileSubmit}
                                            inputContent="Last opp"
                                        />
                                    } */}

                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <Dialog
                    fullScreen
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isUploadingDropzone}
                >
                    {
                        dropupload && dropupload.map(({ id, name, size, percent }: any) => {
                            return <div key={"imgup" + id}>
                                <Typography variant="h6">{name + " (" + size + ")"}</Typography>
                                <LinearProgressWithLabel color="warning" value={percent} />
                            </div>
                        })
                    }
                </Dialog>

            </main >
        </ThemeProvider >
    );
}

export default TVSeries;