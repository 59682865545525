import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Button, Card, CardContent, CircularProgress, createTheme, CssBaseline, Grid, MenuItem, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';


const APIURL = process.env.REACT_APP_APIURL;

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function ForgotPassword() {
    const [resetusername, setResetUsername] = useState("null");;
    const [tryReset, settryReset] = useState(false);
    const [isLoadingReset, setisLoadingReset] = useState(false);
    const [isLoadingResetPassword, setisLoadingResetPassword] = useState(false);
    const [tryResetPassword, settryResetPassword] = useState(false);
    const [gotEmail, setgotEmail] = useState(false);

    useEffect(() => {
        document.title = 'ParaOffice';
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setisLoadingReset(true);
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        let rusername = data.get('username');
        setResetUsername(String(rusername));
        var formdata = {
            username: data.get('username'),
            useremail: data.get('useremail'),
        };
        await axios.post(APIURL + "/profileforgotpassword", formdata, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if ((response.status === 200) && (response.data[0].userid)) {
                    setgotEmail(true);
                }
                else {
                    settryReset(true);
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingReset(false);
    }
    const handleSubmitNewPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        setisLoadingResetPassword(true);
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        var formdata = {
            username: data.get('username'),
            userpasswordtemp: data.get('userpasswordtemp'),
            userpassword: data.get('userpassword'),
            userpasswordsalt: data.get('userpasswordsalt'),
        };
        await axios.post(APIURL + "/profileforgotpasswordreset", formdata, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if ((response.status === 200) && (response.data[0].userid)) {
                    window.location.assign("/");
                }
                else {
                    settryResetPassword(true);
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingResetPassword(false);
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box m={2}>
                            <Typography variant="h5" component="h2">
                                Glemt passord
                            </Typography>
                            {!gotEmail &&
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    {tryReset &&
                                        <Alert severity="error">Opplysningene du oppgav er ikke riktig.</Alert>
                                    }
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Brukernavn"
                                        name="username"
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="useremail"
                                        label="E-post"
                                        id="useremail"
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                sx={{ mt: 3 }}
                                            >
                                                Send meg engangspassord
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        pt={2}
                                        pr={2}
                                    >
                                    </Grid>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={isLoadingReset}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                            }
                            {gotEmail &&
                                <Box component="form" onSubmit={handleSubmitNewPassword} noValidate sx={{ mt: 1 }}>
                                    {!tryResetPassword &&
                                        <Alert severity="success">Engangspassord er sendt til din e-post.</Alert>
                                    }
                                    {tryResetPassword &&
                                        <Alert severity="error">Engangspassordet du oppgav er ikke riktig.</Alert>
                                    }
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Brukernavn"
                                        name="username"
                                        value={resetusername}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="userpasswordtemp"
                                        label="Engangspassord"
                                        name="userpasswordtemp"
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="userpassword"
                                        label="Nytt passord"
                                        id="userpassword"
                                        type="password"
                                    />
                                    <Alert severity="info">Du kan velge styrke på krypteringsnøkkelen som blir brukt på passordet ditt i databasen. Det minste valget er 15 som er veldig sterkt i seg selv, men ønsker du sterkere kan du velge det. Merk at det tar lenger tid å logge inn på ParaOffice desto høyere styrke du har. Antall forventet sekunder innlogging tar står i parantes bak styrken i nedtrekksmenyen under.</Alert>
                                    <p></p>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Krypterings-sikkerhetsnivå"
                                        name="userpasswordsalt"
                                        defaultValue={15}
                                    >
                                        <MenuItem key="15" value="15">15 (2s)</MenuItem>
                                        <MenuItem key="16" value="16">16 (4s)</MenuItem>
                                        <MenuItem key="17" value="17">17 (8s)</MenuItem>
                                        <MenuItem key="18" value="18">18 (16s)</MenuItem>
                                    </TextField>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                sx={{ mt: 3 }}
                                            >
                                                Lagre nytt passord
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        pt={2}
                                        pr={2}
                                    >
                                    </Grid>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={isLoadingResetPassword}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} m={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body2">
                                    Når du sender inn skjemaet med riktige opplysninger vil du motta et engangspassord på e-post som er gyldig i en time.<p></p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </main>
        </ThemeProvider>
    );
}

export default ForgotPassword;