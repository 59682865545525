import { useEffect, useState } from 'react';
import '../App.css';
import { Autocomplete, Backdrop, Box, Breadcrumbs, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, MenuItem, Pagination, Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import StorageIcon from '@mui/icons-material/Storage';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import TimeHHMMSS from '../components/TimeHHMMSS';
import { useParams } from 'react-router-dom';

const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken');

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function EventCases() {
    const [isLoading, setisLoading] = useState(true);
    const { statusid } = useParams();
    const [events, setEvents] = useState<any[]>([]);
    const [eventtypes, setEventTypes] = useState<any[]>([]);
    const [eventstatuses, setEventStatuses] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [filtereventtype, setfiltereventtype] = useState(0);
    const [filtereventstatus, setfiltereventstatus] = useState(0);
    const [filtereventteam, setfiltereventteam] = useState(0);
    const [filterlocation, setfilterlocation] = useState(0);
    const [filtereventname, setfiltereventname] = useState("");

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    async function getevents() {
        var body = {
            eventstatusid: filtereventstatus,
            teamid: filtereventteam,
            locationid: filterlocation,
            eventname: filtereventname,
            page: page
        };
        await axios.post(APIURL + "/v5/eventcases", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setEvents(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const handleSelectFilterStatus = (eventstatusname: any, eventstatusid: any) => {
        if (eventstatusid?.id) {
            setfiltereventstatus(eventstatusid.id);
        }
        else {
            setfiltereventstatus(0);
        }
    }
    const handleSelectFilterLocation = (locationname: any, locationid: any) => {
        if (locationid?.id) {
            setfilterlocation(locationid.id);
        }
        else {
            setfilterlocation(0);
        }
    }

    const handleSelectFilterTeam = (teamname: any, teamid: any) => {
        if (teamid?.id) {
            setfiltereventteam(teamid.id);
        }
        else {
            setfiltereventteam(0);
        }
    }

    const handleTypeEventName = (eventname: any) => {
        setfiltereventname(eventname);
    }


    async function geteventstatuses() {
        await axios.get(APIURL + "/v2/optionseventstatuses", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStatuses(response.data);
            })
    }

    async function getteams() {
        await axios.get(APIURL + "/v2/optionsteams", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setTeams(response.data);
            })
    }

    async function getlocations() {
        await axios.get(APIURL + "/v2/optionslocations", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocations(response.data);
            })
    }

    useEffect(() => {
        document.title = 'Events - ParaOffice';
        getevents();
        geteventstatuses();
        getteams();
        getlocations();
        setisLoading(false);
    }, []);

    useEffect(() => {
        if (statusid) {
            setfiltereventstatus(Number(statusid));
        }
        setisLoading(true);
        getevents();
    }, [filtereventtype, filtereventstatus, filtereventteam, filterlocation, page]);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box m={2}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <EventIcon className='potexticonxl' /> Events ({events[0]?.eventstotal})
                                        </Typography>
                                        <Stack spacing={2}>
                                            <Pagination count={Math.ceil(events[0]?.eventstotal / 10)} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                                        </Stack>
                                    </Grid>
                                    <p></p>




                                    {!isLoading ?
                                        <div>
                                            {events[0]?.events.map(({ eventteams, effilestotallen, missingrecuser, missingfiledesc, effilesnewlen, missingclips, eventownerteamid, eventtypename, eventownerusername, eventaccesslevelname, eventstoragename, eventstorageidb1disk, eventstorageidb1name, eventstorageidb1ts, eventhash, eventname, eventdatedisp, teamname, eventstatusname, eventclassname, eventpublic }: any, index: number) => {
                                                var eventpub = '';
                                                if (eventpublic === 1) {
                                                    eventpub = "CardPublic";
                                                }
                                                else {
                                                    eventpub = '';
                                                }
                                                return <Card variant="outlined" key={"eventhash" + eventhash} sx={{ mb: 1 }} className={eventpub}>
                                                    <CardContent>
                                                        <Grid container width='100%'>
                                                            <Grid item>
                                                                <Box sx={{ height: 150, width: 250 }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={"https://poimg01.paraoffice.com/event/" + eventhash + "_t.png?" + Date.now()}
                                                                        alt="random"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                                <Link href={"/event/" + eventhash}>{eventname}</Link>
                                                                <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <CalendarMonthIcon sx={{ mr: 1 }} className='potextico' /> {eventdatedisp} &nbsp; <PersonIcon sx={{ mr: 1 }} className='potextico' /> {eventownerusername} &nbsp; <LockPersonIcon sx={{ mr: 1 }} className='potextico' /> {eventaccesslevelname} &nbsp; <StorageIcon sx={{ mr: 1 }} className='potextico' /> {eventstoragename} &nbsp; <StorageIcon sx={{ mr: 1 }} className='potextico' /> {eventstorageidb1name} -  {eventstorageidb1disk} ({eventstorageidb1ts})
                                                                </Typography>
                                                                <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        Status: <span className='infobox'>{eventstatusname}</span> Type: <span className='infobox'>{eventtypename}</span> <span className='infobox'>{eventclassname}</span> Offentlig publisert: {(eventpublic === 1) ? <span className='infobox'>Ja</span> : <span className='infobox'>Nei</span>}
                                                                    </Typography>
                                                                </Typography>
                                                                <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    Opptakstid: <span className='infobox'>{TimeHHMMSS(effilestotallen)}</span> &nbsp; Ikke gjennomgått: <span className='infobox'>
                                                                        {(effilesnewlen === null) ?
                                                                            <span className="pogreen">0</span>
                                                                            :
                                                                            <span className="pored">{TimeHHMMSS(effilesnewlen)}</span>
                                                                        }
                                                                    </span>
                                                                    Mangler klipp: &nbsp; <span className='infobox'>
                                                                        {(missingclips === 0) ?
                                                                            <span className='pogreen'>{missingclips}</span>
                                                                            :
                                                                            <span className='pored'>{missingclips}</span>
                                                                        }
                                                                    </span>
                                                                    Mangler filbeskrivelse: &nbsp; <span className='infobox'>
                                                                        {(missingfiledesc === 0) ?
                                                                            <span className='pogreen'>{missingfiledesc}</span>
                                                                            :
                                                                            <span className='pored'>{missingfiledesc}</span>
                                                                        }
                                                                    </span>
                                                                    Mangler ansvarlig på fil: &nbsp; <span className='infobox'>
                                                                        {(missingrecuser === 0) ?
                                                                            <span className='pogreen'>{missingrecuser}</span>
                                                                            :
                                                                            <span className='pored'>{missingrecuser}</span>
                                                                        }
                                                                    </span>
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                    <span className='poteambox poteamboxowner'>{teamname}</span>
                                                                    {
                                                                        eventteams && eventteams.map(({ teamid, teamname }: any) => {
                                                                            return (
                                                                                <span key={"eventteams" + eventhash + "t" + teamid}>
                                                                                    {
                                                                                        (teamid !== eventownerteamid) &&
                                                                                        <span className='poteambox'>{teamname}</span>
                                                                                    }
                                                                                </span>
                                                                            )
                                                                        })
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            })
                                            }
                                        </div>
                                        :
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={isLoading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box m={2}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <ContentPasteSearchIcon className='potexticonxl' /> Søk og filtrering
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid item xs={12} p={2}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="eventname"
                                                    label="Navn på event"
                                                    name="eventname"
                                                    onChange={(newValue) => handleTypeEventName(newValue.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            getevents();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationid"
                                                    options={locations}
                                                    onChange={(e, v) => handleSelectFilterLocation(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Lokasjon"
                                                        name="locationid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="eventstatusid"
                                                    options={eventstatuses}
                                                    onChange={(e, v) => handleSelectFilterStatus(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Status"
                                                        name="eventstatusid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="teamid"
                                                    options={teams}
                                                    onChange={(e, v) => handleSelectFilterTeam(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Team"
                                                        name="teamid"
                                                    />}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default EventCases;