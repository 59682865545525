import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Button, CircularProgress, createTheme, CssBaseline, Grid, IconButton, TextField, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import LoginIcon from '@mui/icons-material/Login';
import PinInput from 'react-pin-input';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const APIURL = process.env.REACT_APP_APIURL;

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Home() {
    useEffect(() => {
        document.title = 'ParaOffice';
    }, []);
    const [isLoggingOn, setisLoggingOn] = useState(false);
    const [AuthError, setAuthError] = useState(false);
    const [pin, setPin] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handlePinChange = (value: string) => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setPin(sanitizedValue);
    };
    const handleUsernameChange = (value: string) => {
        const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');
        setUsername(sanitizedValue);
    };
    const handlePasswordChange = (value: string) => {
        setPassword(value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setisLoggingOn(!isLoggingOn);
        event.preventDefault();
        var formdata = {
            username: username,
            password: password,
            pincode: pin,
        };
        axios.post(APIURL + "/token", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                setisLoggingOn(false);
                if (response.status === 200) {
                    console.log("oooke");
                    setAuthError(false);
                    let accesstoken = response.data.accesstoken;
                    localStorage.setItem('pouser', String(response.data.username));
                    localStorage.setItem('poname', response.data.name);
                    localStorage.setItem('potoken', accesstoken);
                    localStorage.setItem('pomod', response.data.usermod);
                    localStorage.setItem('poadmin', response.data.useradmin);
                    localStorage.setItem('pouserid', response.data.userid);
                    localStorage.setItem('poteamid', response.data.teamid);
                    localStorage.setItem('poteamname', response.data.teamname);
                    localStorage.setItem('pouserteamaccess', response.data.teamaccess);
                    await axios.get(APIURL + "/v3/agreement/0", { withCredentials: true })
                        .then(async (agres: any) => {
                            if (agres.status === 200) {
                                console.log("ok2");
                                if (agres.data[0].agreementid > response.data.poagreementid) {
                                    window.location.assign("/agreement");
                                }
                                else {
                                    window.location.assign("/dashboard");
                                }
                            }
                            else {
                            }
                        })
                        .catch((e: any) => {
                            if (e.response!.status === 403) {
                                window.location.assign("/logout");
                            }
                        });

                }
                else {
                    setAuthError(true);
                }
            })
            .catch((e: Error) => {
                setisLoggingOn(false);
                setAuthError(true);
            });
    }
    return (
        <ThemeProvider theme={theme}>
            <div className='loginbody'>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Backdrop
                            className='backdropbg'
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoggingOn}
                        >
                            <Typography variant="h6" className="polightgreen" sx={{ mr: 5 }}>Logging in...</Typography> <CircularProgress className="polightgreen" />
                        </Backdrop>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box component="form" onSubmit={handleSubmit} className="poboxlogin" p={2}>
                                <Typography variant="h6" align="center">
                                    ParaOffice Login
                                </Typography>
                                {AuthError && <Box sx={{ marginTop: 3, width: '100%' }}>
                                    <Alert
                                        variant="standard" severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setAuthError(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        Login error
                                    </Alert>
                                </Box>
                                }
                                <TextField
                                    variant="standard"
                                    className='loginfield'
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    onChange={(event) => handleUsernameChange(event.target.value)}
                                    autoFocus
                                />
                                <TextField
                                    variant="standard"
                                    className='loginfield'
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    onChange={(event) => handlePasswordChange(event.target.value)}
                                    id="password"
                                />
                                <Typography variant="caption" align="center">
                                    PIN access code
                                </Typography>
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    secret
                                    secretDelay={300}
                                    onChange={(value, index) => handlePinChange(value)}
                                    type="numeric"
                                    inputMode="number"
                                    style={{ 
                                        padding: '5px',
                                    }}
                                    inputStyle={{
                                        color: 'green',
                                        borderColor: '#00352c',
                                        borderRadius: '5px 5px 5px 5px'
                                    }}
                                    inputFocusStyle={{ borderColor: 'white' }}
                                    autoSelect={true}
                                    regexCriteria={/^[0-9]*$/}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                        variant="outlined"
                                            fullWidth
                                            type="submit"
                                            color="success"
                                            sx={{ mt: 3 }}
                                        >
                                            <LoginIcon sx={{ mr: 1 }} /> Login
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            href="/forgotpassword"
                                            color="error"
                                            sx={{ mt: 3 }}
                                        >
                                            <QuestionMarkIcon sx={{ mr: 1 }} /> Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
            </div>
        </ThemeProvider >
    );
}

export default Home;