import * as React from 'react';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Navigator from '../components/Nav';
import Header from '../components/Header';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia/CardMedia';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import EventIcon from '@mui/icons-material/Event';
const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function DashFooter() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            ParaOffice (c) {new Date().getFullYear()}
        </Typography>
    );
}

const drawerWidth = 256;

export default function Members() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [isLoading, setisLoading] = useState(true);
    const [users, setUsers] = useState<any[]>([]);

    async function getUsers() {
        await axios.get(APIURL + "/v3/users", { withCredentials: true })
            .then(async (response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setUsers(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
        setisLoading(false);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        document.title = 'ParaOffice';
        getUsers();
    }, []);



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" color="text.secondary" gutterBottom className='potypeicon'>
                                        <EventIcon className='potexticonxl' /> Aktive medlemmer
                                    </Typography>
                                </Grid>
                                <Grid container>
                                    {users?.map(({ userid, userhash, username, teamname, userlastseen }: any, index: number) => {
                                        return <Grid item xs={2} sx={{ mr: 2 }}>
                                            <Box className='pobox'>
                                                <Box m={2}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ display: { xs: 'block' }, paddingBottom: 2 }}
                                                        image={"https://poimg01.paraoffice.com/profile/" + userhash + ".png?" + Date.now()}
                                                        alt={username}
                                                    />
                                                    <Typography variant="body1" color="text.secondary">
                                                        {username}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {teamname}
                                                    </Typography>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Sist aktiv: {userlastseen}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component="footer" sx={{ p: 2, bgcolor: 'rgba(0, 0, 0, 0.3)' }}>
                        <DashFooter />
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    );
}