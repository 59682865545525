import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Link, MenuItem, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CakeIcon from '@mui/icons-material/Cake';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LanguageIcon from '@mui/icons-material/Language';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';

const APIURL = process.env.REACT_APP_APIURL;
const APIFSURL = process.env.REACT_APP_APIFSURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function TeamAdmin() {
    const [isLoading, setisLoading] = useState(true);
    const [team, setTeam] = useState<any[]>([]);
    const [teamAccess, setTeamAccess] = useState<any[]>([]);
    const [teamModal, setTeamModal] = useState(false);
    const [teamMemberModal, setTeamMemberModal] = useState(false);
    const [teamMemberDeleteModal, setTeamDeleteMemberModal] = useState(false);
    const [ModalNewTeamMember, setModalNewTeamMember] = useState(false);
    const [teamUpdated, setTeamUpdated] = useState(false);
    const [isTeamAdmin, setisTeamAdmin] = useState(false);
    // const [isTeamEco, setisTeamEco] = useState(false);


    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [teamMember, setTeamMember] = useState<any[]>([]);

    const [imageModal, setImageModal] = useState(false);
    const handleClickImageModalOpen = () => {
        setImageModal(true);
    };

    const handleClickImageModalClose = () => {
        setImageModal(false);
    };


    const handleClickTeamModalOpen = () => {
        setTeamModal(true);
    };

    const handleClickTeamModalClose = () => {
        setTeamModal(false);
    };

    const handleClickTeamMemberModalOpen = (memberid: any) => {
        axios.get(APIURL + "/mod/teammember/" + memberid, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setTeamMember(response.data);
                    setTeamMemberModal(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    };

    const handleClickTeamMemberDeleteModalOpen = (memberid: any) => {
        axios.get(APIURL + "/mod/teammember/" + memberid, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setTeamMember(response.data);
                    setTeamDeleteMemberModal(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    };

    const handleSubmitTeamImage = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setImageModal(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            token: localStorage.getItem("potoken"),
            type: data.get('type'),
            file: data.get('file'),
        };
        await axios.post(APIFSURL + "/upload.php", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleClickTeamMemberModalClose = () => {
        setTeamMemberModal(false);
    };
    const handleClickTeamMemberDeleteModalClose = () => {
        setTeamDeleteMemberModal(false);
    };
    const ModalNewTeamMemberClose = () => {
        setModalNewTeamMember(false);
    };
    const ModalNewTeamMemberOpen = () => {
        setModalNewTeamMember(true);
    };

    const handleCloseTeamUpdated = () => {
        setTeamUpdated(false);
    };


    const SubmitNewTeamMember = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalNewTeamMemberClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            username: data.get('username'),
            userfullname: data.get('userfullname'),
            useremail: data.get('useremail'),
        };
        await axios.post(APIURL + "/user", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getteam();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const handleSubmitTeam = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickTeamModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            teamname: data.get('teamname'),
            teamshortname: data.get('teamshortname'),
            teambirthdisp: data.get('teambirthdisp'),
            teamurl: data.get('teamurl'),
            teaminstagram: data.get('teaminstagram'),
            teamfacebook: data.get('teamfacebook'),
            teamyoutube: data.get('teamyoutube'),
            teamwebinfo: data.get('teamwebinfo'),
        };
        await axios.put(APIURL + "/teamdetails", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTeamUpdated(true);
                    setTeam(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleSubmitTeamMember = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickTeamMemberModalClose();
        var data = new FormData(event.currentTarget);
        let teammemberid = data.get('teammemberid');
        var formdata = {
            teammemberid: data.get('teammemberid'),
            teammemberjoindatedisp: data.get('teammemberjoindatedisp'),
            teammemberaccessid: data.get('teammemberaccessid'),
            teammemberpaid: data.get('teammemberpaid'),
        };
        await axios.put(APIURL + "/mod/teammember/" + teammemberid, formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTeam(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    const handleSubmitDeleteTeamMember = async () => {
        let teammemberid = teamMember[0]?.teammemberid;
        await axios.delete(APIURL + "/mod/teammember/" + teammemberid, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTeam(response.data);
                    setTeamDeleteMemberModal(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    function getteam() {
        axios.get(APIURL + "/teamdetails", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setTeam(response.data);
                    console.log(response.data);
                    setisLoading(false);
                    if (response.data[0].teammemberaccessid === 1) {
                        // setisTeamEco(true);
                        setisTeamAdmin(true);
                    }
                    if (response.data[0].teammemberaccessid === 2) {
                        setisTeamAdmin(true);
                        // setisTeamEco(true);
                    }
                    if (response.data[0].teammemberaccessid === 3) {
                        setisTeamAdmin(true);
                        // setisTeamEco(true);
                    }
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    function getteamaccess() {
        axios.get(APIURL + "/v5/teamaccesslist", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setTeamAccess(response.data);
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    useEffect(() => {
        document.title = 'ParaOffice';
        getteam();
        getteamaccess();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                {isLoading ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Header onDrawerToggle={handleDrawerToggle} />
                        <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}></Box>
                        <Snackbar open={teamUpdated} autoHideDuration={6000} onClose={handleCloseTeamUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                            <Alert onClose={handleCloseTeamUpdated} severity="success" sx={{ width: '100%' }}>
                                Teaminformasjon ble oppdatert!
                            </Alert>
                        </Snackbar>
                        <Dialog
                            open={teamMemberDeleteModal}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            onClose={handleClickTeamMemberDeleteModalClose}
                        >
                            <DialogTitle id="alert-dialog-title">
                                Slett medlem fra teamet
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Er du sikker på at du vil slette dette medlemmet fra teamet?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    color="success"
                                    onClick={handleSubmitDeleteTeamMember}
                                >
                                    <CheckIcon className='pobtnicon' />
                                    Bekreft
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={handleClickTeamMemberDeleteModalClose}
                                >
                                    <CloseIcon className='pobtnicon' />
                                    Avbryt
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={teamMemberModal}
                            onClose={handleClickTeamMemberModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Endre medlemskap
                            </DialogTitle>
                            <Typography pl={2}>{teamMember[0]?.userfullname}</Typography>
                            <Box component="form" onSubmit={handleSubmitTeamMember} noValidate>
                                <input name='teammemberid' type='hidden' value={teamMember[0]?.teammemberid}></input>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Grid container>
                                            <Grid item xs={12} p={2}>
                                                {isTeamAdmin &&
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="teammemberjoindatedisp"
                                                        label="Ble medlem dato"
                                                        name="teammemberjoindatedisp"
                                                        defaultValue={teamMember[0]?.teammemberjoindatedisp}
                                                        autoFocus
                                                    />
                                                }
                                                {!isTeamAdmin &&
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="teammemberjoindatedisp"
                                                        label="Ble medlem dato"
                                                        name="teammemberjoindatedisp"
                                                        value={teamMember[0]?.teammemberjoindatedisp}
                                                        autoFocus
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                {isTeamAdmin &&
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Medlemsklasse"
                                                        name="teammemberaccessid"
                                                        defaultValue={teamMember[0]?.teammemberaccessid}
                                                    >
                                                        {
                                                            teamAccess && teamAccess.map(({ id, label }: any) => {
                                                                return <MenuItem key={"acid" + id} value={id}>{label}</MenuItem>
                                                            })
                                                        }
                                                    </TextField>
                                                }
                                                {!isTeamAdmin &&
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Medlemsklasse"
                                                        name="teammemberaccessid"
                                                        value={teamMember[0]?.teammemberaccessid}
                                                    >
                                                        {
                                                            teamAccess && teamAccess.map(({ id, label }: any) => {
                                                                return <MenuItem key={"acid" + id} value={id}>{label}</MenuItem>
                                                            })
                                                        }
                                                    </TextField>
                                                }
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    label="Betalt medlemskontingent"
                                                    name="teammemberpaid"
                                                    defaultValue={teamMember[0]?.teammemberpaid}
                                                >
                                                    <MenuItem key="0" value="0">
                                                        Nei
                                                    </MenuItem>
                                                    <MenuItem key="1" value="1">
                                                        Ja
                                                    </MenuItem>
                                                </TextField>
                                            </Grid></Grid>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" type='submit' startIcon={<SaveIcon />}>Lagre</Button>
                                    <Button variant="outlined" color="error" onClick={handleClickTeamMemberModalClose} startIcon={<CloseIcon />}>Avbryt</Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        {isTeamAdmin &&
                            <Dialog
                                open={ModalNewTeamMember}
                                onClose={ModalNewTeamMemberClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Legg til nytt medlem
                                </DialogTitle>
                                <Box component="form" onSubmit={SubmitNewTeamMember} noValidate>
                                    <input name='teammemberid' type='hidden' value={teamMember[0]?.teammemberid}></input>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Grid container>
                                                <Grid item xs={12} p={2}>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="username"
                                                        label="Brukernavn"
                                                        name="username"
                                                        autoFocus
                                                    />
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="userfullname"
                                                        label="Fullt navn"
                                                        name="userfullname"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="useremail"
                                                        label="E-post"
                                                        name="useremail"
                                                        autoFocus
                                                    />
                                                </Grid>
                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant="outlined" type='submit' startIcon={<SaveIcon />}>Legg til nytt medlem</Button>
                                        <Button variant="outlined" color="error" onClick={ModalNewTeamMemberClose} startIcon={<CloseIcon />}>Avbryt</Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>
                        }
                        <Dialog
                            open={teamModal}
                            onClose={handleClickTeamModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Endre teaminformasjon
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitTeam} noValidate>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="teamname"
                                            label="Teamnavn"
                                            name="teamname"
                                            defaultValue={team[0]?.teamname}
                                            autoFocus
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="teamshortname"
                                            label="Teamnavn forkortelse"
                                            name="teamshortname"
                                            defaultValue={team[0]?.teamshortname}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="teambirthdisp"
                                            label="Etableringsdato"
                                            name="teambirthdisp"
                                            defaultValue={team[0]?.teambirthdisp}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="teamurl"
                                            label="Nettside URL"
                                            name="teamurl"
                                            defaultValue={team[0]?.teamurl}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="teaminstagram"
                                            label="Instagram ID/brukernavn"
                                            id="teaminstagram"
                                            defaultValue={team[0]?.teaminstagram}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="teamfacebook"
                                            label="Facebook ID/brukernavn"
                                            id="teamfacebook"
                                            defaultValue={team[0]?.teamfacebook}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="teamyoutube"
                                            label="YouTube ID/brukernavn"
                                            id="teamyoutube"
                                            defaultValue={team[0]?.teamyoutube}
                                        />
                                        <TextField
                                            multiline
                                            size="small"
                                            rows="10"
                                            margin="normal"
                                            fullWidth
                                            name="teamwebinfo"
                                            label="Team informasjon (offentlig)"
                                            id="teamwebinfo"
                                            defaultValue={team[0]?.teamwebinfo}
                                        />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Lagre
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClickTeamModalClose}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={8}>
                                <Card variant="outlined" sx={{ marginBottom: 2 }}>
                                    <CardContent>
                                        <Grid container spacing={2} p={2}>
                                            <Grid item xs={12} md={3}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ maxWidth: 250, display: { xs: 'block' }, paddingBottom: 2 }}
                                                    image={"https://poimg01.paraoffice.com/teams/" + team[0]?.teamhash + ".jpg?" + Date.now()}
                                                    alt={team[0]?.teamname}
                                                />
                                                <Button variant="outlined" size="small" color="warning" onClick={handleClickImageModalOpen}><EditIcon className='pobtnicon' /> Endre bilde</Button>
                                                <Dialog
                                                    open={imageModal}
                                                    onClose={handleClickImageModalClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <Box component="form" onSubmit={handleSubmitTeamImage} noValidate>
                                                        <input type='hidden' name='type' value='reactteamimage'></input>
                                                        <DialogTitle id="alert-dialog-title">
                                                            Last opp nytt profilbilde
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                <div className="container">
                                                                    <img style={{ height: '300px', width: '300px' }} src={"https://poimg01.paraoffice.com/teams/" + team[0]?.teamhash + ".jpg?" + Date.now()} alt="Teamlogo"></img>
                                                                </div>

                                                                <input
                                                                    name="file"
                                                                    type="file"
                                                                    disabled={isLoading}
                                                                    accept="image/*"
                                                                    placeholder="Velg fil"
                                                                />
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button type="submit">Oppdater</Button>
                                                            <Button onClick={handleClickImageModalClose} autoFocus>Avbryt</Button>
                                                        </DialogActions>
                                                    </Box>
                                                </Dialog>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {team[0]?.teamname} ({team[0]?.teamshortname})
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                    <CakeIcon className='potexticon' /> {team[0]?.teambirthdisp} ({team[0]?.teamage} år)
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                    <SupervisedUserCircleIcon className='potexticon' /> {team[0]?.teamownerusername}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                    <LanguageIcon className='potexticon' /> {team[0]?.teamurl}
                                                </Typography>
                                                <p></p>
                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                    <InfoIcon className='potexticonxl' /> Team informasjon
                                                </Typography>
                                                <Card>
                                                    <CardContent>
                                                        {team[0].teamwebinfo}
                                                    </CardContent>
                                                </Card>
                                                <p></p>
                                                <Button variant="outlined" color="warning" size="small" onClick={handleClickTeamModalOpen} sx={{ mr: 1 }}><EditIcon className='pobtnicon' /> Endre opplysninger</Button>
                                                <Button variant="outlined" color="primary" size="small" onClick={ModalNewTeamMemberOpen}><PersonAddAltIcon className='pobtnicon' /> Nytt medlem</Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <br />
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <FacebookIcon className='potexticonxl' /> <Link href={"https://facebook.com/" + team[0]?.teamfacebook} target='_blank'>{team[0]?.teamfacebook}</Link>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <InstagramIcon className='potexticonxl' /> <Link href={"https://instagr.am/" + team[0]?.teaminstagram} target='_blank'>{team[0]?.teaminstagram}</Link>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <YouTubeIcon className='potexticonxl' /> <Link href={"https://youtube.com/" + team[0]?.teamyoutube} target='_blank'>{team[0]?.teamyoutube}</Link>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    team[0]?.members && team[0]?.members.map(({ userid, userhash, userfullname, userage, teammemberjoindatedisp, teammemberid, teammemberyears, teammemberpaid, teamaccessname }: any) => {
                                        return <span key={userid}>
                                            <Card sx={{ mb: 1, pb: 1 }}>
                                                <Grid container width='100%'>
                                                    <Grid item>
                                                        <Box sx={{ height: 150, width: 150 }}>
                                                            <CardMedia
                                                                component="img"
                                                                image={"https://poimg01.paraoffice.com/profiles/" + userhash + "_p.jpg?" + Date.now()}
                                                                alt="random"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item ml={2} mt={1} mr={2} sx={{ flex: 1 }}>
                                                        {userfullname} ({userage})
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <PersonAddAltIcon className='potexticonxl' />  {teammemberjoindatedisp}  ({teammemberyears} år medlem)
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <AccountBoxIcon className='potexticonxl' />  {teamaccessname}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                            <PaidIcon className='potexticonxl' />
                                                            {
                                                                (teammemberpaid === 0) ?
                                                                    "Nei"
                                                                    :
                                                                    "Ja"
                                                            }
                                                        </Typography>
                                                        {isTeamAdmin &&
                                                            <Typography align='right'>
                                                                <Button variant="outlined" size="small" color="warning" onClick={e => handleClickTeamMemberModalOpen(teammemberid)} sx={{ mr: 1 }}><SettingsIcon className='pobtnicon' /> Endre</Button>
                                                                <Button variant="outlined" size="small" color="error" onClick={e => handleClickTeamMemberDeleteModalOpen(teammemberid)}><DeleteForeverIcon className='pobtnicon' /> Slett</Button>
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </span>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>
        </ThemeProvider >
    );
}














































export default TeamAdmin;