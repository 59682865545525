import React, { useRef, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Button from '@mui/material/Button';

interface WavesurferPlayerProps {
  audioFile: string; // Path to the audio file
}

// const ctx = document.createElement('canvas').getContext('2d');
// const gradient = ctx?.createLinearGradient(0, 0, 0, 150);
// gradient?.addColorStop(0, 'rgb(200, 0, 200)')
// gradient?.addColorStop(0.7, 'rgb(100, 0, 100)')
// gradient?.addColorStop(1, 'rgb(0, 0, 0)')


const WavesurferPlayer: React.FC<WavesurferPlayerProps> = ({ audioFile }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    // Create the Wavesurfer instance when the component mounts
    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current!,
      waveColor: 'rgb(255,255,255)',
      barWidth: 1,
      progressColor: 'rgba(0, 0, 100, 0.5)',
      //   responsive: true,
      height: 200,
    });

    // Load the audio file
    wavesurferRef.current.load(audioFile);

    // Autoplay the audio
    wavesurferRef.current.on('ready', () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.play();
      }
    });

    // Clean up the Wavesurfer instance when the component unmounts
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioFile]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (wavesurferRef.current.isPlaying()) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const volume = Number(event.target.value);
    if (wavesurferRef.current) {
      wavesurferRef.current.setVolume(volume);
    }
  };

  return (
    <div>
      <div ref={waveformRef} />
      <Button onClick={handlePlayPause} 
      
       variant="outlined"
      >
        {wavesurferRef.current?.isPlaying() ? 'Pause' : 'Play'}
      </Button>
      {/* <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={wavesurferRef.current?.getVolume() || 1}
        onChange={handleVolumeChange}
      /> */}
    </div>
  );
};

export default WavesurferPlayer;