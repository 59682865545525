import { useEffect, useState } from 'react';
import '../App.css';
import { Autocomplete, Backdrop, Box, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, MenuItem, Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import Pagination from '@mui/material/Pagination';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function Locations() {
    const [isLoading, setisLoading] = useState(true);
    const [locations, setLocations] = useState<any[]>([]);
    const [countrys, setCountrys] = useState<any[]>([]);
    const [filtercountry, setfiltercountry] = useState(1);
    const [filtercountryname] = useState("Norge");
    const [locationstates, setlocationstates] = useState<any[]>([]);
    const [filterstate, setfilterstate] = useState(0);
    const [filterlocationname, setfilterlocationname] = useState("");
    const [modalAddLocation, setModalAddLocation] = useState(false);
    const [locationStates, setLocationStates] = useState<any[]>([]);
    const [locationContries, setLocationContries] = useState<any[]>([]);
    const [locationVisit, setLocationVisit] = useState<any[]>([]);



    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    const handleOpenModalAddLocation = () => {
        axios.get(APIURL + "/v2/optionsstates", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationStates(response.data);
            })
        axios.get(APIURL + "/v2/optionscountrys", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationContries(response.data);
            })
        axios.get(APIURL + "/v3/optionslocationvisit", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationVisit(response.data);
            })
        setModalAddLocation(true);
    };

    const handleCloseModalAddLocation = () => {
        setModalAddLocation(false);
    };


    const [NewLocationStateId, setNewLocationStateId] = useState(0);

    const handleSelectState = (locationstateid: any) => {
        setNewLocationStateId(locationstateid.id);
    }

    const [NewLocationCountryId, setNewLocationCountryId] = useState(0);

    const handleSelectContry = (locationcountryid: any) => {
        setNewLocationCountryId(locationcountryid.id);
    }

    const [NewLocationVisitId, setNewLocationVisitId] = useState(0);

    const handleSelectVisitId = (locationvisitid: any) => {
        setNewLocationVisitId(locationvisitid.id);
    }


    const submitAddLocation = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setModalAddLocation(false);
        var data = new FormData(event.currentTarget);
        var newvisitid = 1;
        if (NewLocationVisitId != 0) {
            newvisitid = NewLocationVisitId;
        }
        var newstateid = 1;
        if (NewLocationStateId != 0) {
            newstateid = NewLocationStateId;
        }
        var newcnid = 1;
        if (NewLocationCountryId != 0) {
            newcnid = NewLocationCountryId;
        }
        var formdata = {
            locationname: data.get('locationname'),
            locationaddress: data.get('locationaddress'),
            locationzipcode: data.get('locationzipcode'),
            locationcity: data.get('locationcity'),
            locationstateid: newstateid,
            locationcountryid: newcnid,
            locationpublic: data.get('locationpublic'),
            locationpublicmap: data.get('locationpublicmap'),
            locationgeolat: data.get('locationgeolat'),
            locationgeolong: data.get('locationgeolong'),
            locationdetails: data.get('locationdetails'),
            locationparanormal: data.get('locationparanormal'),
            locationvisitid: newvisitid,
            locationaccesslevelid: data.get('locationaccesslevelid')
        };

        axios.post(APIURL + "/v5/location", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    async function getlocations() {
        var body = {
            locationcountryid: filtercountry,
            locationstateid: filterstate,
            locationname: filterlocationname,
            page: page

        };
        await axios.post(APIURL + "/v3/locations", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setLocations(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const handleSelectFilterCountry = (countryname: any, countryid: any) => {
        if (countryid?.id) {
            setfiltercountry(countryid.id);
        }
        else {
            setfiltercountry(0);
        }
    }

    const handleSelectFilterState = (statename: any, stateid: any) => {
        if (stateid?.id) {
            setfilterstate(stateid.id);
        }
        else {
            setfilterstate(0);
        }
    }

    const handleTypeLocationName = (locationname: any) => {
        setfilterlocationname(locationname);
    }



    async function getcountrys() {
        await axios.get(APIURL + "/v2/optionscountrys", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setCountrys(response.data);
            })
    }
    async function getstates() {
        await axios.get(APIURL + "/v2/optionsstates", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setlocationstates(response.data);
            })
    }

    useEffect(() => {
        document.title = 'Lokasjoner - ParaOffice';
        getlocations();
        getcountrys();
        getstates();
        setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtercountry, filterstate, page]);

    // useEffect(() => {
    //     setisLoading(true);
    //     getlocations();
    // }, [filtercountry, filterstate, page]);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalAddLocation}
                    onClose={handleCloseModalAddLocation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til ny lokasjon
                    </DialogTitle>
                    <Box component="form" onSubmit={submitAddLocation}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="locationname"
                                            label="Navn på lokasjon"
                                            name="locationname"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="locationaddress"
                                            label="Addresse"
                                            name="locationaddress"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationzipcode"
                                            label="Postnummer"
                                            id="locationzipcode"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationcity"
                                            label="Sted"
                                            id="locationcity"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationstateid"
                                            options={locationStates}
                                            onChange={(e, v) => handleSelectState(v)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Fylke"
                                                name="locationstateid"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationcountryid"
                                            options={locationContries}
                                            onChange={(e, v) => handleSelectContry(v)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Land"
                                                name="locationcountryid"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationgeolat"
                                            label="Geokode Latitude"
                                            id="locationgeolat"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationgeolong"
                                            label="Geokode Longitude"
                                            id="locationgeolong"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Tilgangsnivå"
                                            name="locationaccesslevelid"
                                            defaultValue={4}
                                        >
                                            <MenuItem value={1}>Privat</MenuItem>
                                            <MenuItem value={2}>Eget team</MenuItem>
                                            <MenuItem value={3}>NGH Crew</MenuItem>
                                            <MenuItem value={4}>NGH Felles</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Offentlig lokasjon"
                                            name="locationpublic"
                                            defaultValue={0}
                                        >
                                            <MenuItem key={"priv0"} value={0}>Nei</MenuItem>
                                            <MenuItem key={"priv1"} value={1}>Ja</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Publisert på spøkelseskartet"
                                            name="locationpublicmap"
                                            defaultValue={0}
                                        >
                                            <MenuItem key={"priv0"} value={0}>Nei</MenuItem>
                                            <MenuItem key={"priv1"} value={1}>Ja</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationvisitid"
                                            options={locationVisit}
                                            onChange={(e, v) => handleSelectVisitId(v)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Besøkstilgang"
                                                name="locationvisitid"
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationdetails"
                                            label="Detaljer og informasjon"
                                            id="locationdetails"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationparanormal"
                                            label="Paranormal aktivitet"
                                            id="locationparanormal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til event
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalAddLocation}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box m={2}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <EventIcon className='potexticonxl' /> Lokasjoner ({locations[0]?.locationstotal})
                                        </Typography>
                                        <Stack spacing={2}>
                                            <Pagination count={Math.ceil(locations[0]?.locationstotal / 10)} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                                        </Stack>
                                    </Grid>
                                    <p></p>

                                    {!isLoading ?
                                        <div>
                                            {locations[0]?.locations.map(({ locationid, locationhash, locationname, locationcountryname, locationvisitname, locationstatename, locationcity, locationzipcode, locationgeolat, locationgeolong, locationteamname, locationaccesslevelid, locationpublic, locationpublicmap, locationaccessdetails, locationaccessdetailsdate }: any, index: number) => {
                                                return <div className='pcard' key={"location" + locationid}>
                                                    <div className='pcardheader'>
                                                        {locationname}
                                                    </div>
                                                    <div className='pcardbody'>
                                                        <Grid container>
                                                            <Grid item>
                                                                <Box>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ maxHeight: 130 }}
                                                                        image={"https://poimg01.paraoffice.com/location/" + locationhash + ".png?" + Date.now()}
                                                                        alt={locationname}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item ml={2} mr={2} sx={{ flex: 1 }}>

                                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                                                    <Link href={"/location/" + locationhash}>
                                                                        <Typography variant="subtitle1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            {locationname}
                                                                        </Typography>
                                                                    </Link>
                                                                    <span>
                                                                        {(locationgeolat) &&
                                                                            <Link href={"https://www.google.com/maps/@" + locationgeolat + "," + locationgeolong + ",14z"}><MapIcon /></Link>
                                                                        }
                                                                        {(locationpublicmap == "1") ?
                                                                            <PublicIcon sx={{ ml: 1, mr: 1 }} className='pogreen' />
                                                                            :
                                                                            <PublicIcon sx={{ ml: 1, mr: 1 }} className='pored' />
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                                <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <FlagIcon sx={{ mr: 1 }} className='potextico' /> {locationcountryname} &nbsp; <MapIcon sx={{ mr: 1 }} className='potextico' /> {locationstatename} &nbsp; <LocationCityIcon sx={{ mr: 1 }} className='potextico' /> {locationcity} {(locationzipcode) && <span> &nbsp;({locationzipcode})</span>}&nbsp;
                                                                </Typography>
                                                                <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <GroupIcon sx={{ mr: 1 }} className='potextico' /> {locationteamname}
                                                                    {(locationaccesslevelid === 1) &&
                                                                        <span>
                                                                            <LockIcon sx={{ ml: 1 }} className='potextico pored' /> Privat
                                                                        </span>
                                                                    }
                                                                    {(locationaccesslevelid === 2) &&
                                                                        <span>
                                                                            <LockIcon sx={{ ml: 1 }} className='potextico pored' /> Eget team
                                                                        </span>
                                                                    }
                                                                    {(locationaccesslevelid === 3) &&
                                                                        <span>
                                                                            <LockIcon sx={{ ml: 1 }} className='potextico pored' /> NGH Crew
                                                                        </span>
                                                                    }
                                                                    {(locationaccesslevelid === 4) &&
                                                                        <span>
                                                                            <LockIcon sx={{ ml: 1 }} className='potextico pored' /> NGH Felles
                                                                        </span>
                                                                    }
                                                                    {(locationaccesslevelid === 5) &&
                                                                        <span>
                                                                            <LockIcon sx={{ ml: 1 }} className='potextico pogreen' /> Offentlig
                                                                        </span>
                                                                    }
                                                                    <PersonIcon className="potextico" sx={{ ml: 1 }} /> {locations[0]?.locations[index]?.contact?.length}
                                                                    <InventoryIcon className="potextico" sx={{ ml: 1 }} /> {locations[0]?.locations[index]?.arch?.length}
                                                                </Typography>
                                                                <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <TravelExploreIcon sx={{ mr: 1 }} className='potextico' />
                                                                    {(locations[0]?.locations[index]?.visitedteams.length === 0) &&
                                                                        <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <i>Ikke undersøkt av noe team</i>
                                                                        </Typography>
                                                                    }
                                                                    {locations[0]?.locations[index]?.visitedteams?.map(({ teamid, teamname, eventdate }: any, indext: number) => {
                                                                        return <Box key={"team" + indext} sx={{ mr: 1 }}>
                                                                            {teamname} ({eventdate})
                                                                        </Box>
                                                                    })
                                                                    }
                                                                </Typography>
                                                                {(locations[0]?.locations[index]?.locationvisitid === 1) &&
                                                                    <div className="pcard pcard-warning">
                                                                        <div className="pcardheader">
                                                                            {locationvisitname} ({locationaccessdetailsdate})
                                                                        </div>
                                                                        <div className="pcardbody">
                                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                                {locationaccessdetails}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {((locations[0]?.locations[index]?.locationvisitid === 2) || (locations[0]?.locations[index]?.locationvisitid === 3)) &&
                                                                    <div className="pcard pcard-success">
                                                                        <div className="pcardheader">
                                                                            {locationvisitname} ({locationaccessdetailsdate})
                                                                        </div>
                                                                        <div className="pcardbody">
                                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                                {locationaccessdetails}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {((locations[0]?.locations[index]?.locationvisitid === 4) || (locations[0]?.locations[index]?.locationvisitid === 5)) &&
                                                                    <div className="pcard">
                                                                        <div className="pcardheader">
                                                                            {locationvisitname} ({locationaccessdetailsdate})
                                                                        </div>
                                                                        <div className="pcardbody">
                                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                                {locationaccessdetails}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                        :
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={isLoading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box m={2}>
                                    <Button component="label" variant="outlined" startIcon={<AddIcon />} onClick={e => handleOpenModalAddLocation()}>
                                        Opprett ny lokasjon
                                    </Button>
                                    <p></p>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <ContentPasteSearchIcon className='potexticonxl' /> Søk og filtrering
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid item xs={12} p={2}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="locationname"
                                                    label="Navn på lokasjon"
                                                    name="locationname"
                                                    onChange={(newValue) => handleTypeLocationName(newValue.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            getlocations();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationcountryid"
                                                    options={countrys}
                                                    onChange={(e, v) => handleSelectFilterCountry(e, v)}
                                                    defaultValue={filtercountryname}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Land"
                                                        name="locationcountryid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationstateid"
                                                    options={locationstates}
                                                    groupBy={(option) => option.locationgroup}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(e, v) => handleSelectFilterState(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Fylke"
                                                        name="locationstateid"
                                                    />}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </Box >
        </ThemeProvider >
    );
}

export default Locations;