import { useEffect, useState } from 'react';
import { Grid, Badge } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import axios from 'axios';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode'

const APIURL = process.env.REACT_APP_APIURL;

const item = {
    py: '0px',
    px: 2,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        color: '#fff',
        bgcolor: '#262626'
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.2,
    px: 3,
};

const potoken: string | null = localStorage.getItem('potoken');


export default function Navigator(props: DrawerProps) {
    const { ...other } = props;

    const [menuStats, setMenuStats] = useState<any[]>([]);
    const [loginTimeout, setLoginTimeout] = useState(0);



    function getMenuStats() {
        if (potoken) {
            const tokendecoded: { exp: number } = jwtDecode(potoken);
            const currentTimestamp = Math.floor(Date.now() / 1000);
            const timeoutcheck = tokendecoded.exp + 3600;
            const timeout = timeoutcheck - currentTimestamp;
            setLoginTimeout(timeout);
        }
        axios.get(APIURL + "/v3/menustats", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setMenuStats(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    useEffect(() => {
        getMenuStats();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoginTimeout((loginTimeout) => (loginTimeout > 0 ? loginTimeout - 1 : 0));
        }, 1000);
    }, []);

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding className='pomenuleft'>
                <Link to="/dashboard" className='navlink'>
                    <ListItem sx={{ ...item, ...itemCategory, fontSize: 18 }}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText>
                            ParaOffice
                        </ListItemText>
                    </ListItem>
                </Link>
                {/* <ListItem sx={{ py: 2, px: 3 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Oppdrag</ListItemText>
                </ListItem> */}
                <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Profil</ListItemText>
                </ListItem>

                <Link to="/profile" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText>Min profil</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/members" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><PeopleIcon /></ListItemIcon>
                            <ListItemText>Medlemmer</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/teams" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><GroupsIcon /></ListItemIcon>
                            <ListItemText>Teams</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Divider />
                <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Events</ListItemText>
                </ListItem>
                <Link to="/events" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Alle events
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.events}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/eventcases" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Alle oppdrag
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.events_cases}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/eventcases/4" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Uferdige oppdrag
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.events_cases_wip}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/eventcases/8" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Arkiverte oppdrag
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.events_cases_archived}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Divider />
                <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Database</ListItemText>
                </ListItem>
                <Link to="/todo" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><InventoryIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Oppgaver
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.archive_new}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/locations" className='navlink'>
                    <Link to="/archive" className='navlink'>
                        <ListItem disablePadding>
                            <ListItemButton sx={item}>
                                <ListItemIcon><InventoryIcon /></ListItemIcon>
                                <ListItemText>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            Saksarkiv
                                        </div>
                                        <Typography variant="body1" className="menustatnew">
                                            {menuStats[0]?.archive_new}
                                        </Typography>
                                    </Grid>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to="/filelogs" className='navlink'>
                        <ListItem disablePadding>
                            <ListItemButton sx={item}>
                                <ListItemIcon><PlayCircleOutlineIcon /></ListItemIcon>
                                <ListItemText>Logger</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><LocationOnIcon /></ListItemIcon>
                            <ListItemText>
                                Lokasjoner
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/contacts" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText>Kontakter</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                {/* <Link to="/cases" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><InventoryIcon /></ListItemIcon>
                            <ListItemText>Saksarkiv</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link> */}
                <Divider />
                <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Publisering</ListItemText>
                </ListItem>
                <Link to="/www" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><LanguageIcon /></ListItemIcon>
                            <ListItemText>Nettsiden</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/instagram" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><InstagramIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Instagram
                                    </div>
                                    <Typography variant="body1" className="menustatnew">
                                        {menuStats[0]?.instagram_new}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Divider />
                <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText sx={{ color: '#ddd' }}>Annet</ListItemText>
                </ListItem>
                <Link to="/helplogging" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><QuestionAnswerOutlined /></ListItemIcon>
                            <ListItemText>Hjelp Logging</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/tv" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><LiveTvIcon /></ListItemIcon>
                            <ListItemText>TV</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/supraquiz" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><QuizIcon /></ListItemIcon>
                            <ListItemText>SupraQuiz</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
                {/* <Link to="#" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><DnsRoundedIcon /></ListItemIcon>
                            <ListItemText>Formula Drift</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link> */}
                <Divider />
                <Link to="/logout" className='navlink'>
                    <ListItem disablePadding>
                        <ListItemButton sx={item}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Logg ut
                                    </div>
                                    <Typography variant="body1" className="menustattimeout">
                                        {loginTimeout}
                                    </Typography>
                                </Grid>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>
        </Drawer>
    );
}