import { useEffect, useState, useRef } from 'react';
import '../App.css';
import { Alert, Avatar, Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, createTheme, CssBaseline, FormControlLabel, Grid, IconButton, Link, TextField, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import moment from "moment";
import AssignmentIcon from '@mui/icons-material/Assignment';

const APIURL = process.env.REACT_APP_APIURL;
const pouser = localStorage.getItem('pouser');
const poname = localStorage.getItem('poname');
const pouserid = localStorage.getItem('pouserid');
const poteam = localStorage.getItem('poteamname');
const poteamid = localStorage.getItem('poteamid');
const potoken = localStorage.getItem("potoken") || "";

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Agreement() {
    useEffect(() => {
        document.title = 'ParaOffice';
        getAgreement();
        setisLoading(false);
    }, []);

    const signatureRef = useRef<SignatureCanvas | null>(null);
    const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [agreement, setAgreement] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    var today = moment(new Date()).format('DD.MM.YYYY');
    var todaydb = moment(new Date()).format('YYYY-MM-DD');

    useEffect(() => {
        if (agreement.length !== 0) {
            drawText();
        }
    }, [agreement]);

    const clearSignature = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
            setImageDataUrl(null);
            drawText();
        }
    };
    const drawText = () => {
        if (signatureRef.current) {
            const canvas = signatureRef.current.getCanvas();
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.font = '150px Arial';
                ctx.fillStyle = '#f7f5f5';
                ctx.fillText("ParaOffice", 5, 130);
            }
            if (ctx) {
                ctx.font = '50px Arial';
                ctx.fillStyle = '#000';
                ctx.fillText("__________________________", 0, 145);
            }
            if (ctx) {
                ctx.font = '10px Arial';
                ctx.fillStyle = '#555';
                ctx.fillText("Aksept av ParaOffice brukervilkår versjon " + agreement[0]?.agreementversion + " [" + agreement[0]?.agreementdate + "]", 5, 195);
            }
            if (ctx) {
                ctx.font = '16px Arial';
                ctx.fillStyle = '#555';
                ctx.fillText("" + poname + " (" + poteam + ")", 5, 175);
            }
            if (ctx) {
                ctx.font = '16px Arial';
                ctx.fillStyle = '#555';
                ctx.fillText(today, 600, 145);
            }
            if (ctx) {
                ctx.font = '16px Arial';
                ctx.fillStyle = '#555';
                ctx.fillText("Dato", 600, 175);
            }
        }
    }
    const saveSignature = async () => {
        setisLoading(true);
        var dataUrl = '';
        var formdata = {
            archdate: todaydb,
            archdatedue: todaydb,
            archusername: pouser,
            archuserid: pouserid,
            archteamname: poteam,
            archteamid: poteamid,
            archcategoryname: "Kontrakt",
            archcategoryid: 18,
            archtitle: poname + " aksept av ParaOffice brukervilkår " + agreement[0]?.agreementversion,
            archdescription: poname + " aksept av ParaOffice brukervilkår v" + agreement[0]?.agreementversion + " fra dato " + agreement[0]?.agreementdate,
            archpublic: 0,
            archaccessid: 4,
            archaccessname: "NGH Felles",
        };

        axios.post(APIURL + "/v4/arch", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    var textformdata = {
                        archivedocumentdate: todaydb,
                        archivedocumentname: "ParaOffice brukervilkår v" + agreement[0]?.agreementversion,
                        archivedocumenttext: agreement[0]?.agreementtext,
                        archivedocumenttypeid: 23
                    };
                    axios.post(APIURL + "/v3/archivedocumenttxt/" + response.data[0].archhash, textformdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
                        .then((txtres: any) => {
                            if (txtres.status === 200) {
                            }
                        })
                        .catch((e: any) => {
                            console.log(e);
                        });
                    if (signatureRef.current) {
                        const canvas = signatureRef.current.getCanvas();
                        const html2canvasPromise = new Promise<void>((resolve) => {
                            html2canvas(canvas).then((canvas) => {
                                dataUrl = canvas.toDataURL('image/png');
                                setImageDataUrl(dataUrl);
                                resolve();
                            });
                        });
                        const toBlobPromise = new Promise<void>((resolve) => {
                            canvas.toBlob((blob) => {
                                if (blob) {
                                    const file = new File([blob], 'signatur.png', {
                                        type: 'image/png',
                                    });
                                    setImageFile(file);
                                    sendImageToAPI(file, response.data[0].archhash);
                                    resolve();
                                }
                            }, 'image/png');
                        });
                    }
                }
                else {
                    // console.log(response.data);
                }
            })
            .catch((e: any) => {
                console.log(e);
            });



    };

    const sendImageToAPI = (ifile: any, archhash: string) => {
        const apiUrl = "https://poarch01.paraoffice.com/uploadsign.php";
        const formData = new FormData();
        formData.append("token", potoken);
        formData.append("userid", pouserid || "");
        formData.append("teamid", poteamid || "");
        formData.append("username", pouser || "");
        formData.append("teamname", poteam || "");
        formData.append("archhash", archhash);
        formData.append('file', ifile || "");
        console.log(ifile);
        console.log(formData);
        axios.post(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(async (imgres) => {


                var formdata = {
                    archstatusid: 5,
                };

                axios.put(APIURL + "/v4/arch/" + archhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
                    .then((response: any) => {
                        if (response.status === 200) {


                            axios.put(APIURL + "/v4/arch/" + archhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
                                .then((response: any) => {
                                    if (response.status === 200) {


                                        axios.get(APIURL + "/useragreementok", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
                                            .then((response: any) => {
                                                if (response.status === 200) {
                                                    window.location.assign("/dashboard");
                                                }
                                            })
                                            .catch((e: any) => {
                                                console.log(e);
                                            });

                                    }
                                })
                                .catch((e: any) => {
                                    console.log(e);
                                });

                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });


            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };


    function getAgreement() {
        axios.get(APIURL + "/v3/agreement/0", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setAgreement(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
            });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        var formdata = {
            username: data.get('username'),
            password: data.get('password'),
            pincode: data.get('pincode'),
        };
        axios.post(APIURL + "/token", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    let accesstoken = response.data.accesstoken;
                    localStorage.setItem('pouser', String(data.get('username')));
                    localStorage.setItem('poname', response.data.name);
                    localStorage.setItem('potoken', accesstoken);
                    localStorage.setItem('pomod', response.data.usermod);
                    localStorage.setItem('poadmin', response.data.useradmin);
                    localStorage.setItem('pouserid', response.data.userid);
                    localStorage.setItem('poteamid', response.data.teamid);
                    localStorage.setItem('poteamname', response.data.teamname);
                    localStorage.setItem('pouserteamaccess', response.data.teamaccess);
                    window.location.assign("/dashboard");
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1 }}>
                        <AssignmentIcon />
                    </Avatar>
                    <Typography variant="overline" component="h2" pb={2}>
                        ParaOffice brukervilkår versjon {agreement[0]?.agreementversion} [{agreement[0]?.agreementdate}]
                    </Typography>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="caption">
                                {agreement[0]?.agreementtext?.split('\n').map((item: any, key: any) => {
                                    return <span key={key}>{item}<br /></span>
                                })}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Box className="poboxlogin" p={2} mt={2}>
                        <div>
                            <div>
                                <Typography variant="caption" component="h2">
                                    Tegn signatur her
                                </Typography>
                                <SignatureCanvas
                                    ref={signatureRef}
                                    penColor="black"
                                    backgroundColor="white"
                                    canvasProps={{ width: 720, height: 200 }}
                                />
                            </div>
                            {/* {imageDataUrl && (
                                <div>
                                    <h2>Stored Signature:</h2>
                                    <img src={imageDataUrl} alt="Signature" />
                                </div>
                            )} */}
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={clearSignature}
                            >
                                <CloseIcon sx={{ mr: 1 }} /> Fjern signatur
                            </Button>
                        </div>
                        <Grid container spacing={2} pt={3}>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox />} label="Ved å krysse av her og signere bekrefter jeg at jeg vil overholde alle brukervilkår for bruk av ParaOffice systemet, og at eventuelle brudd på disse potensielt vil medføre rettslig straff og konsekvenser for meg." />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    sx={{ mr: 1 }}
                                    onClick={saveSignature}
                                >
                                    <LoginIcon sx={{ mr: 1 }} /> Signer og godkjenn
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Agreement;
