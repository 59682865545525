import React from 'react';
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Autocomplete, Avatar, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, Chip, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, MenuItem, Slide, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TransitionProps } from '@mui/material/transitions';
import { Image } from "react-grid-gallery";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../components/canvaspreview';
import { useDebounceEffect } from '../components/useDebounceEffect';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import DeleteForever from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StorageIcon from '@mui/icons-material/Storage';
import PersonIcon from '@mui/icons-material/Person';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { BusinessCenter } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';



const potoken = localStorage.getItem('potoken');


function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export interface CustomImage extends Image {
    original: string;
}


const APIURL = process.env.REACT_APP_APIURL;
const APIFSURL = process.env.REACT_APP_APIFSURL;


let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Location() {
    const { locationhash } = useParams();
    const [imageModal, setImageModal] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale] = useState(1);
    const [rotate] = useState(0);
    const [aspect] = useState<number | undefined>(16 / 9);
    const [isLoading, setisLoading] = useState(true);
    const [TabValue, setTabValue] = useState(0);
    const [location, setLocation] = useState<any[]>([]);
    const [locationStates, setLocationStates] = useState<any[]>([]);
    const [locationContries, setLocationContries] = useState<any[]>([]);
    const [locationVisit, setLocationVisit] = useState<any[]>([]);

    const [logomodal, setLogoModal] = useState(false);
    const [modalWipeContact, setmodalWipeContact] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(0);
    const [selectedContactHash, setSelectedContactHash] = useState("");
    const [editLocationModal, seteditLocationModal] = useState(false);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickImageModalOpen = () => {
        setImageModal(true);
    };

    const handleClickImageModalClose = () => {
        setImageModal(false);
    };


    const handleOpenCase = (archivehash: any) => {
        localStorage.setItem('archivecase', archivehash);
        window.location.href = '/archive'
    }


    const handleOpenEditLocationModal = () => {
        axios.get(APIURL + "/v2/optionsstates", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationStates(response.data);
            })
        axios.get(APIURL + "/v2/optionscountrys", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationContries(response.data);
            })
        axios.get(APIURL + "/v3/optionslocationvisit", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocationVisit(response.data);
            })

        seteditLocationModal(true);
    };

    const handleCloseEditLocationModal = () => {
        seteditLocationModal(false);
    };

    const [modalFileAddArea, setmodalFileAddArea] = useState(false);


    const handleOpenAddContactLocationModal = async () => {
        setAddContactLocationModal(true);
        getcontacts();
    };

    const [contacts, setContacts] = useState<any[]>([]);

    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setContacts(response.data);
            })
    }

    const handleCloseAddContactLocationModal = () => {
        setAddContactLocationModal(false);
    };

    const handleOpenmodalFileAddArea = () => {
        // getlocationareas();
        getlocation();
        setmodalFileAddArea(true);
    };
    const handleClosemodalFileAddArea = () => {
        setmodalFileAddArea(false);
    };


    const handleClosemodalWipeContact = () => {
        setmodalWipeContact(false);
    };
    const handleOpenmodalWipeContact = (contacthash: any) => {
        setSelectedContactHash(contacthash);
        setmodalWipeContact(true);
    };





    const submitLocationWipeArea = (laid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/locationarea/" + locationhash + "/" + laid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getlocation();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const submitWipeContact = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        axios.delete(APIURL + "/v3/contactlocation/" + selectedContactHash + "/" + locationhash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getlocation();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        handleClosemodalWipeContact();
        setisLoading(false);
    }


    const submitFileAddArea = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddArea(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            laname: data.get('laname'),
        };
        axios.post(APIURL + "/v3/locationarea/" + locationhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getlocation();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }




    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        setisLoading(true);
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const formData = new FormData();
            formData.append("token", potoken || "");
            formData.append("locationhash", locationhash || "");
            formData.append("file[]", blob);
            await axios.post("https://poimg01.paraoffice.com/locationimageupload.php", formData, { headers: { 'Content-Type': blob.type } })
                .then((response: any) => {
                    if (response.status === 200) {
                        getlocation();
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setisLoading(false);
                });
        })
        setImageModal(false);
    }


    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 1) {
        }
        if (newValue === 2) {
        }
        if (newValue === 3) {
        }
    };

    const handleSubmitLocationLogo = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setLogoModal(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            token: localStorage.getItem("potoken"),
            hash: locationhash,
            type: data.get('type'),
            file: data.get('file'),
        };
        axios.post(APIFSURL + "/upload.php", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getlocation();
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    const [NewLocationStateId, setNewLocationStateId] = useState(0);

    const handleSelectState = (locationstateid: any) => {
        setNewLocationStateId(locationstateid.id);
    }

    const [NewLocationCountryId, setNewLocationCountryId] = useState(0);

    const handleSelectContry = (locationcountryid: any) => {
        setNewLocationCountryId(locationcountryid.id);
    }

    const [NewLocationVisitId, setNewLocationVisitId] = useState(0);

    const handleSelectVisitId = (locationvisitid: any) => {
        setNewLocationVisitId(locationvisitid.id);
    }

    const handleAddContactSelectLocation = (event: any, values: any) => {
        setSelectedContactId(values['id']);
    }


    const [AddContactLocationModal, setAddContactLocationModal] = useState(false);

    const handleSubmitContactNewLocation = () => {
        setisLoading(true);
        setAddContactLocationModal(false);
        var formdata = {
            locationid: location[0]?.locationid,
            contactid: selectedContactId
        };
        axios.post(APIURL + "/v4/locationcontact/", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    getlocation();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    const handleSubmitEditLocation = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        seteditLocationModal(false);
        setisLoading(true);
        var newvisitid = location[0].locationvisitid;
        if (NewLocationVisitId != 0) {
            newvisitid = NewLocationVisitId;
        }
        var newstateid = location[0].locationstateid;
        if (NewLocationStateId != 0) {
            newstateid = NewLocationStateId;
        }
        var newcnid = location[0].locationcountryid;
        if (NewLocationCountryId != 0) {
            newcnid = NewLocationCountryId;
        }
        var data = new FormData(e.currentTarget);
        var formdata = {
            locationname: data.get('locationname'),
            locationaddress: data.get('locationaddress'),
            locationzipcode: data.get('locationzipcode'),
            locationcity: data.get('locationcity'),
            locationstateid: newstateid,
            locationcountryid: newcnid,
            locationpublic: data.get('locationpublic'),
            locationpublicmap: data.get('locationpublicmap'),
            locationgeolat: data.get('locationgeolat'),
            locationgeolong: data.get('locationgeolong'),
            locationdetails: data.get('locationdetails'),
            locationparanormal: data.get('locationparanormal'),
            locationvisitid: newvisitid,
            locationaccesslevelid: data.get('locationaccesslevelid'),
            locationaccessdetailsdate: data.get('locationaccessdetailsdate'),
            locationaccessdetails: data.get('locationaccessdetails')
        };
        axios.put(APIURL + "/v3/location/" + locationhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getlocation();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }






    function DashFooter() {
        return (
            <Typography variant="body2" color="text.secondary" align="center">
                <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        href="/dashboard"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Forsiden
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        href="/locations"
                    >
                        <ScreenShareIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Lokasjoner
                    </Link>
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                    >
                        {location[0]?.locationname}
                    </Typography>
                </Breadcrumbs>
            </Typography>
        );
    }

    function getlocation() {
        axios.get(APIURL + "/v3/location/" + locationhash, { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setLocation(response.data);
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }
    const handleClickLogoModalOpen = () => {
        setLogoModal(true);
    };
    const handleClickLogoModalClose = () => {
        setLogoModal(false);
    };


    useEffect(() => {
        document.title = 'Lokasjon - ParaOffice';
        getlocation();
        // eslint-disable-next-line
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeContact}
                    onClose={handleClosemodalWipeContact}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Fjern kontaktperson
                    </DialogTitle>
                    <Box component="form" onSubmit={submitWipeContact}>
                        <DialogContent>
                            Er du sikker på at du vil fjerne kontaktpersonen fra denne lokasjonen?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Bekreft
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalWipeContact}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={AddContactLocationModal}
                    onClose={handleCloseAddContactLocationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til kontaktperson på lokasjonen
                    </DialogTitle>
                    <Box component="form" onSubmit={handleSubmitContactNewLocation}>
                        <DialogContent style={{ height: '250px' }}>
                            <Autocomplete
                                size="small"
                                ListboxProps={{ style: { maxHeight: 200 } }}
                                disablePortal
                                id="contactid"
                                options={contacts}
                                onChange={(e, v) => handleAddContactSelectLocation(e, v)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Kontaktperson"
                                    name="contactid"
                                />}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseAddContactLocationModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddArea}
                    onClose={handleClosemodalFileAddArea}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til område på lokasjon
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileAddArea}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="laname"
                                    label="Navn på område"
                                    name="laname"
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileAddArea}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={editLocationModal}
                    onClose={handleCloseEditLocationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Endre lokasjon
                    </DialogTitle>
                    <Box component="form" onSubmit={handleSubmitEditLocation}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="locationname"
                                            label="Navn på lokasjon"
                                            name="locationname"
                                            defaultValue={location[0]?.locationname}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="locationaddress"
                                            label="Addresse"
                                            name="locationaddress"
                                            defaultValue={location[0]?.locationaddress}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationzipcode"
                                            label="Postnummer"
                                            id="locationzipcode"
                                            defaultValue={location[0]?.locationzipcode}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationcity"
                                            label="Sted"
                                            id="locationcity"
                                            defaultValue={location[0]?.locationcity}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationstateid"
                                            options={locationStates}
                                            onChange={(e, v) => handleSelectState(v)}
                                            defaultValue={location[0]?.locationstatename}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Fylke"
                                                name="locationstateid"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationcountryid"
                                            options={locationContries}
                                            onChange={(e, v) => handleSelectContry(v)}
                                            defaultValue={location[0]?.locationcountryname}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Land"
                                                name="locationcountryid"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationgeolat"
                                            label="Geokode Latitude"
                                            id="locationgeolat"
                                            defaultValue={location[0]?.locationgeolat}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationgeolong"
                                            label="Geokode Longitude"
                                            id="locationgeolong"
                                            defaultValue={location[0]?.locationgeolong}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Tilgangsnivå"
                                            name="locationaccesslevelid"
                                            defaultValue={location[0]?.locationaccesslevelid}
                                        >
                                            <MenuItem value={1}>Privat</MenuItem>
                                            <MenuItem value={2}>Eget team</MenuItem>
                                            <MenuItem value={3}>NGH Crew</MenuItem>
                                            <MenuItem value={4}>NGH Felles</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Offentlig lokasjon"
                                            name="locationpublic"
                                            defaultValue={location[0]?.locationpublic}
                                        >
                                            <MenuItem key={"priv0"} value={0}>Nei</MenuItem>
                                            <MenuItem key={"priv1"} value={1}>Ja</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            select
                                            fullWidth
                                            label="Publisert på spøkelseskartet"
                                            name="locationpublicmap"
                                            defaultValue={location[0]?.locationpublicmap}
                                        >
                                            <MenuItem key={"priv0"} value={0}>Nei</MenuItem>
                                            <MenuItem key={"priv1"} value={1}>Ja</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            sx={{ pt: 2 }}
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            disablePortal
                                            id="locationvisitid"
                                            options={locationVisit}
                                            onChange={(e, v) => handleSelectVisitId(v)}
                                            defaultValue={location[0]?.locationvisitdescription}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Besøkstilgang"
                                                name="locationvisitid"
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationaccessdetails"
                                            label="Tilgangsinformasjon"
                                            id="locationaccessdetails"
                                            defaultValue={location[0]?.locationaccessdetails}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="date"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationaccessdetailsdate"
                                            label="Dato for tilgangsinformasjon"
                                            id="locationaccessdetailsdate"
                                            defaultValue={location[0]?.locationaccessdetailsdate}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationdetails"
                                            label="Detaljer og informasjon"
                                            id="locationdetails"
                                            defaultValue={location[0]?.locationdetails}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            name="locationparanormal"
                                            label="Paranormal aktivitet"
                                            id="locationparanormal"
                                            defaultValue={location[0]?.locationparanormal}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseEditLocationModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    open={imageModal}
                    onClose={handleClickImageModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box>
                        <DialogTitle id="alert-dialog-title">
                            Last opp nytt bilde
                        </DialogTitle>
                        <DialogContent>


                            <input type="file" accept="image/*" onChange={onSelectFile} />
                            {!!imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                            {!!completedCrop && (
                                <>
                                    <div>
                                        <Typography>
                                            Forhåndsvisning:
                                        </Typography>
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                                border: '1px solid black',
                                                objectFit: 'contain',
                                                width: 350,
                                                height: 350,
                                            }}
                                        />
                                    </div>
                                </>
                            )}


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onDownloadCropClick}
                                size="small"
                                variant="outlined"
                                color="success"
                            >Last opp</Button>
                            <Button onClick={handleClickImageModalClose}
                                size="small"
                                variant="outlined"
                                color="error"
                            >Avbryt</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>


                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }} p={2}>
                            <Tabs value={TabValue} onChange={handleChangeTab} aria-label="tabs" variant="fullWidth">
                                <Tab label="Informasjon" {...a11yProps(0)} />
                                {/* <Tab label={"Bilder (0)"} {...a11yProps(3)} />
                                <Tab label={"Funn (0)"} {...a11yProps(4)} />
                                <Tab label={"Spøkelseskartet"} {...a11yProps(5)} /> */}
                        {/* </Tabs>
                        </Box> */}

                        {/* <TabPanel value={TabValue} index={0}> */}
                        <Grid container spacing={1}>
                            <Grid item xs={8} p={2}>
                                <Grid item xs={12}>
                                    <div className="pcard">
                                        <div className="pcardheader">
                                            Detaljer og informasjon
                                        </div>
                                        <div className="pcardbody">
                                            {(location[0]?.locationdetails) ?
                                                <Typography variant="body2" color="text.secondary">
                                                    {location[0]?.locationdetails.split('\n').map((item: any, key: any) => {
                                                        return <span key={key}>{item}<br /></span>
                                                    })}
                                                </Typography>
                                                :
                                                <span className='potextmissing'>(mangler)</span>
                                            }
                                        </div>
                                    </div>
                                </Grid>

                                <div className="pcard">
                                    <div className="pcardheader">
                                        Paranormale historier
                                    </div>
                                    <div className="pcardbody">
                                        {(location[0]?.locationparanormal) ?
                                            <Typography variant="body2" color="text.secondary">
                                                {location[0]?.locationparanormal.split('\n').map((item: any, key: any) => {
                                                    return <span key={key}>{item}<br /></span>
                                                })}
                                            </Typography>
                                            :
                                            <span className='potextmissing'>(mangler)</span>
                                        }
                                    </div>
                                </div>

                                <div className="pcard">
                                    <div className="pcardheader">
                                        Tilgangsinformasjon (Siste info: {location[0]?.locationaccessdetailsdatedisp})
                                    </div>
                                    <div className="pcardbody">
                                        <Typography color="text.primary" className='potypeicon' gutterBottom>
                                            {(location[0]?.locationaccessdetails) ?
                                                <Typography variant="body2" color="text.secondary">
                                                    {location[0]?.locationaccessdetails.split('\n').map((item: any, key: any) => {
                                                        return <span key={key}>{item}<br /></span>
                                                    })}
                                                </Typography>
                                                :
                                                <span className='potextmissing'>(mangler)</span>
                                            }
                                        </Typography>
                                    </div>
                                </div>

                                <div className="pcard">
                                    <div className="pcardheader">
                                        Saksarkiv ({location[0]?.arch?.length})
                                    </div>
                                    <div className="pcardbody">
                                        <Typography color="text.primary" className='potypeicon' gutterBottom>
                                            {(location[0]?.arch) ?
                                                <Typography variant="body2" color="text.secondary">
                                                    {location[0]?.arch?.map(({ archid, archhash, archref, archdate, archdatedisp, archuserid, archteamid, teamname, archlocationid, archlocationname, archlocationhash, archeventid, archeventname, archeventhash, archcontactid, archcontactname, archcategoryid, archcategoryname, archtitle, archdescription, archpublic, archaccessid, archstatusid, archstatusname, archcruserid, archcruseridmod, archcrts, archcrtsmod, archwiped, archseen }: any, index: number) => {
                                                        return <span key={archid}>[{archdatedisp}] - <Link href={"/archive/" + archhash}>{archtitle}</Link> ({archcategoryname}) - [{archcontactname}]<br /></span>
                                                    })}
                                                </Typography>
                                                :
                                                <span className='potextmissing'>(ingen saker)</span>
                                            }
                                        </Typography>
                                    </div>
                                </div>

                                <div className="pcard">
                                    <div className="pcardheader">
                                        Undersøkelser ({location[0]?.investigations?.length})
                                    </div>
                                    <div className="pcardbody">

                                        <Grid container spacing={1}>
                                            <Grid container spacing={2} p={2}>
                                                <Grid item xs={12}>
                                                    {location[0]?.investigations.map(({ eventteams, eventownerteamid, eventtypename, eventownerusername, eventaccesslevelname, eventstoragename, eventstorageidb1disk, eventstorageidb1name, eventstorageidb1ts, eventhash, eventname, eventdatedisp, teamname, eventstatusname, eventclassname, eventpublic }: any, index: number) => {
                                                        var eventpub = '';
                                                        if (eventpublic === 1) {
                                                            eventpub = "CardPublic";
                                                        }
                                                        else {
                                                            eventpub = '';
                                                        }
                                                        return <Card variant="outlined" key={"eventhash" + eventhash} sx={{ mb: 1 }} className={eventpub}>
                                                            <CardContent>
                                                                <Grid container width='100%'>
                                                                    <Grid item>
                                                                        <Box sx={{ height: 150, width: 250 }}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={"https://poimg01.paraoffice.com/event/" + eventhash + "_t.png?" + Date.now()}
                                                                                alt="random"
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                                            <Link href={"/event/" + eventhash}>{eventdatedisp} - {eventname}</Link>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            <span className='poteambox poteamboxowner'>{teamname}</span>
                                                                            {
                                                                                eventteams && eventteams.map(({ teamid, teamname }: any) => {
                                                                                    return (
                                                                                        <span key={"eventteams" + eventhash + "t" + teamid}>
                                                                                            {
                                                                                                (teamid !== eventownerteamid) &&
                                                                                                <span className='poteambox'>{teamname}</span>
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Typography>
                                                                        <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <LockPersonIcon sx={{ mr: 1 }} className='potextico' /> {eventaccesslevelname} &nbsp; <PersonIcon sx={{ mr: 1 }} className='potextico' /> {eventownerusername}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            Type: <span className='infobox'>{eventtypename}</span> <span className='infobox'>{eventclassname}</span> Status: <span className='infobox'>{eventstatusname}</span> Offentlig publisert: {(eventpublic === 1) ? <span className='infobox'>Ja</span> : <span className='infobox'>Nei</span>}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    })
                                                    }







                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} p={2}>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12}>
                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="h5" color="text.secondary" className='potypeicon' gutterBottom>
                                                {location[0]?.locationname}
                                            </Typography>
                                            <Typography variant="h5" color="text.secondary" className='potypeicon' gutterBottom>
                                                <Button variant="outlined" size="small" color="warning" onClick={handleOpenEditLocationModal}><EditIcon className='pobtnicon' /> Endre informasjon</Button>
                                            </Typography>
                                        </Grid>
                                        <CardMedia
                                            component="img"
                                            sx={{ maxWidth: 350, display: { xs: 'block' }, paddingBottom: 2 }}
                                            image={"https://poimg01.paraoffice.com/location/" + locationhash + ".png?" + Date.now()}
                                            alt={location[0]?.locationname}
                                        />
                                        <Button variant="outlined" size="small" color="warning" onClick={handleClickImageModalOpen}><EditIcon className='pobtnicon' /> Endre bilde</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Adresse
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationaddress) ?
                                                        <span>{location[0]?.locationaddress}</span>
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Postnummer
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationzipcode) ?
                                                        <span>{location[0]?.locationzipcode}</span>
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Sted
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationcity) ?
                                                        <span>{location[0]?.locationcity}</span>
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Fylke
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationstatename) ?
                                                        location[0]?.locationstatename
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Land
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationcountryname) ?
                                                        location[0]?.locationcountryname
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    GeoKoder
                                                </Typography>
                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                    {(location[0]?.locationgeolat) ?
                                                        <span>{location[0]?.locationgeolat} / {location[0]?.locationgeolong}</span>
                                                        :
                                                        <span className='potextmissing'>(mangler)</span>
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Team
                                                </Typography>
                                                {(location[0]?.locationteamname) ?
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        {location[0]?.locationteamname}
                                                    </Typography>
                                                    :
                                                    <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                        [ingen]
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Besøkstilgang
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                    <AccessibilityIcon sx={{ mr: 1 }} className='potextico' /> {location[0]?.locationvisitdescription}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>

                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Tilgangsnivå
                                                </Typography>
                                                {(location[0]?.locationaccesslevelid === 1) &&
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        Privat
                                                    </Typography>
                                                }
                                                {(location[0]?.locationaccesslevelid === 2) &&
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        Eget team
                                                    </Typography>
                                                }
                                                {(location[0]?.locationaccesslevelid === 3) &&
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        NGH Crew
                                                    </Typography>
                                                }
                                                {(location[0]?.locationaccesslevelid === 4) &&
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        NGH Felles
                                                    </Typography>
                                                }
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Offentlig
                                                </Typography>
                                                {(location[0]?.locationpublic == "1") ?
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        Ja
                                                    </Typography>
                                                    :
                                                    <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                        Nei
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                    Spøkelseskartet
                                                </Typography>
                                                {(location[0]?.locationpublicmap == "1") ?
                                                    <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                        Ja
                                                    </Typography>
                                                    :
                                                    <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                        Nei
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={12} pt={1}>
                                                <div className="pcard">
                                                    <div className="pcardheader">
                                                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            Områder ({location[0]?.areas.length})
                                                            <AddCircleIcon color="primary" className='pcardheaderoption' onClick={handleOpenmodalFileAddArea} sx={{ mr: 1 }} />
                                                        </Grid>
                                                    </div>
                                                    <div className="pcardbody">
                                                        <Grid item xs={12}>
                                                            {location[0]?.areas.map(({ laid, laname, lausednum }: any, index: number) => {
                                                                return <span>
                                                                    {(lausednum === 0) ?
                                                                        <Chip label={laname} variant="outlined" onDelete={e => submitLocationWipeArea(laid)} />
                                                                        :
                                                                        <Chip variant="outlined" label={laname} sx={{ mr: 1, mt: 1 }} />
                                                                    }
                                                                </span>
                                                            })
                                                            }
                                                        </Grid>
                                                    </div>
                                                </div>


                                                <div className="pcard">
                                                    <div className="pcardheader">
                                                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            Kontaktpersoner ({location[0]?.contacts.length})
                                                            <AddCircleIcon color="primary" className='pcardheaderoption' onClick={handleOpenAddContactLocationModal} sx={{ mr: 1 }} />
                                                        </Grid>
                                                    </div>
                                                    <div className="pcardbody">
                                                        <Grid item xs={12}>
                                                            {location[0]?.contacts.map(({ contacthash, contactname, contactemail, contacttitle, contactphone }: any, index: number) => {
                                                                return <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span>
                                                                        <Grid container>
                                                                            <Grid item pr={2}><Avatar src={"https://poimg01.paraoffice.com/contact/" + contacthash + ".png?" + Date.now()} /></Grid><Grid item> <Link href={"/contact/" + contacthash}>{contactname}</Link><br />{contacttitle}</Grid>
                                                                        </Grid>
                                                                    </span>
                                                                    <span>
                                                                        <DeleteForever color="error" onClick={() => handleOpenmodalWipeContact(contacthash)} sx={{ mr: 1 }} />
                                                                    </span>
                                                                </Grid>
                                                            })
                                                            }
                                                        </Grid>
                                                    </div>
                                                </div>

                                            </Grid>













                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* </TabPanel >

                        <TabPanel value={TabValue} index={1}>


                            <Grid container spacing={1}>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12}>




                                    </Grid>
                                </Grid>
                            </Grid>



                        </TabPanel> */}

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Box>
                    <Box component="footer" sx={{ p: 0, bgcolor: 'rgba(0, 0, 0, 0.3)' }}>
                        <DashFooter />
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default Location;