import { useEffect, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Button, CardContent, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import QuizIcon from '@mui/icons-material/Quiz';
import { useParams } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function SupraQuizDetails() {
    const { quizhash } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [myQuiz, setMyQuiz] = useState<any[]>([]);
    const [addNewQuizQuestionModal, setAddNewQuizQuestionModal] = useState(false);
    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    async function getquiz() {
        await axios.get(APIURL + "/v3/supraquiz/" + quizhash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setMyQuiz(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const handleOpenAddNewQuizQuestionModal = () => {
        setAddNewQuizQuestionModal(true);
    };

    const handleCloseAddNewQuizQuestionModal = () => {
        setAddNewQuizQuestionModal(false);
    };


    const handleSubmitNewQuizQuestion = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAddNewQuizQuestionModal(false);
        setisLoading(true);
        var data = new FormData(e.currentTarget);
        var formdata = {
            supraquizquestion: data.get('supraquizquestion'),
            supraquizalt1: data.get('supraquizalt1'),
            supraquizalt2: data.get('supraquizalt2'),
            supraquizalt3: data.get('supraquizalt3'),
            supraquizalt4: data.get('supraquizalt4'),
        };
        axios.post(APIURL + "/v3/supraquiz/question/" + quizhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getquiz();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }




    const submitDeleteQuestion = (quizqid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/supraquiz/question/" + quizqid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getquiz();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);

    }
    useEffect(() => {
        document.title = 'SupraQuiz';
        getquiz();
        setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={addNewQuizQuestionModal}
                    onClose={handleCloseAddNewQuizQuestionModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Nytt spørsmål på quiz: {myQuiz[0]?.quizname}
                    </DialogTitle>
                    <Box component="form" onSubmit={handleSubmitNewQuizQuestion}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizquestion"
                                            label="Spørsmål"
                                            name="supraquizquestion"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizalt1"
                                            label="Riktig svar"
                                            name="supraquizalt1"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizalt2"
                                            label="Alternativ #2"
                                            name="supraquizalt2"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizalt3"
                                            label="Alternativ #3"
                                            name="supraquizalt3"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="supraquizalt4"
                                            label="Alternativ #4"
                                            name="supraquizalt4"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseAddNewQuizQuestionModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <QuizIcon className='potexticonxl' /> SupraQuiz - {myQuiz[0]?.quizname}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} ml={1}>
                                <Button size="small" variant="outlined" color="primary" onClick={handleOpenAddNewQuizQuestionModal} sx={{ mb: 1 }}><AddCircleIcon className='btniconsmall' /> Legg til nytt spørsmål</Button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} p={2}>
                                {!isLoading ?
                                    <div>
                                        {myQuiz[0]?.questions.map(({ quizqid, quizqquestion, quizqalt1, quizqalt2, quizqalt3, quizqalt4 }: any, index: number) => {
                                            return <Box className='pobox' key={"quiz" + quizqid} sx={{ mb: 1 }}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                #{index + 1} - {quizqquestion}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <KeyboardDoubleArrowRightIcon className="pogreen" /> {quizqalt1}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <KeyboardDoubleArrowRightIcon className="pored" /> {quizqalt2}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <KeyboardDoubleArrowRightIcon className="pored" /> {quizqalt3}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <KeyboardDoubleArrowRightIcon className="pored" /> {quizqalt4}
                                                            </Typography>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="primary"
                                                                href={"/supraquiz/" + quizhash}
                                                                sx={{ mr: 2 }}
                                                                disabled
                                                            >
                                                                <AddCircleIcon className='pobtnicon' />
                                                                Endre
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                color="error"
                                                                sx={{ mr: 2 }}
                                                                onClick={e => submitDeleteQuestion(quizqid)}
                                                            >
                                                                <AddCircleIcon className='pobtnicon' />
                                                                Slett
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Box>
                                        })
                                        }
                                    </div>
                                    :
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default SupraQuizDetails;