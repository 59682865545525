import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'ParaOffice';
        localStorage.clear();
        window.location.assign("/");
        // navigate('/', { replace: true });
    });
    return (
        <div></div>
    )
}

export default Logout;