import { useEffect, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import EventIcon from '@mui/icons-material/Event';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';


const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

function Teams() {
    const [isLoading, setisLoading] = useState(true);
    const [teams, setTeams] = useState<any[]>([]);


    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    useEffect(() => {
        document.title = 'ParaOffice';
        axios.get(APIURL + "/teams", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setisLoading(false);
                    setTeams(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                {!isLoading ?
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Header onDrawerToggle={handleDrawerToggle} />
                        <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Box m={2}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <EventIcon className='potexticonxl' /> ParaOffice teams
                                        </Typography>
                                        {teams.map(({ teamid, teamhash, teamname, teampo }: any, index: number) => {
                                            return <Card variant="outlined" key={teamid} sx={{ mb: 1 }}>
                                                <CardContent>
                                                    <Grid container width='100%'>
                                                        <Grid item>
                                                            <Box sx={{ height: 150, width: 250 }}>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={"https://poimg01.paraoffice.com/teams/" + teamhash + ".jpg?" + Date.now()}
                                                                    alt="random"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                            <Link href={"/team/" + teamhash}>{teamname}</Link>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                ParaOffice team:&nbsp;
                                                                {
                                                                    (teampo === 0) ?
                                                                        "Nei"
                                                                        :
                                                                        "Ja"
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        })
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </Box>
        </ThemeProvider>
    );
}

export default Teams;