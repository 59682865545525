import { useEffect, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import GroupsIcon from '@mui/icons-material/Groups';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import LanguageIcon from '@mui/icons-material/Language';
const APIURL = process.env.REACT_APP_APIURL;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function WWW() {
    const [wwwModal, setwwwModal] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // const handleClickwwwModalOpen = () => {
    //     setwwwModal(true);
    // };

    const handleClickwwwModalClose = () => {
        setwwwModal(false);
    };

    const [isLoading, setisLoading] = useState(true);
    const [www, setwww] = useState<any[]>([]);

    function getwww() {
        axios.get(APIURL + "/v3/www", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setwww(response.data);
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        getwww();
    }, []);


    const handleSubmitWWW = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickwwwModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            userfullname: data.get('userfullname'),
            useraddress: data.get('useraddress'),
            userbirth: data.get('userbirth'),
            useremail: data.get('useremail'),
            userphone: data.get('userphone'),
            userzipcode: data.get('userzipcode'),
            usercity: data.get('usercity'),
            userdiscordid: data.get('userdiscordid'),
            usersnapchat: data.get('usersnapchat'),
            userfacebook: data.get('userfacebook'),
            userinstagram: data.get('userinstagram'),
            usergeolat: data.get('usergeolat'),
            usergeolong: data.get('usergeolong'),
            userpubsnapchat: data.get('userpubsnapchat'),
            userpubinstagram: data.get('userpubinstagram'),
            userpubfacebook: data.get('userpubfacebook'),
            userpubphone: data.get('userpubphone'),
            userpubemail: data.get('userpubemail'),
        };
        axios.put(APIURL + "/profile", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getwww();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        {!isLoading ?
                            <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    aria-label="tabs"
                                >
                                    <Tab icon={<LanguageIcon />} iconPosition="start" label="Forsiden" {...a11yProps(0)} />
                                    <Tab icon={<GroupsIcon />} iconPosition="start" label="Ofte stilte spørsmål" {...a11yProps(1)} />
                                </Tabs>
                                <Dialog
                                    open={wwwModal}
                                    onClose={handleClickwwwModalClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        Endre profil
                                    </DialogTitle>
                                    <Box component="form" onSubmit={handleSubmitWWW}>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="wwwtitle"
                                                            label="Tittel"
                                                            name="wwwtitle"
                                                            defaultValue={www[0]?.wwwtitle}
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            rows="10"
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            name="wwwtext"
                                                            label="Introtekst"
                                                            id="wwwtext"
                                                            defaultValue={www[0]?.wwwtext}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <SaveIcon className='pobtnicon' />
                                                Lagre
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={handleClickwwwModalClose}
                                            >
                                                <CloseIcon className='pobtnicon' />
                                                Avbryt
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>


                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12} md={12}>
                                        <TabPanel value={tabValue} index={0}>
                                            <Grid container spacing={2} p={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" color="text.secondary" className='potypeicon' gutterBottom>
                                                        Tittel
                                                    </Typography>
                                                    <Box className="pobox" p={2}>
                                                        {www[0]?.wwwtitle}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" color="text.secondary" className='potypeicon' gutterBottom>
                                                        Introtekst
                                                    </Typography>
                                                    <Box className="pobox" p={2}>
                                                        {www[0]?.wwwtext.split('\n').map((item: any, key: any) => {
                                                            return <span key={key}>{item}<br /></span>
                                                        })}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={1}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2} p={2}>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </> : <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default WWW;