import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, MenuItem, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CakeIcon from '@mui/icons-material/Cake';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useParams } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PlaceIcon from '@mui/icons-material/Place';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import BadgeIcon from '@mui/icons-material/Badge';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteForever from '@mui/icons-material/DeleteForever';
import AddCircle from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';

const APIURL = process.env.REACT_APP_APIURL;

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Team() {
    const { teamhash } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [addequipmentmodal, setaddequipmentmodal] = useState(false);
    const [isTeamAdmin, setisTeamAdmin] = useState(false);
    const [teams, setTeams] = useState<any[]>([]);
    const [teamequipment, setteamequipment] = useState<any[]>([]);
    const [tabValue, setTabValue] = useState(0);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 2) {
            getequipment();
        }
        setTabValue(newValue);
    };


    const handleOpenAddEquipmentModal = () => {
        setaddequipmentmodal(true);
    };
    const handleCloseAddEquipmentModal = () => {
        setaddequipmentmodal(false);
    };






    const submitWipeEqiuipment = (trid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v2/teamequipment/" + trid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getequipment();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }




    const submitNewEquipment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        var formdata = {
            trname: data.get('trname'),
            trtypeid: data.get('trtypeid'),
        };
        await axios.post(APIURL + "/v2/teamequipment/" + teams[0]?.teamid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getequipment();
                }
            })
            .catch((e: Error) => {
            });
        setaddequipmentmodal(false);
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        axios.get(APIURL + "/teamdetails/" + teamhash, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    console.log(response.data);
                    if ((response.data[0].teammemberaccessid === 1) || (response.data[0].teammemberaccessid === 2) || (response.data[0].teammemberaccessid === 3)) {
                        setisTeamAdmin(true);
                    }
                    setisLoading(false);
                    setTeams(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        // eslint-disable-next-line
    }, []);


    function getequipment() {
        axios.get(APIURL + "/v2/teamequipment/" + teams[0]?.teamid, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    console.log(response.data);
                    setteamequipment(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={addequipmentmodal}
                    onClose={handleCloseAddEquipmentModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til utstyr
                    </DialogTitle>
                    <Box component="form" onSubmit={submitNewEquipment} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Navn på utstyret"
                                    name="trname"
                                >
                                </TextField>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <TextField
                                        size="small"
                                        select
                                        fullWidth
                                        label="Kategori"
                                        name="trtypeid"
                                    >
                                        <MenuItem value={1}>Videokamera</MenuItem>
                                        <MenuItem value={2}>Lydopptaker</MenuItem>
                                        <MenuItem value={3}>CCTV Kamera</MenuItem>
                                        <MenuItem value={4}>Mobil</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <AddCircle className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseAddEquipmentModal}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                {!isLoading ?
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Header onDrawerToggle={handleDrawerToggle} />
                        <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>

                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                aria-label="tabs"
                            >
                                <Tab icon={<GroupsIcon />} iconPosition="start" label="Informasjon" {...a11yProps(0)} />
                                <Tab icon={<AccountBoxIcon />} iconPosition="start" label="Medlemmer" {...a11yProps(1)} />
                                <Tab icon={<ConstructionIcon />} iconPosition="start" label="Utstyr" {...a11yProps(2)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box m={2}>
                                            {teams.map(({ teamid, teamhash, teamname, teampo }: any, index: number) => {
                                                return <Card variant="outlined" key={teamid} sx={{ mb: 1 }}>
                                                    <CardContent>
                                                        <Grid container width='100%'>
                                                            <Grid item>
                                                                <Box sx={{ height: 150, width: 250 }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={"https://poimg01.paraoffice.com/teams/" + teamhash + ".jpg?" + Date.now()}
                                                                        alt="random"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                                {teamname}
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    ParaOffice team:&nbsp;
                                                                    {
                                                                        (teampo === 0) ?
                                                                            "Nei"
                                                                            :
                                                                            "Ja"
                                                                    }
                                                                </Typography>
                                                                {isTeamAdmin &&
                                                                    <Button variant="outlined" size="small" color="warning" href="/team/admin/"><EditIcon className='pobtnicon' /> Administrer team</Button>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            })
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box m={2}>
                                            {teams[0].members.map(({ userid, userhash, useridcard, userdiscordid, userpassworddate, userpassworddateago, useraddress, usergeolat, usergeolong, userpublic, userzipcode, userfacebook, userpubfacebook, usersnapchat, userpubsnapchat, userinstagram, userpubinstagram, usercity, userage, useremail, userpubemail, userlastlogindisp, userlastloginago, userbirthdisp, userfullname, username, teamaccessname, teammemberpaid, teammemberjoindatedisp, teammemberyears }: any, index: number) => {
                                                return <Card variant="outlined" key={userid} sx={{ mb: 1 }}>
                                                    <CardContent>
                                                        <Grid container width='100%'>
                                                            <Grid item>
                                                                <Box sx={{ height: 150, width: 150 }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={"https://poimg01.paraoffice.com/profiles/" + userhash + "_p.jpg?" + Date.now()}
                                                                        alt="random"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                                {userfullname} ({username})
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <PersonAddAltIcon className='potexticonxl' /> {teammemberjoindatedisp} ({teammemberyears} år)
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <EmojiPeopleIcon className='potexticonxl' /> {teamaccessname}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <HomeIcon className='potexticonxl' /> {useraddress}, {userzipcode} ({usercity})
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <CakeIcon className='potexticonxl' /> {userbirthdisp} ({userage} år)
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <AlternateEmailIcon className='potexticonxl' /> {useremail} &nbsp; {userpubemail ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <PlaceIcon className='potexticonxl' /> {usergeolat} / {usergeolong}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <WhatsAppIcon className='potexticonxl' /> {userdiscordid}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <FacebookIcon className='potexticonxl' /> <Link href={"https://facebook.com/" + userfacebook} target='_blank'>{userfacebook}</Link> &nbsp; {userpubfacebook ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <InstagramIcon className='potexticonxl' /> <Link href={"https://instagr.am/" + userinstagram} target='_blank'>{userinstagram}</Link> &nbsp; {userpubinstagram ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <MonochromePhotosIcon className='potexticonxl' /> {usersnapchat} &nbsp; {userpubsnapchat ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <BadgeIcon className='potexticonxl' /> <Link href={"https://ghosthunters.no/id/" + useridcard} target='_blank'>{useridcard}</Link>
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                    <PublicIcon className='potexticonxl' />
                                                                    {
                                                                        (userpublic === 0) ?
                                                                            "Nei"
                                                                            :
                                                                            "Ja"
                                                                    }
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                    <i>Sist logget inn: {userlastlogindisp} ({userlastloginago} dager siden). Passord sist endret {userpassworddateago} ({userpassworddateago} dager siden)</i>
                                                                </Typography>
                                                                {userpassworddateago > 60 &&
                                                                    <Alert severity="error">Passordet er gammelt, og bør endres.</Alert>
                                                                }
                                                                {teammemberpaid === 0 &&
                                                                    <Alert severity="error">Medlemskontingent er ikke betalt.</Alert>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            })
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>





                            <TabPanel value={tabValue} index={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleOpenAddEquipmentModal}
                                        >
                                            <AddCircle className='pobtnicon' />
                                            Legg til utstyr
                                        </Button>
                                        <TableContainer>
                                            <Table aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Navn</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Filer</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teamequipment && teamequipment.map(({ trid, trtype, trname, trfiles }: any, index: number) => {
                                                        return <TableRow key={trid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {trname}
                                                            </TableCell>
                                                            <TableCell>{trtype}</TableCell>
                                                            <TableCell>{trfiles}</TableCell>
                                                            <TableCell align="right">
                                                                {(trfiles === 0) &&
                                                                    <IconButton color="error" aria-label="Slett" onClick={e => submitWipeEqiuipment(trid)}>
                                                                        <DeleteForever />
                                                                    </IconButton>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TabPanel>



                        </Box>
                    </Box>
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </Box>
        </ThemeProvider >
    );
}

export default Team;