import React from "react";
import { FileStatus } from "./ImageUpload";

interface FileItemProps {
  file: File;
  onFileUpload: (
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    onUploadSuccess: () => void,
    onUploadError: (error: string) => void
  ) => void;
  status: FileStatus;
  onError: (file: File, error: string) => void;
  onSuccess: (file: File) => void;
  errorMessage?: string;
}

export default function FileItem(props: FileItemProps) {
  const {
    file,
    status,
    onFileUpload,
    onSuccess,
    onError,
    errorMessage
  } = props;

  const [progress, setProgress] = React.useState(0);

  const handleUploadProgress = React.useCallback((ev: ProgressEvent) => {
    const nextProgress = Math.round((ev.loaded / ev.total) * 100);
    setProgress(nextProgress);
  }, []);

  const handleUploadSuccess = React.useCallback(() => {
    onSuccess(file);
  }, [onSuccess, file]);

  const handleUploadError = React.useCallback(
    (error: string) => {
      onError(file, error);
    },
    [onError, file]
  );

  React.useEffect(() => {
    if (status === "start") {
      onFileUpload(
        file,
        handleUploadProgress,
        handleUploadSuccess,
        handleUploadError
      );
    }
  }, [
    status,
    onFileUpload,
    file,
    handleUploadProgress,
    handleUploadSuccess,
    handleUploadError
  ]);

  return (
    <div className="FileItem__root">
      <strong className="FileItem__name">
        {file.name} ({status})
      </strong>
      <progress className="FileItem__progress" value={progress} max="100" />
      {progress}
      <div className="FileItem__error">{errorMessage}</div>
    </div>
  );
}
