import * as React from 'react';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from '../components/Nav';
import Header from '../components/Header';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ListIcon from '@mui/icons-material/List';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import CardMedia from '@mui/material/CardMedia/CardMedia';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Percentage from '../components/Percentage';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import FolderIcon from '@mui/icons-material/Folder';
import Chip from '@mui/material/Chip/Chip';
import TodayIcon from '@mui/icons-material/Today';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import EventIcon from '@mui/icons-material/Event';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InventoryIcon from '@mui/icons-material/Inventory';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatar from '@mui/material/Avatar/Avatar';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import zIndex from '@mui/material/styles/zIndex';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Alert, CardHeader, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PaidIcon from '@mui/icons-material/Paid';
import { BakeryDining } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import TimeHHMMSS from '../components/TimeHHMMSS';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const poteam = localStorage.getItem('poteamname');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');

function isDateBeforeToday(date: any) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
}


let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 16,
                    },
                },
            },
        },
    },
};

const drawerWidth = 256;

export default function TODO() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [isLoading, setisLoading] = useState(true);
    const [todo, setTodo] = useState<any[]>([]);
    const [snackSuccessMessage, setSnackSuccessMessage] = useState('');
    const [openSnackSuccess, setOpenSnackSuccess] = useState(false);


    const [tasks, setTasks] = useState([
        { id: "task-1", content: "Task 1", type: "type-1" },
        { id: "task-2", content: "Task 2", type: "type-2" },
        // More tasks...
    ]);

    const handleDragEnd = (result: any) => {
        // TODO:
        // Update the tasks state based on the result
    };

    // const handleOpenModalNewNews = async () => {
    //     setmodalAddNews(true);
    // };
    // const handleCloseModalNewNews = () => {
    //     setmodalAddNews(false);
    // };


    // const handleCloseModalViewNewArchive = () => {
    //     setModalViewNewArchive(false);
    //     getNewArchive();
    //     setSnackSuccessMessage("Henvendelsen er markert som lest.");
    //     setOpenSnackSuccess(true);
    // };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleCloseSnackSuccess = () => {
        setOpenSnackSuccess(false);
    };

    // function getTodos() {
    //     axios.get(APIURL + "/v5/myteammembers", { withCredentials: true })
    //         .then(async (response: any) => {
    //             if (response.status === 200) {
    //                 setMyTeamMembers(response.data);
    //             }
    //             else {
    //             }
    //         })
    //         .catch((e: any) => {
    //             if (e.response!.status === 403) {
    //                 window.location.assign("/logout");
    //             }
    //         });
    // }


    // const submitAddEvent = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     setisLoading(true);
    //     setmodalAddEvent(false);
    //     var data = new FormData(event.currentTarget);
    //     var formdata = {
    //         eventlocationid: newEventId,
    //         eventname: data.get('eventname'),
    //         eventdate: data.get('eventdate'),
    //         eventtime: data.get('eventtime'),
    //         eventjoinslots: data.get('eventjoinslots'),
    //         eventtypeid: data.get('eventtypeid'),
    //         eventclassid: data.get('eventclassid'),
    //         eventaccesslevelid: data.get('eventaccesslevelid'),
    //         eventstorageid: data.get('eventstorageid'),
    //     };
    //     axios.post(APIURL + "/event", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    //         .then((response: any) => {
    //             if (response.status === 200) {
    //                 setisLoading(false);
    //                 getNewEvents();
    //             }
    //             else {
    //             }
    //         })
    //         .catch((e: Error) => {
    //         });
    // }



    useEffect(() => {
        document.title = 'ParaOffice';
        setisLoading(false);
        // getTodo();
    }, []);



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }} className='pobody'>
                <CssBaseline />

                {/* <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalAddEvent}
                    onClose={handleCloseModalNewEvent}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til nytt event
                    </DialogTitle>
                    <Box component="form" onSubmit={submitAddEvent}>
                        <DialogContent>
                            <Autocomplete
                                size="small"
                                ListboxProps={{ style: { maxHeight: 400 } }}
                                disablePortal
                                id="eventlocationid"
                                options={locations}
                                onChange={(e, v) => handleNewEventSelectLocation(e, v)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Lokasjon"
                                    name="eventlocationid"
                                />}
                            />
                            <TextField
                                key={"eventnamekey" + (10000 + Math.random() * (1000000 - 10000))}
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventname"
                                label="Navn på event"
                                name="eventname"
                                defaultValue={newEventName}
                            />
                            <TextField
                                type="date"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventdate"
                                label="Dato for event"
                                name="eventdate"
                            />
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventtime"
                                label="Klokkeslett (00:00:00 format)"
                                name="eventtime"
                                defaultValue="20:00:00"
                            />
                            <TextField
                                type="number"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="eventjoinslots"
                                label="Maks deltakere"
                                name="eventjoinslots"
                                defaultValue="10"
                            /><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Type event"
                                name="eventtypeid"
                                defaultValue={1}
                            >
                                {
                                    eventtypes && eventtypes.map(({ eventtypeid, eventtypename }: any) => {
                                        return <MenuItem key={"eventtypeid" + eventtypeid} value={eventtypeid}>{eventtypename}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Event klasse"
                                name="eventclassid"
                                defaultValue={1}
                            >
                                {
                                    eventclasses && eventclasses.map(({ eventclassid, eventclassname }: any) => {
                                        return <MenuItem key={"eventclassid" + eventclassid} value={eventclassid}>{eventclassname}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Tilgangsnivå"
                                name="eventaccesslevelid"
                                defaultValue={1}
                            >
                                {
                                    eventaccesslevels && eventaccesslevels.map(({ eventaccesslevelid, eventaccesslevelname }: any) => {
                                        return <MenuItem key={"eventaccesslevelid" + eventaccesslevelid} value={eventaccesslevelid}>{eventaccesslevelname}</MenuItem>
                                    })
                                }
                            </TextField><p></p>
                            <TextField
                                size="small"
                                select
                                fullWidth
                                label="Lagringsdisk"
                                name="eventstorageid"
                            >
                                {
                                    eventstorage && eventstorage.map(({ storageid, storageopen, storagename }: any) => {
                                        return <MenuItem key={"eventstorage" + storageid} value={storageid}>{storagename + " (" + storageopen + ")"}</MenuItem>
                                    })
                                }
                            </TextField>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til event
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalNewEvent}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingNewEvent}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog> */}


                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={openSnackSuccess}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackSuccess}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {snackSuccessMessage}
                    </Alert>
                </Snackbar>


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 2, pt: 2 }}>
                        <Grid container>
                            <Grid item xs={10} sm={10}>
                                <div className='pcard'>
                                    <div className='pcardheader'>
                                        Oppgaver
                                    </div>
                                    <div className='pcardbody'>

                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="droppable-1" type="type-1">
                                                {(provided: any) => (
                                                    <List ref={provided.innerRef} {...provided.droppableProps}>
                                                        {tasks
                                                            .filter((task) => task.type === "type-1")
                                                            .map((task, index) => (
                                                                <Draggable
                                                                    key={task.id}
                                                                    draggableId={task.id}
                                                                    index={index}
                                                                    // type="type-1"
                                                                >
                                                                    {(provided: any) => (
                                                                        <ListItem
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {task.content}
                                                                        </ListItem>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </List>
                                                )}
                                            </Droppable>
                                            <Droppable droppableId="droppable-2" type="type-2">
                                                {(provided: any) => (
                                                    <List ref={provided.innerRef} {...provided.droppableProps}>
                                                        {tasks
                                                            .filter((task) => task.type === "type-2")
                                                            .map((task, index) => (
                                                                <Draggable
                                                                    key={task.id}
                                                                    draggableId={task.id}
                                                                    index={index}
                                                                    // type="type-2"
                                                                >
                                                                    {(provided: any) => (
                                                                        <ListItem
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {task.content}
                                                                        </ListItem>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </List>
                                                )}
                                            </Droppable>
                                        </DragDropContext>

                                    </div>
                                </div>


                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </Box >
        </ThemeProvider >
    );
}