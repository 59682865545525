import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, AppBar, Autocomplete, Backdrop, Box, Button, Card, CardContent, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, Grid, IconButton, Link, MenuItem, Slide, Stack, TextField, ThemeProvider, Toolbar, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import EventIcon from '@mui/icons-material/Event';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import Pagination from '@mui/material/Pagination';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import MoodIcon from '@mui/icons-material/Mood';
import TagIcon from '@mui/icons-material/Tag';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import HomeIcon from '@mui/icons-material/Home';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import WavesurferPlayer from '../components/WavesurferPlayer';



const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken') || "";



let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function Filelogs() {
    const [isLoading, setisLoading] = useState(true);
    const [filelogs, setFileLogs] = useState<any[]>([]);
    // const [countrys, setCountrys] = useState<any[]>([]);
    // const [filtercountry, setfiltercountry] = useState(1);
    // const [filtercountryname] = useState("Norge");
    // const [locationstates, setlocationstates] = useState<any[]>([]);
    // const [filterstate, setfilterstate] = useState(0);
    // const [filterlocationname, setfilterlocationname] = useState("");
    // const [modalAddLocation, setModalAddLocation] = useState(false);
    // const [locationStates, setLocationStates] = useState<any[]>([]);
    // const [locationContries, setLocationContries] = useState<any[]>([]);
    // const [locationVisit, setLocationVisit] = useState<any[]>([]);
    const [filterlogtype, setfilterlogtype] = useState(0);



    const handleSelectFilterLogType = (efllogcategory: any, efllogcategoryid: any) => {
        if (efllogcategoryid?.id) {
            setfilterlogtype(efllogcategoryid.id);
        }
        else {
            setfilterlogtype(0);
        }
    }


    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [cliphash, setClipHash] = useState('');
    const [clipfile, setClipFile] = useState('');
    const [clipDescription, setClipDescription] = useState('');
    const [clipeqname, setClipEqname] = useState('');
    const [clipEventHash, setClipEventHash] = useState('');
    const [clipisvideo, setClipIsVideo] = useState(true);
    const [modalViewClip, setmodalViewClip] = useState(false);
    const [modalShareClip, setmodalShareClip] = useState(false);
    const [eventfilelogtypes, seteventfilelogtypes] = useState<any[]>([]);


        function handleOpenModalViewClip(chash: any, trtypeid: any, trname: any, effilename: any, eventhash: any, efldescription: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        setClipEventHash(eventhash);
        setClipDescription(efldescription);
        if (trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalViewClip(true);
    };
    const handleCloseModalViewClip = () => {
        setmodalViewClip(false);
    };

    function handleOpenModalShareClip(chash: any, trtypeid: any, trname: any, effilename: any, eventhash: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        setClipEventHash(eventhash);
        if (trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalShareClip(true);
    };
    const handleCloseModalShareClip = () => {
        setmodalShareClip(false);
    };


    async function getlogtypes() {
        await axios.get(APIURL + "/v2/optionslogtypes", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                seteventfilelogtypes(response.data);
            })
    }


    // const [NewLocationStateId, setNewLocationStateId] = useState(0);

    // const handleSelectState = (locationstateid: any) => {
    //     setNewLocationStateId(locationstateid.id);
    // }

    // const [NewLocationCountryId, setNewLocationCountryId] = useState(0);

    // const handleSelectContry = (locationcountryid: any) => {
    //     setNewLocationCountryId(locationcountryid.id);
    // }

    // const [NewLocationVisitId, setNewLocationVisitId] = useState(0);

    // const handleSelectVisitId = (locationvisitid: any) => {
    //     setNewLocationVisitId(locationvisitid.id);
    // }

    async function getfilelogs() {
        var body = {
            // locationcountryid: filtercountry,
            // locationstateid: filterstate,
            // locationname: filterlocationname,
            filterlogtype: filterlogtype,
            page: page
        };
        await axios.post(APIURL + "/v3/filelogs", body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setFileLogs(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    // const handleSelectFilterCountry = (countryname: any, countryid: any) => {
    //     if (countryid?.id) {
    //         setfiltercountry(countryid.id);
    //     }
    //     else {
    //         setfiltercountry(0);
    //     }
    // }

    // const handleSelectFilterState = (statename: any, stateid: any) => {
    //     if (stateid?.id) {
    //         setfilterstate(stateid.id);
    //     }
    //     else {
    //         setfilterstate(0);
    //     }
    // }

    // const handleTypeLocationName = (locationname: any) => {
    //     setfilterlocationname(locationname);
    // }



    // async function getcountrys() {
    //     await axios.get(APIURL + "/v2/optionscountrys", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    //         .then((response: any) => {
    //             setCountrys(response.data);
    //         })
    // }
    // async function getstates() {
    //     await axios.get(APIURL + "/v2/optionsstates", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    //         .then((response: any) => {
    //             setlocationstates(response.data);
    //         })
    // }


    const submitShareClip = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalShareClip(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            dschan: data.get('dschan'),
            eflhash: cliphash,
            pousername: pouser,
            potoken: potoken
        };
        axios.post("https://pofs01.paraoffice.com/clip-discord.php", formdata, { withCredentials: false, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    // setSnackSuccessMessage("Deler klipp på Discord.");
                    // setOpenSnackSuccess(true);
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }
    


    useEffect(() => {
        document.title = 'Logger og funn - ParaOffice';
        getfilelogs();
        getlogtypes();
        // getcountrys();
        // getstates();
        setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterlogtype]);

    // }, [filtercountry, filterstate, page]);

    // useEffect(() => {
    //     setisLoading(true);
    //     getlocations();
    // }, [filtercountry, filterstate, page]);




    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={modalShareClip}
                    onClose={handleCloseModalShareClip}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseModalShareClip}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box component="form" onSubmit={submitShareClip} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Velg discord kanal"
                                    name="dschan"
                                >
                                    <MenuItem value='ngh-felles'>ngh-felles</MenuItem>
                                    <MenuItem value='ngh-crew'>ngh-crew</MenuItem>
                                    <MenuItem value='justchatting'>justchatting</MenuItem>
                                    <MenuItem value='video-og-lydklipp'>video-og-lydklipp</MenuItem>
                                    <MenuItem value='spøkelsesprat'>spøkelsesprat</MenuItem>
                                    <MenuItem value='ghostseekers'>ghostseekers</MenuItem>
                                    <MenuItem value='lgh'>lgh</MenuItem>
                                </TextField>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <ScreenShareIcon className='pobtnicon' />
                                Del klipp
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalShareClip}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={modalViewClip}
                    onClose={handleCloseModalViewClip}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>{clipeqname} - {clipDescription}</Typography>
                            <div>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            clipisvideo ?
                                                "https://media01.paraoffice.com/" + clipEventHash + "/" + cliphash
                                                :
                                                "https://media01.paraoffice.com/" + clipEventHash + "/" + cliphash
                                        )
                                    }}
                                >
                                    <ShareIcon />
                                </IconButton>
                                &nbsp;
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseModalViewClip}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {!isLoading ?
                        clipisvideo ?
                            <video width="100%" height="100%" preload='auto' controls>
                                <source src={"https://media01.paraoffice.com/" + clipEventHash + "/" + cliphash + ".mp4?" + Date.now()} type="video/mp4" />
                            </video>
                            :
                            <div>
                                <WavesurferPlayer audioFile={"https://media01.paraoffice.com/" + clipEventHash + "/" + cliphash + ".wav?" + Date.now()} />
                            </div>
                        : null
                    }
                </Dialog>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ py: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box m={2}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} ml={1}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <EventIcon className='potexticonxl' /> Logger ({filelogs[0]?.totallogs})
                                        </Typography>
                                        <Stack spacing={2}>
                                            <Pagination count={Math.ceil(filelogs[0]?.totallogs / 10)} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                                        </Stack>
                                    </Grid>
                                    <p></p>

                                    {!isLoading ?
                                        <div>
                                            {filelogs && filelogs[0]?.logs.map(({ eflid, efhash, eflusername, eventname, eventhash, teamname, eflhash, eflloguser, effiletypeid, efllogcategory, effilename, efluserid, eflclippublic, eflclipts, eflclip, efllogcategoryid, efltime, efldescription, efldescriptionpublic }: any, index: number) => {
                                                var cardpub = '';
                                                if (eflclippublic === 1) { cardpub = 'CardLogPublic'; }

                                                return <Card key={"loglist" + eflid} className={"CardLog " + cardpub} variant="outlined" sx={{ pb: 1, mb: 1 }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                                    {(efllogcategoryid === 1) && <StarIcon className='logicon loga' />}
                                                                    {(efllogcategoryid === 2) && <StarIcon className='logicon logb' />}
                                                                    {(efllogcategoryid === 3) && <StarIcon className='logicon logc' />}
                                                                    {(efllogcategoryid === 4) && <InfoIcon className='logicon loginfo' />}
                                                                    {(efllogcategoryid === 5) && <CelebrationIcon className='logicon logfun' />}
                                                                    {(efllogcategoryid === 6) && <PersonIcon className='logicon' />}
                                                                    {(efllogcategoryid === 7) && <PersonIcon className='logicon' />}
                                                                    {(efllogcategoryid === 8) && <PersonIcon className='logicon' />}
                                                                    {(efllogcategoryid === 9) && <PersonIcon className='logicon' />}
                                                                    {(efllogcategoryid === 10) && <PhotoCameraFrontIcon className='logicon' />}
                                                                    {(efllogcategoryid === 11) && <MoodIcon className='logicon' />}
                                                                    <Link variant="body2" href={"/eventfile/" + efhash} underline="hover" sx={{ mr: 1 }}>{effilename}</Link> {efldescription}
                                                                </Typography>
                                                                <span>
                                                                    {((eflclip === 1) && (eflclipts != null)) &&
                                                                        <span>
                                                                            <IconButton color="primary" aria-label="Se klipp">
                                                                                <PlayCircleOutlineIcon onClick={e => handleOpenModalViewClip(eflhash, effiletypeid, effiletypeid, effilename, eventhash, efldescription)} />
                                                                            </IconButton>

                                                                            <IconButton color="primary" aria-label="Se klipp">
                                                                                <ShareIcon onClick={e => handleOpenModalShareClip(eflhash, effiletypeid, effilename, eventhash, efldescription)} />
                                                                            </IconButton>
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </Grid>
                                                            {(eflclippublic === 1) &&
                                                                <Alert severity="info">{efldescriptionpublic}</Alert>
                                                            }
                                                            <Typography variant="body2" color="text.secondary" className='logextras' sx={{ ml: 3, flex: 1, flexWrap: 'wrap' }}>
                                                                <TypeSpecimenIcon className='logextrasicon logicon' /> <span className='logareabox'>{efllogcategory}</span>
                                                                <HomeIcon className='logextrasicon logicon' />
                                                                {filelogs[0]?.logs[index]?.areas && filelogs[0].logs[index].areas.map(({ laid, laname }: any, index: number) => {
                                                                    return <span key={eflid + "area" + eflid + "" + laid} className='logareabox'>{laname}
                                                                    </span>
                                                                })
                                                                }
                                                                <PersonIcon className='logextrasicon logicon' />
                                                                {filelogs[0]?.logs[index]?.investigators && filelogs[0].logs[index].investigators.map(({ userid, username }: any, index: number) => {
                                                                    return <span key={eflid + "loguser" + eflid + "" + userid} className='logareabox'>{username}
                                                                    </span>
                                                                })
                                                                }
                                                                <TagIcon className='logextrasicon logicon' />
                                                                {filelogs[0].logs[index]?.tags && filelogs[0].logs[index].tags.map(({ ltid, ltname }: any, index: number) => {
                                                                    return <span key={eflid + "logtag" + eflid + "" + ltid} className='logareabox'>#{ltname}
                                                                    </span>
                                                                })
                                                                }
                                                                <AdminPanelSettingsIcon className='logextrasicon logicon' /> <span className='logareabox'>{eflusername}</span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            })
                                            }
                                        </div>
                                        :
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={isLoading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box m={2}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                        <ContentPasteSearchIcon className='potexticonxl' /> Søk og filtrering
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="efllogcategoryid"
                                                    options={eventfilelogtypes}
                                                    onChange={(e, v) => handleSelectFilterLogType(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Logg klasse"
                                                        name="efllogcategoryid"
                                                    />}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={12} p={2}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="locationname"
                                                    label="Navn på lokasjon"
                                                    name="locationname"
                                                    onChange={(newValue) => handleTypeLocationName(newValue.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            getlocations();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationcountryid"
                                                    options={countrys}
                                                    onChange={(e, v) => handleSelectFilterCountry(e, v)}
                                                    defaultValue={filtercountryname}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Land"
                                                        name="locationcountryid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="locationstateid"
                                                    options={locationstates}
                                                    groupBy={(option) => option.locationgroup}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(e, v) => handleSelectFilterState(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Fylke"
                                                        name="locationstateid"
                                                    />}
                                                />
                                            </Grid> */}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </Box >
        </ThemeProvider >
    );
}

export default Filelogs;