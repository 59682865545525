import React from 'react';
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Alert, AppBar, Autocomplete, Backdrop, Badge, Box, Button, Card, CardContent, CardMedia, Checkbox, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, LinearProgress, LinearProgressProps, Link, MenuItem, Paper, Slide, Snackbar, Tab, Tabs, TextField, ThemeProvider, Toolbar, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { useParams } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StorageIcon from '@mui/icons-material/Storage';
import FolderIcon from '@mui/icons-material/Folder';
import disksize from '../components/DiskSize';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CameraIcon from '@mui/icons-material/Camera';
import DiskSize from '../components/DiskSize';
import DownloadIcon from '@mui/icons-material/Download';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import PersonIcon from '@mui/icons-material/Person';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PendingIcon from '@mui/icons-material/Pending';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Percentage from '../components/Percentage';
import StarIcon from '@mui/icons-material/Star';
import CelebrationIcon from '@mui/icons-material/Celebration';
import MoodIcon from '@mui/icons-material/Mood';
import TagIcon from '@mui/icons-material/Tag';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { TransitionProps } from '@mui/material/transitions';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
// import "react-image-lightbox/style.css";
import { Image } from "react-grid-gallery";
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ShareIcon from '@mui/icons-material/Share';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Category from '@mui/icons-material/Category';
import TimeHHMMSS from '../components/TimeHHMMSS';
import ImageUpload from "../components/ImageUpload";
import EventFileUpload from "../components/EventFileUpload";
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VerifiedIcon from '@mui/icons-material/Verified';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../components/canvaspreview';
import { useDebounceEffect } from '../components/useDebounceEffect';
import ImageIcon from '@mui/icons-material/Image';
import HotelIcon from '@mui/icons-material/Hotel';
import WavesurferPlayer from '../components/WavesurferPlayer';

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function isDateBeforeToday(date: any) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export interface CustomImage extends Image {
    original: string;
}


const APIURL = process.env.REACT_APP_APIURL;
const APIFSURL = process.env.REACT_APP_APIFSURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken') || "";
const eventtab = localStorage.getItem('eventtab') || "0";


let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function Event() {
    const { eventhash } = useParams();
    const [activeimagefolderid, setactiveimagefolderid] = useState(0);
    const [activeimagefolderhash, setactiveimagefolderhash] = useState("");
    const [uploadimages, setuploadimages] = useState<any[]>([]);
    const [dropupload, setdropupload] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    const [isUploadingImages, setisUploadingImages] = useState(false);
    const [isUploadingDropzone, setIsUploadingDropzone] = useState(false);
    const [modalEditEventBackup, setmodalEditEventBackup] = useState(false);
    const [modalEditEvent, setmodalEditEvent] = useState(false);
    const [logomodal, setLogoModal] = useState(false);
    const [ModalNewFiles, setModalNewFiles] = useState(false);
    const [isFileUploading, setisFileUploading] = useState(false);
    const [modalNewEquipment, setmodalNewEquipment] = useState(false);
    const [modalNewEquipmentFolder, setmodalNewEquipmentFolder] = useState(false);
    const [modalNewImageFolder, setmodalNewImageFolder] = useState(false);

    const [modalEditPublicEvent, setmodalEditPublicEvent] = useState(false);
    const [eventstorage, setEventStorage] = useState<any[]>([]);
    const [eventstoragebackup, setEventStorageBackup] = useState<any[]>([]);
    const [TabValue, setTabValue] = useState(0);
    const [activefolderid, setactivefolderid] = useState(0);
    const [activefoldername, setactivefoldername] = useState(0);
    const [activefoldereqname, setactivefoldereqname] = useState(0);

    const [users, setUsers] = useState<any[]>([]);
    const [contacts, setContacts] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [ouser, setoUser] = useState(0);
    const [oteam, setoTeam] = useState(0);
    const [newInvestigator, setNewInvestigator] = useState(0);
    const [filtertags, setfiltertags] = useState(0);
    const [logAreas, setlogAreas] = useState<any[]>([]);
    const [logUsers, setlogUsers] = useState<any[]>([]);
    const [selectedTags, setSelectedTags] = useState<any[]>([]);

    const [filterlogtype, setfilterlogtype] = useState(2);
    const [filterareaid, setfilterareaid] = useState(0);
    const [filteruserid, setfilteruserid] = useState(0);

    const [eventfilelogtypes, seteventfilelogtypes] = useState<any[]>([]);

    const [eventfilelogs, setEventFileLogs] = useState<any[]>([]);
    const [imagefolders, setimagefolders] = useState<any[]>([]);
    const [folderfiles, setfolderfiles] = useState<any[]>([]);
    const [teamRecorders, setTeamRecorders] = useState<any[]>([]);
    const [activeeventimagefoldername, setactiveeventimagefoldername] = useState("");

    const [eventid, seteventid] = useState(0);
    const [newRecorder, setnewRecorder] = useState(0);
    const [newContact, setnewContact] = useState(0);
    const [currentEquipmentId, setcurrentEquipmentId] = useState(0);

    const [locations, setLocations] = useState<any[]>([]);
    const [eventclasses, setEventClasses] = useState<any[]>([]);
    const [eventtypes, setEventTypes] = useState<any[]>([]);
    const [eventstatuses, setEventStatuses] = useState<any[]>([]);
    const [eventaccesslevels, setEventAccessLevels] = useState<any[]>([]);
    const [eventimages, setEventImages] = useState<any[]>([]);
    const [eventimagestmp, setEventImagestmp] = useState<any[]>([]);
    const [logTags, setlogTags] = useState<any[]>([]);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };












    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(16 / 9);



    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        setisLoading(true);
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const formData = new FormData();
            formData.append("token", potoken);
            formData.append("eventhash", eventhash || "");
            formData.append("file[]", blob);
            await axios.post("https://poimg01.paraoffice.com/eventimageupload.php", formData, { headers: { 'Content-Type': blob.type } })
                .then((response: any) => {
                    if (response.status === 200) {
                        getevent();
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setisLoading(false);
                });
        })
        setLogoModal(false);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )




    const handleClickImageModalOpen = () => {
        setLogoModal(true);
    };

    const handleClickImageModalClose = () => {
        setLogoModal(false);
    };





    const [devfolders, setDevFolders] = useState<any[]>([]);

    const [event, setEvent] = useState<any[]>([]);


    const cancelTokenSourceRef = React.useRef(axios.CancelToken.source());

    const handleImageUpload = (
        file: File,
        onUploadProgress: (event: ProgressEvent) => void,
        onUploadSuccess: () => void,
        onUploadError: (error: string) => void
    ) => {
        const formData = new FormData();
        formData.append("token", potoken);
        formData.append("hash", eventhash || "");
        formData.append("type", "eventimage");
        formData.append("folderhash", activeimagefolderhash);
        formData.append("file[]", file);
        axios({
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            url: "https://poimg01.paraoffice.com/upload.php",
            onUploadProgress,
            cancelToken: cancelTokenSourceRef.current.token
        })
            .then(() => {
                onUploadSuccess();
            })
            .catch((err: Error) => {
                onUploadError(err.message);
            });
    };

    const handleImageCancel = () => {
        cancelTokenSourceRef.current.cancel("Upload cancelled");
    };

    const handleEventFileUpload = (
        file: File,
        onUploadProgress: (event: ProgressEvent) => void,
        onUploadSuccess: () => void,
        onUploadError: (error: string) => void
    ) => {
        const formData = new FormData();
        formData.append("token", potoken);
        formData.append("hash", eventhash || "");
        formData.append("folderid", activefolderid.toString());
        formData.append("file", file);
        axios({
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            url: "https://pofs01.paraoffice.com/upload.php",
            onUploadProgress,
            cancelToken: cancelTokenSourceRef.current.token
        })
            .then(() => {
                onUploadSuccess();
            })
            .catch((err: Error) => {
                onUploadError(err.message);
            });
    };

    const handleEventFileCancel = () => {
        cancelTokenSourceRef.current.cancel("Upload cancelled");
    };


    const [cliphash, setClipHash] = useState('');
    const [clipfile, setClipFile] = useState('');
    const [clipeqname, setClipEqname] = useState('');
    const [clipisvideo, setClipIsVideo] = useState(true);
    const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
    const [modalViewClip, setmodalViewClip] = useState(false);
    const [modalNewInvestigator, setmodalNewInvestigator] = useState(false);
    const [modalNewContact, setmodalNewContact] = useState(false);
    const [modalShareClip, setmodalShareClip] = useState(false);
    const [modalImageUpload, setmodalImageUpload] = useState(false);
    const [modalEventFileUpload, setmodalEventFileUpload] = useState(false);
    function handleOpenModalViewClip(chash: any, trtypeid: any, trname: any, effilename: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        if (trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalViewClip(true);
    };
    const handleCloseModalViewClip = () => {
        setmodalViewClip(false);
    };

    function handleOpenModalShareClip(chash: any, trtypeid: any, trname: any, effilename: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        if (trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalShareClip(true);
    };
    const handleCloseModalShareClip = () => {
        setmodalShareClip(false);
    };


    function handleOpenModalImageUpload() {
        setmodalImageUpload(true);
    };
    const handleCloseModalImageUpload = () => {
        setmodalImageUpload(false);
        getImagesFromFolderName(activeimagefolderhash, activeeventimagefoldername);
    };


    function handleOpenEventFileUploadModal() {
        setmodalEventFileUpload(true);
    };
    const handleCloseEventFileUploadModal = () => {
        setmodalEventFileUpload(false);

    };


    const [index, setIndex] = useState(-1);
    const currentImage = eventimages[index];
    const nextIndex = (index + 1) % eventimages.length;
    const nextImage = eventimages[nextIndex] || currentImage;
    const prevIndex = (index + eventimages.length - 1) % eventimages.length;
    const prevImage = eventimages[prevIndex] || currentImage;
    const handleClick = (index: number, item: CustomImage) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);



    const submitJoinEvent = (eventid: number) => {
        setisLoading(true);
        var formdata = {};
        axios.post(APIURL + "/event/join/" + eventid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                getevent();
            })
            .catch((e: Error) => {
            });
    }

    const submitLeaveEvent = (eventid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/event/join/" + eventid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                getevent();
            })
            .catch((e: Error) => {
            });
    }


    async function submitDateSync(currentefid: number) {
        var formdata = {};
        await axios.post(APIURL + "/v2/eventfiledatesync/" + currentefid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    handleViewFolderFiles(activefolderid);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }



    const handleModalEditEventBackupClose = () => {
        setmodalEditEventBackup(false);
    };


    const handleOpenModalNewImageFolder = () => {
        setmodalNewImageFolder(true);
    };

    const handleCloseModalNewImageFolder = () => {
        setmodalNewImageFolder(false);
    };

    const handleOpenModalNewEquipment = async () => {
        await axios.get(APIURL + "/teamequipment", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setTeamRecorders(response.data);
            })

        setmodalNewEquipment(true);
    };

    const handleOpenModalNewEquipmentFolder = async (trid: number) => {
        setcurrentEquipmentId(trid);
        setmodalNewEquipmentFolder(true);
    };
    const handleCloseModalNewEquipmentFolder = async () => {
        setmodalNewEquipmentFolder(false);
    };

    const handleOpenModalNewInvestigator = async () => {
        getusers();
        setmodalNewInvestigator(true);
    };
    const handleCloseModalNewInvestigator = async () => {
        setmodalNewInvestigator(false);
    };

    const handleOpenModalNewContact = async () => {
        getcontacts();
        setmodalNewContact(true);
    };
    const handleCloseModalNewContact = async () => {
        setmodalNewContact(false);
    };


    const handlePublishImage = async (eifhash: string) => {
        var json = {};
        console.log("pub: " + eifhash);
        axios.put(APIURL + "/eventimagepub/" + eifhash, json, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                }
            })
            .catch((e: Error) => {
            });
    }

    const submitNewInvestigator = (evform: React.FormEvent<HTMLFormElement>) => {
        evform.preventDefault();
        setmodalNewInvestigator(false);
        var json = {};
        axios.post(APIURL + "/v2/event/adduser/" + event[0]?.eventid + "/" + newInvestigator, json, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getevent();
                }
            })
            .catch((e: any) => {
                getevent();
                console.log(e);
            });
    }

    const submitNewContact = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setmodalNewContact(false);
        var data = new FormData(e.currentTarget);
        var formdata = {
            contactid: newContact,
        };
        axios.post(APIURL + "/v3/event/" + event[0]?.eventid + "/contact", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getevent();
                }
            })
            .catch((e: any) => {
                getevent();
                console.log(e);
            });
    }





    const handleUnPublishImage = async (eifhash: string) => {
        var json = {};
        axios.put(APIURL + "/eventimagermpub/" + eifhash, json, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                }
            })
            .catch((e: Error) => {
            });
    }


    const handleCloseModalNewEquipment = () => {
        setmodalNewEquipment(false);
    };

    const handleSelectNewRecorder = (event: any, values: any) => {
        setnewRecorder(values['id']);
    }

    const handleSelectNewContact = (event: any, values: any) => {
        setnewContact(values['id']);
    }


    const handleSelectNewUser = (event: any, values: any) => {
        setoUser(values['id']);
    }
    const handleSelectNewInvestigator = (event: any, values: any) => {
        setNewInvestigator(values['id']);
    }

    const handleSelectNewTeam = (event: any, values: any) => {
        setoTeam(values['id']);
    }


    const submitAssignFile = (fileid: number) => {
        setisLoading(true);
        var body = {};
        axios.put(APIURL + "/eventfileassign/" + fileid, body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    handleViewFolderFiles(activefolderid);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }
    async function getImagesFromFolder(folderhash: string) {
        setEventImages([]);
        setEventImagestmp([]);
        await axios.get(APIURL + "/v2/eventimages/" + folderhash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                response.data.map(({ eifilenamedisk, eifhash, eihash, eimheight, eimwidth, eipublic }: any) => {
                    eimheight = eimheight || 320;
                    eimwidth = eimwidth || 320;
                    var filetype = eifilenamedisk.slice(-3);
                    if (eipublic === 1) {
                        eventimagestmp.push({ src: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eihash + "_t." + filetype, original: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eifilenamedisk, width: eimwidth, height: eimheight, tags: [{ value: "offentlig publisert", title: "offentlig publisert", }], caption: <IconButton onClick={e => handleUnPublishImage(eihash)} color="error" aria-label="Fjern publiesring offentlig"><PublicIcon /></IconButton> });
                    }
                    else {
                        eventimagestmp.push({
                            src: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eihash + "_t." + filetype, original: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eifilenamedisk, width: eimwidth, height: eimheight, caption: <IconButton onClick={e => handlePublishImage(eihash)} color="primary" aria-label="Publiser offentlig"><PublicIcon /></IconButton>
                        });
                    }
                })
            })
        setEventImages(eventimagestmp);
    }

    async function getImagesFromFolderName(folderhash: string, foldername: string) {
        setactiveeventimagefoldername(foldername);
        localStorage.setItem('currentimgfolder' + eventid, folderhash);
        localStorage.setItem('currentimgfoldername' + eventid, foldername);
        setEventImages([]);
        setEventImagestmp([]);
        setactiveimagefolderhash(folderhash);
        await axios.get(APIURL + "/v2/eventimages/" + folderhash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                response.data.map(({ eifilenamedisk, eifhash, eihash, eimheight, eimwidth, eipublic }: any) => {
                    eimheight = eimheight || 320;
                    eimwidth = eimwidth || 320;
                    var filetype = eifilenamedisk.slice(-3);
                    if (eipublic === 1) {
                        eventimagestmp.push({ src: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eihash + "_t." + filetype, original: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eifilenamedisk, width: eimwidth, height: eimheight, tags: [{ value: "offentlig publisert", title: "offentlig publisert", }], caption: <IconButton onClick={e => handleUnPublishImage(eihash)} color="error" aria-label="Fjern publiesring offentlig"><PublicIcon /></IconButton> });
                    }
                    else {
                        eventimagestmp.push({
                            src: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eihash + "_t." + filetype, original: "https://poimg01.paraoffice.com/events/" + eventhash + "/" + eifhash + "/" + eifilenamedisk, width: eimwidth, height: eimheight, caption: <IconButton onClick={e => handlePublishImage(eihash)} color="primary" aria-label="Publiser offentlig"><PublicIcon /></IconButton>
                        });
                    }
                })
            })
        setEventImages(eventimagestmp);
        console.log(eventimagestmp)
    }


    const submitUnAssignFile = (fileid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/eventfileassign/" + fileid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    handleViewFolderFiles(activefolderid);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitWipeEventContact = (contactid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/event/" + event[0]?.eventid + "/contact/" + contactid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    getevent();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }



    const handleViewFolderFiles = (folderid: any) => {
        localStorage.setItem('currentfilefolder' + eventid, folderid);
        axios.get(APIURL + "/eventfilesfolderid/" + folderid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setfolderfiles(response.data);
                setactivefolderid(folderid);
            })
        axios.get(APIURL + "/eventfilesfolder/" + folderid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setactivefoldername(response.data[0].effname);
                setactivefoldereqname(response.data[0].trname);
            })
    };


    const handleModalEditEventBackupOpen = async () => {
        await axios.get(APIURL + "/eventstorageoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStorage(response.data);
            })
        await axios.get(APIURL + "/eventstoragebackupoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStorageBackup(response.data);
            })
        setmodalEditEventBackup(true);
    };
    const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        localStorage.setItem('eventtab', newValue.toString());
        // if (newValue === 1) {
        //     geteventfiles();
        // }
        // if (newValue === 2) {
        //     geteventimagefolders();
        // }
        // if (newValue === 3) {
        //     geteventfilelogs();
        //     getlogtypes();
        //     getlocationareas();
        //     geteventusers();
        //     getlogtags();
        // }
    };



    function geteventfiles() {
        axios.get(APIURL + "/eventdevfolders/" + eventid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setDevFolders(response.data);
                console.log(response.data);
            })
    }

    function geteventimagefolders() {
        axios.get(APIURL + "/v2/eventimagefolders/" + eventid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setimagefolders(response.data);
                console.log(response.data);
            })
    }



    const handleSelectFilterLogType = (efllogcategory: any, efllogcategoryid: any) => {
        if (efllogcategoryid?.id) {
            setfilterlogtype(efllogcategoryid.id);
        }
        else {
            setfilterlogtype(0);
        }
    }

    const handleSelectFilterAreaId = (areaname: any, areaid: any) => {
        if (areaid?.id) {
            setfilterareaid(areaid.id);
        }
        else {
            setfilterareaid(0);
        }
    }


    const handleSelectFilterTags = (tagname: any, tagid: any) => {
        if (tagid?.id) {
            setfiltertags(tagid.id);
        }
        else {
            setfiltertags(0);
        }
    }



    const handleSelectFilterUser = (username: any, userid: any) => {
        if (userid?.id) {
            setfilteruserid(userid.id);
        }
        else {
            setfilteruserid(0);
        }
    }




    async function getlogtypes() {
        await axios.get(APIURL + "/v2/optionslogtypes", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                seteventfilelogtypes(response.data);
            })
    }


    async function geteventusers() {
        await axios.get(APIURL + "/v2/optionseventusers/" + event[0]?.eventid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setlogUsers(response.data);
            })
    }

    const handleClickLogoModalOpen = () => {
        setLogoModal(true);
    };
    const handleClickLogoModalClose = () => {
        setLogoModal(false);
    };

    const handleModalEditEventClose = () => {
        setmodalEditEvent(false);
    };
    const handleModalEditPublicEventClose = () => {
        setmodalEditPublicEvent(false);
    };
    const handleModalEditPublicEventOpen = () => {
        setmodalEditPublicEvent(true);
    };
    const handleModalEditEventOpen = async () => {
        getusers();
        getteams();
        await axios.get(APIURL + "/v3/locationoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocations(response.data);
            })
        await axios.get(APIURL + "/statusoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStatuses(response.data);
            })
        await axios.get(APIURL + "/eventclasses", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventClasses(response.data);
            })
        await axios.get(APIURL + "/eventtypes", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventTypes(response.data);
            })
        await axios.get(APIURL + "/eventaccesslevels", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventAccessLevels(response.data);
            })
        await axios.get(APIURL + "/eventstorageoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventStorage(response.data);
            })
        setmodalEditEvent(true);
    };


    function getusers() {
        axios.get(APIURL + "/v2/optionsusers", { withCredentials: true })
            .then((response: any) => {
                setUsers(response.data);
            })
    }

    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setContacts(response.data);
            })
    }


    function getteams() {
        axios.get(APIURL + "/v2/optionsteams", { withCredentials: true })
            .then((response: any) => {
                setTeams(response.data);
            })
    }

    function getlogtags() {
        setisLoading(true);
        axios.get(APIURL + "/eventfilelogtags", { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setlogTags(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    function getlocationareas() {
        axios.get(APIURL + "/v2/optionslocationareas/" + event[0]?.eventlocationid, { withCredentials: true })
            .then((response: any) => {
                setlogAreas(response.data);
            })
    }

    const submitEditEventBackup = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalEditEventBackup(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eventstorageid: data.get('eventstorageid'),
            eventstorageidb1: data.get('eventstorageidb1'),
            eventstorageidb2: data.get('eventstorageidb2'),
            eventstorageidb3: data.get('eventstorageidb3'),
            eventstorageidb4: data.get('eventstorageidb4'),
            eventstorageidb5: data.get('eventstorageidb5'),
        };
        axios.put(APIURL + "/eventbackup/" + eventhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getevent();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    const submitNewEquipment = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalNewEquipment(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            effname: data.get('newfoldername'),
            effeventid: eventid,
            efftrid: newRecorder,
        };
        axios.post(APIURL + "/event/newrecorder", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfiles();
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitShareClip = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalShareClip(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            dschan: data.get('dschan'),
            eflhash: cliphash,
            pousername: pouser,
            potoken: potoken
        };
        axios.post("https://pofs01.paraoffice.com/clip-discord.php", formdata, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response: any) => {
                if (response.status === 200) {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    const submitNewEquipmentFolder = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalNewEquipmentFolder(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            effname: data.get('newfoldername'),
            effeventid: eventid,
            efftrid: currentEquipmentId,
        };
        axios.post(APIURL + "/event/newfolder", formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfiles();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }


    const submitNewImageFolder = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalNewImageFolder(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eifname: data.get('newfoldername'),
        };
        axios.post(APIURL + "/eventimagefolders/" + eventid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventimagefolders();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }


    const submitEditEvent = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalEditEvent(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eventname: data.get('eventname'),
            eventlocationid: data.get('eventlocationid'),
            eventdate: data.get('eventdate'),
            eventtime: data.get('eventtime'),
            eventjoinslots: data.get('eventjoinslots'),
            eventtypeid: data.get('eventtypeid'),
            eventclassid: data.get('eventclassid'),
            eventaccesslevelid: data.get('eventaccesslevelid'),
            eventstorageid: data.get('eventstorageid'),
            eventstatusid: data.get('eventstatusid'),
            eventinfo: data.get('eventinfo'),
            eventjoinlimit: data.get('eventjoinlimit'),
            eventowneruserid: ouser,
            eventownerteamid: oteam,
        };
        axios.put(APIURL + "/event/" + eventhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getevent();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitEditPublicEvent = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalEditPublicEvent(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eventpublic: data.get('eventpublic'),
            eventcalendar: data.get('eventcalendar'),
            eventnamepublic: data.get('eventnamepublic'),
            eventcalendarname: data.get('eventcalendarname'),
            eventpublicurl: data.get('eventpublicurl'),
            eventpublicinfo: data.get('eventpublicinfo'),
        };
        axios.put(APIURL + "/eventpublic/" + eventhash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getevent();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    async function getevent() {
        setisLoading(true);
        await axios.get(APIURL + "/eventdetails/" + eventhash, { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    console.log(response.data);
                    setEvent(response.data);
                    seteventid(response.data[0].eventid);
                    setoUser(response.data[0].eventowneruserid);
                    setoTeam(response.data[0].eventownerteamid);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setTabValue(parseInt(eventtab));
        setisLoading(false);
    }

    function geteventfilelogs() {
        setisLoading(true);
        var formdata = {
            filterlogtype: filterlogtype,
            filterareaid: filterareaid,
            filteruserid: filteruserid,
            filtertags: filtertags,
        };
        axios.post(APIURL + "/v2/eventfilelogs/" + eventid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setEventFileLogs(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const handleSubmitEventLogo = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setLogoModal(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            token: localStorage.getItem("potoken"),
            hash: eventhash,
            type: data.get('type'),
            file: data.get('file'),
        };
        axios.post(APIFSURL + "/upload.php", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getevent();
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }

    useEffect(() => {
        document.title = 'Event - ParaOffice';
        getevent();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (TabValue == 1) {
            geteventfiles();
            let currentfilefolder = localStorage.getItem('currentfilefolder' + eventid);
            if (currentfilefolder) {
                handleViewFolderFiles(currentfilefolder);
            }

            // let currentfoldername = localStorage.getItem('currentimgfoldername' + eventid) || "";
            // if (currentfolderhash) {
            //     getImagesFromFolderName(currentfolderhash, currentfoldername);
            // }
        }
        if (TabValue == 2) {
            geteventimagefolders();
            let currentfolderhash = localStorage.getItem('currentimgfolder' + eventid);
            let currentfoldername = localStorage.getItem('currentimgfoldername' + eventid) || "";
            if (currentfolderhash) {
                getImagesFromFolderName(currentfolderhash, currentfoldername);
            }
        }
        if (TabValue == 3) {
            geteventfilelogs();
            getlogtypes();
            getlocationareas();
            geteventusers();
            getlogtags();
        }
    }, [TabValue]);


    useEffect(() => {
        setisLoading(true);
        geteventfilelogs();
    }, [filterlogtype, filterareaid, filteruserid, filtertags]);


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={modalViewClip}
                            onClose={handleCloseModalViewClip}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                                    <div>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    clipisvideo ?
                                                        "https://media01.paraoffice.com/" + event[0]?.eventhash + "/" + cliphash
                                                        :
                                                        "https://media01.paraoffice.com/" + event[0]?.eventhash + "/" + cliphash
                                                )
                                            }}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                        &nbsp;
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={handleCloseModalViewClip}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </Toolbar>
                            </AppBar>
                            {!isLoading ?
                                clipisvideo ?
                                    <video width="100%" height="100%" preload='auto' controls>
                                        <source src={"https://media01.paraoffice.com/" + event[0]?.eventhash + "/" + cliphash + ".mp4?" + Date.now()} type="video/mp4" />
                                    </video>
                                    :
                                    <div>
                                        <WavesurferPlayer audioFile={"https://media01.paraoffice.com/" + event[0]?.eventhash + "/" + cliphash + ".wav?" + Date.now()} />
                                    </div>
                                : null
                            }
                        </Dialog>



                        <Dialog
                            open={logomodal}
                            onClose={handleClickLogoModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Box component="form" onSubmit={handleSubmitEventLogo} noValidate>
                                <DialogTitle id="alert-dialog-title">
                                    Last opp nytt eventbilde
                                </DialogTitle>
                                <DialogContent>

                                    <input type="file" accept="image/*" onChange={onSelectFile} />
                                    {!!imgSrc && (
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            aspect={aspect}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Crop me"
                                                src={imgSrc}
                                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                                onLoad={onImageLoad}
                                            />
                                        </ReactCrop>
                                    )}
                                    {!!completedCrop && (
                                        <>
                                            <div>
                                                <Typography>
                                                    Forhåndsvisning:
                                                </Typography>
                                                <canvas
                                                    ref={previewCanvasRef}
                                                    style={{
                                                        border: '1px solid black',
                                                        objectFit: 'contain',
                                                        width: 350,
                                                        height: 350,
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}


                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onDownloadCropClick}
                                        size="small"
                                        variant="outlined"
                                        color="success"
                                    >Last opp</Button>
                                    <Button onClick={handleClickImageModalClose}
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                    >Avbryt</Button>
                                </DialogActions>
                            </Box>
                        </Dialog>



                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={modalEventFileUpload}
                            onClose={handleCloseEventFileUploadModal}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ flex: 1 }}>Last opp fil(er)</Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEventFileUploadModal}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <div className="App">
                                    <EventFileUpload onFileUpload={handleEventFileUpload} onCancel={handleEventFileCancel} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCloseEventFileUploadModal}
                                >
                                    <CloseIcon className='pobtnicon' />
                                    Lukk
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={modalImageUpload}
                            onClose={handleCloseModalImageUpload}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ flex: 1 }}>Last opp bilde(r)</Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseModalImageUpload}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <div className="App">
                                    <ImageUpload onFileUpload={handleImageUpload} onCancel={handleImageCancel} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCloseModalImageUpload}
                                >
                                    <CloseIcon className='pobtnicon' />
                                    Lukk
                                </Button>
                            </DialogActions>
                        </Dialog>



                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={modalShareClip}
                            onClose={handleCloseModalShareClip}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseModalShareClip}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" onSubmit={submitShareClip} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Velg discord kanal"
                                            name="dschan"
                                        >
                                            <MenuItem value='ngh-felles'>ngh-felles</MenuItem>
                                            <MenuItem value='ngh-crew'>ngh-crew</MenuItem>
                                            <MenuItem value='justchatting'>justchatting</MenuItem>
                                            <MenuItem value='video-og-lydklipp'>video-og-lydklipp</MenuItem>
                                            <MenuItem value='spøkelsesprat'>spøkelsesprat</MenuItem>
                                            <MenuItem value='ghostseekers'>ghostseekers</MenuItem>
                                            <MenuItem value='lgh'>lgh</MenuItem>
                                        </TextField>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <ScreenShareIcon className='pobtnicon' />
                                        Del klipp
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalShareClip}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalEditEventBackup}
                            onClose={handleModalEditEventBackupClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Endre lagringsinformasjon
                            </DialogTitle>
                            <Box component="form" onSubmit={submitEditEventBackup} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Lagringsdisk"
                                            name="eventstorageid"
                                            defaultValue={event[0]?.eventstorageid}
                                        >
                                            {
                                                eventstorage && eventstorage.map(({ storageid, storageopen, storagename }: any) => {
                                                    return <MenuItem key={"eventstorage" + storageid} value={storageid}>{storagename + " (" + storageopen + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Backup #1"
                                            name="eventstorageidb1"
                                            defaultValue={event[0]?.eventstorageidb1}
                                        >
                                            <MenuItem key={"eventstorageidb1-0"} value="">(ingen)</MenuItem>
                                            {
                                                eventstoragebackup && eventstoragebackup.map(({ eventstoragebackupid, eventstoragebackupname, eventstoragebackupdisk }: any) => {
                                                    return <MenuItem key={"eventstorageidb1" + eventstoragebackupid} value={eventstoragebackupid}>{eventstoragebackupname + " (" + eventstoragebackupdisk + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Backup #2"
                                            name="eventstorageidb2"
                                            defaultValue={event[0]?.eventstorageidb2}
                                        >
                                            <MenuItem key={"eventstorageidb2-0"} value="">(ingen)</MenuItem>
                                            {
                                                eventstoragebackup && eventstoragebackup.map(({ eventstoragebackupid, eventstoragebackupname, eventstoragebackupdisk }: any) => {
                                                    return <MenuItem key={"eventstorageidb2" + eventstoragebackupid} value={eventstoragebackupid}>{eventstoragebackupname + " (" + eventstoragebackupdisk + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Backup #3"
                                            name="eventstorageidb3"
                                            defaultValue={event[0]?.eventstorageidb3}
                                        >
                                            <MenuItem key={"eventstorageidb3-0"} value="">(ingen)</MenuItem>
                                            {
                                                eventstoragebackup && eventstoragebackup.map(({ eventstoragebackupid, eventstoragebackupname, eventstoragebackupdisk }: any) => {
                                                    return <MenuItem key={"eventstorageidb3" + eventstoragebackupid} value={eventstoragebackupid}>{eventstoragebackupname + " (" + eventstoragebackupdisk + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Backup #4"
                                            name="eventstorageidb4"
                                            defaultValue={event[0]?.eventstorageidb4}
                                        >
                                            <MenuItem key={"eventstorageidb4-0"} value="">(ingen)</MenuItem>
                                            {
                                                eventstoragebackup && eventstoragebackup.map(({ eventstoragebackupid, eventstoragebackupname, eventstoragebackupdisk }: any) => {
                                                    return <MenuItem key={"eventstorageidb4" + eventstoragebackupid} value={eventstoragebackupid}>{eventstoragebackupname + " (" + eventstoragebackupdisk + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Backup #5"
                                            name="eventstorageidb5"
                                            defaultValue={event[0]?.eventstorageidb5}
                                        >
                                            <MenuItem key={"eventstorageidb5-0"} value="">(ingen)</MenuItem>
                                            {
                                                eventstoragebackup && eventstoragebackup.map(({ eventstoragebackupid, eventstoragebackupname, eventstoragebackupdisk }: any) => {
                                                    return <MenuItem key={"eventstorageidb5" + eventstoragebackupid} value={eventstoragebackupid}>{eventstoragebackupname + " (" + eventstoragebackupdisk + ")"}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Lagre
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleModalEditEventBackupClose}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalNewImageFolder}
                            onClose={handleCloseModalNewImageFolder}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Legg til bildemappe
                            </DialogTitle>
                            <Box component="form" onSubmit={submitNewImageFolder} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Mappenavn"
                                            name="newfoldername"
                                        >
                                        </TextField>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Legg til
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNewEquipment}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalNewEquipment}
                            onClose={handleCloseModalNewEquipment}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Legg til utstyr og mappe
                            </DialogTitle>
                            <Box component="form" onSubmit={submitNewEquipment} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Autocomplete
                                            size="small"
                                            ListboxProps={{ style: { maxHeight: 200 } }}
                                            disablePortal
                                            id="teamrecorderid"
                                            options={teamRecorders}
                                            onChange={(e, v) => handleSelectNewRecorder(e, v)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Utstyr"
                                                name="newfoldereqid"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Mappenavn"
                                            name="newfoldername"
                                        >
                                        </TextField>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Legg til
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNewEquipment}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalNewInvestigator}
                            onClose={handleCloseModalNewInvestigator}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Legg til deltaker på event
                            </DialogTitle>
                            <Box component="form" onSubmit={submitNewInvestigator} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Autocomplete
                                            fullWidth
                                            id="checkboxes-logtags"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            options={users}
                                            onChange={(e, v) => handleSelectNewInvestigator(e, v)}
                                            groupBy={(option) => option.groupname}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Person" />
                                            )}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Legg til
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNewInvestigator}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>



                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalNewContact}
                            onClose={handleCloseModalNewContact}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Legg til ekstern deltaker på event
                            </DialogTitle>
                            <Box component="form" onSubmit={submitNewContact}>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Autocomplete
                                            fullWidth
                                            id="checkboxes-logtags"
                                            ListboxProps={{ style: { maxHeight: 400 } }}
                                            options={contacts}
                                            onChange={(e, v) => handleSelectNewContact(e, v)}
                                            // groupBy={(option) => option.groupname}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Person" />
                                            )}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Legg til
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNewContact}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalNewEquipmentFolder}
                            onClose={handleCloseModalNewEquipmentFolder}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Legg til ny mappe
                            </DialogTitle>
                            <Box component="form" onSubmit={submitNewEquipmentFolder} noValidate>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Mappenavn"
                                            name="newfoldername"
                                        >
                                        </TextField>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Legg til
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNewEquipmentFolder}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalEditEvent}
                            onClose={handleModalEditEventClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Endre event opplysninger
                            </DialogTitle>
                            <Box component="form" onSubmit={submitEditEvent} noValidate>
                                <DialogContent>
                                    {(pomod === "1") &&
                                        <Grid item xs={12}>
                                            <Card variant="outlined" className="CardMod" sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Typography gutterBottom sx={{ mb: 2 }}>
                                                        Moderator alternativer
                                                    </Typography>
                                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                                        <Autocomplete
                                                            size="small"
                                                            ListboxProps={{ style: { maxHeight: 200 } }}
                                                            disablePortal
                                                            id="eventowneruserid"
                                                            options={users}
                                                            defaultValue={event[0]?.eventownerusername}
                                                            onChange={(e, v) => handleSelectNewUser(e, v)}
                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                label="Ansvarlig person"
                                                                name="eventowneruserid"
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                                        <Autocomplete
                                                            size="small"
                                                            ListboxProps={{ style: { maxHeight: 200 } }}
                                                            disablePortal
                                                            id="eventownerteamid"
                                                            options={teams}
                                                            defaultValue={event[0]?.eventownerteamname}
                                                            onChange={(e, v) => handleSelectNewTeam(e, v)}
                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                label="Ansvarlig team"
                                                                name="eventownerteamid"
                                                            />}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Event navn"
                                            name="eventname"
                                            defaultValue={event[0]?.eventname}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label="Lokasjon"
                                            name="eventlocationid"
                                            id="eventlocationid"
                                            defaultValue={event[0]?.eventlocationid}
                                        >
                                            {
                                                locations && locations.map(({ id, label }: any) => {
                                                    return <MenuItem key={"locationid" + id} value={id}>{label}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid container sx={{ mb: 1 }}>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                type="date"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="eventdate"
                                                defaultValue={event[0]?.eventdate}
                                                label="Dato for event"
                                                name="eventdate"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="eventtime"
                                                label="Klokkeslett (00:00:00 format)"
                                                defaultValue={event[0]?.eventtime}
                                                name="eventtime"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                type="datetime"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="eventjoinlimit"
                                                label="Påmeldingsfrist (dd.mm.yyyy hh:mm:ss format)"
                                                defaultValue={event[0]?.eventjoinlimitdisp}
                                                name="eventjoinlimit"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Type event"
                                                name="eventtypeid"
                                                defaultValue={event[0]?.eventtypeid}
                                            >
                                                {
                                                    eventtypes && eventtypes.map(({ eventtypeid, eventtypename }: any) => {
                                                        return <MenuItem key={"eventtypeid" + eventtypeid} value={eventtypeid}>{eventtypename}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Event klasse"
                                                name="eventclassid"
                                                defaultValue={event[0]?.eventclassid}
                                            >
                                                {
                                                    eventclasses && eventclasses.map(({ eventclassid, eventclassname }: any) => {
                                                        return <MenuItem key={"eventclassid" + eventclassid} value={eventclassid}>{eventclassname}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Tilgangsnivå"
                                                name="eventaccesslevelid"
                                                defaultValue={event[0]?.eventaccesslevelid}
                                            >
                                                {
                                                    eventaccesslevels && eventaccesslevels.map(({ eventaccesslevelid, eventaccesslevelname }: any) => {
                                                        return <MenuItem key={"eventaccesslevelid" + eventaccesslevelid} value={eventaccesslevelid}>{eventaccesslevelname}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 1 }}>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Status"
                                                name="eventstatusid"
                                                defaultValue={event[0]?.eventstatusid}
                                            >
                                                {
                                                    eventstatuses && eventstatuses.map(({ eventstatusid, eventstatusname }: any) => {
                                                        return <MenuItem key={"eventstatusid" + eventstatusid} value={eventstatusid}>{eventstatusname}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4} pr={2}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Lagringsdisk"
                                                name="eventstorageid"
                                                defaultValue={event[0]?.eventstorageid}
                                            >
                                                {
                                                    eventstorage && eventstorage.map(({ storageid, storageopen, storagename }: any) => {
                                                        return <MenuItem key={"eventstorage" + storageid} value={storageid}>{storagename + " (" + storageopen + ")"}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                type="number"
                                                size="small"
                                                fullWidth
                                                id="eventjoinslots"
                                                label="Maks deltakere"
                                                name="eventjoinslots"
                                                defaultValue={event[0]?.eventjoinslots}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="eventinfo"
                                            defaultValue={event[0]?.eventinfo}
                                            label="Informasjon"
                                            name="eventinfo"
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Lagre
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleModalEditEventClose}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>



                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={modalEditPublicEvent}
                            onClose={handleModalEditPublicEventClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Endre offentlig informasjon
                            </DialogTitle>
                            <Box component="form" onSubmit={submitEditPublicEvent} noValidate>
                                <DialogContent>

                                    <FormGroup>
                                        {(event[0]?.eventcalendar === 1) ?
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Oppdraget vises i offentlig kalender" name="eventcalendar" />
                                            :
                                            <FormControlLabel control={<Checkbox />} label="Oppdraget vises i offentlig kalender" name="eventcalendar" />
                                        }
                                    </FormGroup>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Offentlig kalender tekst"
                                            name="eventcalendarname"
                                            defaultValue={event[0]?.eventcalendarname}
                                        >
                                        </TextField>
                                    </Grid>
                                    <FormGroup>
                                        {(event[0]?.eventpublic === 1) ?
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Oppdraget er publisert offentlig" name="eventpublic" />
                                            :
                                            <FormControlLabel control={<Checkbox />} label="Oppdraget er publisert offentlig" name="eventpublic" />
                                        }
                                    </FormGroup>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Offentlig event navn"
                                            name="eventnamepublic"
                                            defaultValue={event[0]?.eventnamepublic}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="URL navn (må være unikt)"
                                            name="eventpublicurl"
                                            placeholder="https://www.ghosthunters.no/oppdrag/[NAVN]"
                                            defaultValue={event[0]?.eventpublicurl}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            id="Offentlig informasjon"
                                            defaultValue={event[0]?.eventpublicinfo}
                                            label="Informasjon"
                                            name="eventpublicinfo"
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='pobtnicon' />
                                        Lagre
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleModalEditPublicEventClose}
                                    >
                                        <CloseIcon className='pobtnicon' />
                                        Avbryt
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} p={2}>
                            <Tabs value={TabValue} onChange={handleChangeTab} aria-label="tabs" variant="fullWidth">
                                <Tab label="Informasjon" {...a11yProps(0)} />
                                <Tab label={"Filer (" + event[0]?.eventfiles + ")"} {...a11yProps(1)} />
                                <Tab label={"Bilder (" + event[0]?.eventimages + ")"} {...a11yProps(2)} />
                                <Tab label={"Logger (" + event[0]?.eventlogs + ")"} {...a11yProps(3)} />
                                <Tab label={"Tidslinje"} {...a11yProps(4)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={TabValue} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>
                                                    Event
                                                </span>
                                                <span className='pcardoptions'>
                                                    {((pouserid == event[0]?.eventowneruserid) || (pomod === "1")) &&
                                                        <span>
                                                            <AddPhotoAlternateIcon className='pcardoptionicon' onClick={handleClickLogoModalOpen} sx={{ mr: 1 }} />
                                                            <EditIcon onClick={handleModalEditEventOpen} className='pcardoptionicon' />
                                                        </span>
                                                    }
                                                </span>
                                            </Grid>
                                        </div>
                                        <div className='eventbody'>
                                            <Grid container spacing={2} p={2}>
                                                <Grid item xs={3}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ maxWidth: 500, display: { xs: 'block' }, paddingBottom: 2 }}
                                                        image={"https://poimg01.paraoffice.com/event/" + event[0]?.eventhash + ".png?" + Date.now()}
                                                        alt={event[0]?.eventname}
                                                    />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="h6" color="text.secondary" className='potypeicon' gutterBottom>
                                                            {event[0]?.eventname}
                                                        </Typography>
                                                        <span>
                                                            {((event[0]?.eventjoined === 0) && (event[0]?.investigators.length < event[0]?.eventjoinslots) && (!isDateBeforeToday(new Date(event[0]?.eventjoinlimit)))) &&
                                                                <Button size="small" variant="outlined" color="success" startIcon={<PersonAddAltIcon />} onClick={e => submitJoinEvent(eventid)} sx={{ mr: 2 }}>Bli med</Button>
                                                            }
                                                            {(event[0]?.eventjoined === 1) &&
                                                                <Button size="small" variant="outlined" color="error" startIcon={<PersonRemoveIcon />} onClick={e => submitLeaveEvent(eventid)} sx={{ mr: 2 }}>Avmeld</Button>
                                                            }
                                                        </span>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Dato
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventdatedisp} - {event[0]?.eventtime}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Påmeldingsfrist
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {isDateBeforeToday(new Date(event[0]?.eventjoinlimit)) ?
                                                                    <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>{event[0]?.eventjoinlimitdisp}</Typography>
                                                                    :
                                                                    <Typography variant="subtitle2" className='potypeicon' gutterBottom>{event[0]?.eventjoinlimitdisp}</Typography>
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Påmeldte
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.investigators.length} / {event[0]?.eventjoinslots}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Ansvarlig
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventownerusername}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Ansvarlig Team
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventownerteamname}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Tilgangsnivå
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventaccesslevelname}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Event type
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventtypename}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Event klasse
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventclassname}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Status
                                                            </Typography>
                                                            {((event[0]?.eventstatusid == 1) || (event[0]?.eventstatusid == 2) || (event[0]?.eventstatusid == 3) || (event[0]?.eventstatusid == 4) || (event[0]?.eventstatusid == 5)) &&
                                                                <Typography variant="subtitle2" className='potypeicon poyellow' gutterBottom>
                                                                    {event[0]?.eventstatusname}
                                                                </Typography>
                                                            }
                                                            {((event[0]?.eventstatusid == 6) || (event[0]?.eventstatusid == 8)) &&
                                                                <Typography variant="subtitle2" className='potypeicon pogreen' gutterBottom>
                                                                    {event[0]?.eventstatusname}
                                                                </Typography>
                                                            }
                                                            {(event[0]?.eventstatusid == 7) &&
                                                                <Typography variant="subtitle2" className='potypeicon pored' gutterBottom>
                                                                    {event[0]?.eventstatusname}
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Diskplass
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {disksize(event[0]?.eventfilessize)} ({event[0]?.eventfiles} filer)
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Opptakslengde
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {TimeHHMMSS(event[0]?.eventfileslen)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Bilder
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.eventimages}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            Saksarkiv
                                        </div>
                                        <div className='pcardbody'>
                                            {
                                                event[0]?.archive && event[0]?.archive.map(({ archid, archhash, archref, archdate, archdatedisp, archtitle, archdescription }: any) => {
                                                    return (
                                                        <span key={archid}>
                                                            <Link href={"/archive/" + archhash}>{archref}</Link> ({archdatedisp}) - {archtitle}
                                                        </span>
                                                    )
                                                })
                                            }
                                            {(event[0]?.archive && event[0]?.archive?.length === 0) &&
                                                <span>Ingen saker knyttet til eventet</span>
                                            }
                                        </div>
                                    </div>

                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            Detaljer
                                        </div>
                                        <div className='pcardbody'>
                                            {event[0]?.eventinfo || "Ingen detaljer oppgitt"}
                                        </div>
                                    </div>


                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>
                                                    Publisering
                                                </span>
                                                <span className='pcardoptions'>
                                                    {((pouserid == event[0]?.eventowneruserid) || (pomod === "1")) &&
                                                        <span>
                                                            <EditIcon onClick={handleModalEditPublicEventOpen} className='pcardoptionicon' sx={{ mr: 1 }} />
                                                        </span>
                                                    }
                                                </span>
                                            </Grid>

                                        </div>
                                        <div className='pcardbody'>
                                            {(event[0]?.eventcalendar === 0) ?
                                                <Typography variant="body1" color="text.secondary" gutterBottom>
                                                    <Alert severity="info" variant="outlined">Oppdraget er ikke offentlig i kalenderen</Alert>
                                                </Typography>
                                                :
                                                <Typography variant="body1" color="text.secondary" gutterBottom>
                                                    <Alert severity="success" variant="outlined">Oppdraget er offentlig i kalenderen!<p></p>
                                                        {event[0]?.eventdatedisp} - {event[0]?.eventcalendarname}</Alert>
                                                </Typography>
                                            }
                                            {(event[0]?.eventpublic === 0) ?
                                                <Typography variant="body1" color="text.secondary" gutterBottom>
                                                    <Alert severity="info" variant="outlined">Oppdraget er ikke offentlig publisert</Alert>
                                                </Typography>
                                                :
                                                <Typography variant="body1" color="text.secondary" gutterBottom>
                                                    <Alert severity="error" variant="outlined">Oppdraget er offentlig publisert!<p></p>
                                                        {event[0]?.eventdatedisp} - {event[0]?.eventnamepublic}
                                                        <Card>
                                                            <CardContent>
                                                                {event[0]?.eventpublicinfo}
                                                            </CardContent>
                                                        </Card>
                                                    </Alert>
                                                </Typography>
                                            }
                                        </div>
                                    </div>


                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            Lokasjon
                                        </div>
                                        <div className='pcardbody'>
                                            <Grid container>
                                                <Grid item xs={3} pr={2}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ maxWidth: 500, display: { xs: 'block' }, paddingBottom: 2 }}
                                                        image={"https://poimg01.paraoffice.com/location/" + event[0]?.locationhash + ".png?" + Date.now()}
                                                        alt={event[0]?.eventname}
                                                    />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" color="text.secondary" className='potypeicon' gutterBottom>
                                                                <Link href={"/location/" + event[0]?.locationhash}>{event[0]?.locationname}</Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Adresse
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.locationaddress}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                Postnummer
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.locationzipcode}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                By
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                {event[0]?.locationcity}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>


                                </Grid>

                                <Grid item xs={3}>
                                    <div className='pcard'>
                                        <div className='pcardheader'>
                                            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                Lagring
                                                {(pomod === "1") &&
                                                    <span className='pcardoptions'>
                                                        <EditIcon className='pcardoptionicon' onClick={handleModalEditEventBackupOpen} />
                                                    </span>
                                                }
                                            </Grid>
                                        </div>
                                        <div className='pcardbody'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Lagringsdisk
                                                    </Typography>
                                                    <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                        {event[0]?.eventstoragename}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Backup Server #1
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup1 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup1[0]?.eventstoragebackupname}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN BACKUP]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Harddisk
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup1 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup1[0]?.eventstoragebackupdisk}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN DISK]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Sist verifisert
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup1 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstorageidb1ts}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [IKKE VERIFISERT]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Backup Server #2
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup2 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup2[0]?.eventstoragebackupname}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN BACKUP]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Harddisk
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup2 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup2[0]?.eventstoragebackupdisk}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN DISK]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Sist verifisert
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup2 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstorageidb2ts}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [IKKE VERIFISERT]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Backup Server #3
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup3 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup3[0]?.eventstoragebackupname}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN BACKUP]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Harddisk
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup3 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstoragebackup3[0]?.eventstoragebackupdisk}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [INGEN DISK]
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                        Sist verifisert
                                                    </Typography>
                                                    {(event[0]?.eventstoragebackup3 !== undefined) ?
                                                        <Typography variant="caption" className='potypeicon pocolorinfo' gutterBottom>
                                                            {event[0]?.eventstorageidb3ts}
                                                        </Typography>
                                                        :
                                                        <Typography variant="caption" className='potypeicon pored' gutterBottom>
                                                            [IKKE VERIFISERT]
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>


                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <GroupIcon className='potexticonxl' /> Ekstra team
                                        </Typography>
                                        {((pomod === "1") || (event[0]?.eventowneruserid.toString() === pouserid?.toString())) ?
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    // onClick={handleOpenModalNewTeam}
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                            :
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ width: '100%' }}>

                                            {
                                                event[0]?.teams && event[0]?.teams.map(({ teamid, teamname }: any) => {
                                                    return (
                                                        <span>
                                                            {
                                                                (teamid !== event[0]?.eventownerteamid) &&
                                                                <span className='poteambox'>{teamname}</span>
                                                            }
                                                        </span>
                                                    )
                                                })
                                            }

                                        </Grid>
                                    </Grid>




                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <PersonIcon className='potexticonxl' /> Deltakere ({event[0]?.investigators.length}/{event[0]?.eventjoinslots})
                                        </Typography>
                                        {((pomod === "1") || (event[0]?.eventowneruserid.toString() === pouserid?.toString())) ?
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    onClick={handleOpenModalNewInvestigator}
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                            :
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ width: '100%' }}>
                                            {
                                                event[0]?.investigators && event[0]?.investigators.sort((a: any, b: any) => b.revtime - a.revtime).map(({ userid, userhash, username, revtime }: any) => {
                                                    const perc = Number(((revtime / event[0]?.eventfileslen) * 100).toFixed(0));
                                                    return (
                                                        <Box className="pobox">
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ maxHeight: 100, display: { xs: 'block' } }}
                                                                        image={"https://poimg01.paraoffice.com/profile/" + userhash + ".png?" + Date.now()}
                                                                        alt={username}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8} pl={2} pt={1}>
                                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Typography variant="body2" color="text.secondary" className='potypeicon'>
                                                                            {username}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" className='potypeicon' sx={{ pr: 1 }}>
                                                                            {(perc > 25) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' />
                                                                                </Typography>
                                                                            }
                                                                            {((perc >= 20) && (perc < 25)) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' />
                                                                                </Typography>
                                                                            }
                                                                            {((perc >= 15) && (perc < 20)) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' />
                                                                                </Typography>
                                                                            }
                                                                            {((perc >= 10) && (perc < 15)) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon className='poiconnew' /> <StarIcon className='poiconnew' />
                                                                                </Typography>
                                                                            }
                                                                            {((perc >= 5) && (perc < 10)) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon className='poiconnew' />
                                                                                </Typography>
                                                                            }
                                                                            {((perc > 0) && (perc < 5)) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <StarIcon />
                                                                                </Typography>
                                                                            }
                                                                            {(perc == 0) &&
                                                                                <Typography variant="body2" className='potypeicon'>
                                                                                    <HotelIcon className='pored' />
                                                                                </Typography>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Typography variant="body2" color="text.secondary" className='potypeicon'>
                                                                        {TimeHHMMSS(revtime)} ({perc}%)
                                                                    </Typography>
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>


                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <GroupIcon className='potexticonxl' /> Eksterne deltakere
                                        </Typography>
                                        {((pomod === "1") || (event[0]?.eventowneruserid.toString() === pouserid?.toString())) ?
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    onClick={handleOpenModalNewContact}
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                            :
                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="close"
                                                >
                                                    <PersonAdd />
                                                </IconButton>
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ width: '100%' }}>
                                            {
                                                event[0]?.contacts && event[0]?.contacts.map(({ contactid, contacthash, contactname }: any) => {
                                                    return (
                                                        <Card key={"u" + contactid} variant="outlined" sx={{ mb: 1 }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={2}>
                                                                    <CardContent>
                                                                        <CardMedia
                                                                            component="img"
                                                                            sx={{ maxHeight: 50, display: { xs: 'block' }, paddingBottom: 2 }}
                                                                            image={"https://poimg01.paraoffice.com/contacts/" + contacthash + "_p.jpg?" + Date.now()}
                                                                            alt={contactname}
                                                                        />
                                                                    </CardContent>
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Grid item>
                                                                            <Typography>
                                                                                {contactname}
                                                                            </Typography>
                                                                            <Typography>
                                                                                {((pomod === "1") || (event[0]?.eventowneruserid.toString() === pouserid?.toString())) &&
                                                                                    <IconButton color="error" aria-label="Slett" className='pochipoption' onClick={e => submitWipeEventContact(contactid)}>
                                                                                        <DeleteForever />
                                                                                    </IconButton>
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </Card>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={TabValue} index={1}>
                            <Grid container spacing={2} p={2}>
                                <Grid item xs={2} md={3}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                            <FolderIcon className='potexticonxl' /> Utstyr og Mapper
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <IconButton color="primary" aria-label="Legg til utstyr" onClick={handleOpenModalNewEquipment}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                    <Card variant="outlined" sx={{ marginBottom: 2 }}>
                                        <CardContent>
                                            {
                                                devfolders && devfolders.map(({ trid, trname, trfilessize }: any, index: number) => {
                                                    return <div>
                                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                                <CameraIcon className='potexticonxl' /> {trname} [{DiskSize(trfilessize)}]
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <IconButton color="primary" aria-label="Legg til utstyr" onClick={e => handleOpenModalNewEquipmentFolder(trid)}>
                                                                    <AddCircleIcon />
                                                                </IconButton>
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                            devfolders[index]?.folders && devfolders[index]?.folders.map(({ effid, effilesnew, effilesnewlen, effiles, effilestotallen, effname }: any, index: number) => {
                                                                const efstat = effilestotallen - effilesnewlen;
                                                                return <div><Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                                    <Typography color="text.secondary" className='potypeicon' sx={{ ml: 3 }}>
                                                                        <Badge badgeContent={effiles} color="info">
                                                                            <FolderIcon className='potexticonfolder' />
                                                                        </Badge>
                                                                        <Link href='#' underline="hover" onClick={e => handleViewFolderFiles(effid)} sx={{ ml: 2 }}>{effname}</Link>
                                                                    </Typography>
                                                                </Grid>
                                                                    <Grid xs={12} sx={{ ml: 8 }}>
                                                                        {((effilesnew === null) || (effilesnew === 0)) ?
                                                                            <LinearProgressWithLabel color="success" value={100} />
                                                                            :
                                                                            <LinearProgressWithLabel color="warning" value={Percentage(efstat, effilestotallen)} />
                                                                        }
                                                                    </Grid>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                        </CardContent>
                                    </Card>
                                    {/* {(activefoldereqname !== 0) &&
                                <Dropzone
                                    getUploadParams={getFileUploadParams}
                                    onChangeStatus={handleFileChangeStatus}
                                    LayoutComponent={Layout}
                                    onSubmit={handleFileSubmit}
                                    inputContent="Last opp"
                                />
                            } */}
                                </Grid>
                                <Grid item xs={10} md={9}>
                                    <Typography color="text.secondary" className='potypeicon' sx={{ mb: 1 }}>
                                        {(activefoldereqname !== 0) &&
                                            <span>
                                                <FolderIcon className='potexticonfolder' /> {activefoldereqname} - {activefoldername}
                                                <IconButton color="primary" aria-label="Legg til filer" onClick={e => handleOpenEventFileUploadModal()}>
                                                    <CloudUploadIcon className='iconsmall' />
                                                </IconButton>
                                            </span>
                                        }
                                    </Typography>
                                    {folderfiles && folderfiles.map(({ efid, efhash, effilets, effiletypeid, efcategoryid, effiletsdisp, effilename, effiledescription, efreviewuserid, effilesize, efreviewstatusid, efreviewstatusname, effilelength, efreviewusername, filecategory, effilesha1, effileshaverified, efrecusername, eventfilelogs, eventfileclips }: any, index: number) => {
                                        if (!efreviewuserid) { efreviewuserid = 0; }
                                        if (!eventfilelogs) { eventfilelogs = 0; }
                                        if (!eventfileclips) { eventfileclips = 0; }

                                        var fileclass = 'carddanger';

                                        if ((efreviewuserid !== 0) && (effiledescription) && (effilets) && (folderfiles[index]?.areas[0]) && ((efrecusername) || ((efcategoryid == 9) || (efcategoryid == 10)))) {
                                            fileclass = 'cardok';
                                        }



                                        return <div>
                                            <Card sx={{ mb: 1 }} variant="outlined">
                                                <Grid container className={fileclass}>
                                                    <Grid item xs={2}>
                                                        {(effiletypeid !== 2) ?
                                                            <CardMedia
                                                                component="img"
                                                                image={"https://poimg01.paraoffice.com/events/" + eventhash + "/filethumbs/" + efhash + ".jpg"}
                                                                onMouseOver={e => (e.currentTarget.src = "https://poimg01.paraoffice.com/events/" + eventhash + "/filethumbs/" + efhash + ".gif")}
                                                                onMouseOut={e => (e.currentTarget.src = "https://poimg01.paraoffice.com/events/" + eventhash + "/filethumbs/" + efhash + ".jpg")}
                                                                alt="random"
                                                                sx={{ pr: 2 }}
                                                            />
                                                            :
                                                            <CardMedia
                                                                component="img"
                                                                image={"/images/audio.png"}
                                                                alt="Audio"
                                                                sx={{ pr: 2 }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                                <Link href={"/eventfile/" + efhash} underline="hover">{effilename}</Link> &nbsp;-&nbsp;
                                                                {(effiledescription) ?
                                                                    effiledescription
                                                                    :
                                                                    <span className="text-danger">[Filbeskrivelse mangler]</span>
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                                <span className='logareabox'><StorageIcon className='pobody1icon' /> {DiskSize(effilesize)}</span>
                                                                <span className='logareabox'><AccessTimeIcon className='pobody1icon' /> {SecondsToHHMMSS(effilelength)}</span>
                                                                <span className='logareabox'><FingerprintIcon className='pobody1icon' /> {effilesha1} &nbsp;
                                                                    {(effileshaverified) ?
                                                                        <VerifiedIcon className='pobody1icon text-success' />
                                                                        :
                                                                        <NotificationImportantIcon className='pobody1icon text-warning' />
                                                                    }
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                                            <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                {(efrecusername) ?
                                                                    <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                        <PhotoCameraFrontIcon className='pobody1icon' /> {efrecusername}
                                                                    </Typography>
                                                                    :
                                                                    ((efcategoryid != 9) && (efcategoryid != 10)) ?
                                                                        <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                            <PhotoCameraFrontIcon className='pobody1icon' /><span className='text-danger'> [Ansvarlig mangler]</span>
                                                                        </Typography>
                                                                        :
                                                                        <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                            <PhotoCameraFrontIcon className='pobody1icon' /><span className='text-success'> [system]</span>
                                                                        </Typography>
                                                                }
                                                                &nbsp; &nbsp;
                                                                {(effilets) ?
                                                                    <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                        <AccessTimeIcon className='pobody1icon' /> {effiletsdisp}
                                                                    </Typography>
                                                                    :
                                                                    <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                        <AccessTimeIcon className='pobody1icon' /> <span className='text-danger'> [Dato/Tid mangler] {(pomod === "1") && <CloudSyncIcon color="primary" onClick={e => submitDateSync(efid)} className='pobody1icon' />}</span>
                                                                    </Typography>
                                                                }
                                                                &nbsp; &nbsp;
                                                                <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                                    <Category className='pobody1icon' /> {filecategory}
                                                                </Typography>

                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
                                                                {(!efreviewuserid) ?
                                                                    <Button size="small" variant="outlined" onClick={e => submitAssignFile(efid)} sx={{ mr: 1 }}><AccessibilityNewIcon className='btniconsmall' /> Reserver</Button>
                                                                    :
                                                                    (efreviewuserid?.toString() !== pouserid?.toString()) &&
                                                                    <Button size="small" variant="outlined" disabled sx={{ mr: 1 }}><AccessibilityNewIcon className='btniconsmall' /> Reserver</Button>
                                                                }
                                                                {(!efreviewstatusid) && (efreviewuserid?.toString() === pouserid?.toString()) &&
                                                                    <Button size="small" variant="outlined" color="warning" onClick={e => submitUnAssignFile(efid)} sx={{ mr: 1 }}><AccessibilityNewIcon className='btniconsmall' /> Fjern Reservasjon</Button>
                                                                }
                                                                <Button size="small" variant="outlined" href={"https://pofs01.paraoffice.com/geteventfile.php?i=" + efhash + "&t=" + potoken}><DownloadIcon className='btniconsmall' /> Last ned</Button>
                                                            </Typography>
                                                        </Grid>
                                                        <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                            {(efreviewstatusid === 0) &&
                                                                <FiberNewIcon className='pobody1icon text-danger' />
                                                            }
                                                            {(efreviewstatusid === 1) &&
                                                                <PendingIcon className='pobody1icon text-warning' />
                                                            }
                                                            {(efreviewstatusid === 2) &&
                                                                <CheckIcon className='pobody1icon text-success' />
                                                            }
                                                            {efreviewstatusname} &nbsp; <PersonIcon className='pobody1icon' /> {efreviewusername} &nbsp;
                                                            <AssignmentIcon className='pobody1icon' /> {eventfilelogs} &nbsp;
                                                            <ContentCutIcon className='pobody1icon' /> {eventfileclips}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" className='potypeicon' gutterBottom>
                                                            <GroupIcon className='pobody1icon' />
                                                            {
                                                                folderfiles[index]?.investigators && folderfiles[index]?.investigators.map(({ userid, username }: any, index: number) => {
                                                                    return <span key={"user" + userid} className='potagbox'>{username}</span>
                                                                })
                                                            }
                                                            <MeetingRoomIcon className='pobody1icon' />
                                                            {
                                                                folderfiles[index]?.areas && folderfiles[index]?.areas.map(({ laid, laname }: any, index: number) => {
                                                                    return <span key={"area" + laid} className='potagbox'>{laname}</span>
                                                                })
                                                            }
                                                        </Typography>

                                                    </Grid>
                                                </Grid>
                                            </Card>

                                        </div>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={TabValue} index={2}>
                            <Grid container spacing={2} p={2}>
                                <Grid item xs={2}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography color="text.secondary" className='potypeicon' sx={{ mb: 1 }}>
                                            <FolderIcon className='potexticonfolder' /> Bildemapper
                                        </Typography>
                                        <IconButton color="primary" aria-label="Legg til utstyr" onClick={handleOpenModalNewImageFolder}>
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Grid>
                                    <Card variant="outlined">
                                        <CardContent>
                                            {imagefolders && imagefolders.map(({ eifid, eifhash, eifname, images }: any, index: number) => {
                                                return <div key={"if" + index}><Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    <Typography color="text.secondary" className='potypeicon' sx={{ ml: 3 }}>
                                                        <Badge badgeContent={images} color="info">
                                                            <FolderIcon className='potexticonfolder' />
                                                        </Badge>
                                                        <Link href='#' underline="hover" onClick={e => getImagesFromFolderName(eifhash, eifname)} sx={{ ml: 2 }}>{eifname}</Link>
                                                    </Typography>
                                                    {/* <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <IconButton color="primary" aria-label="Legg til filer" onClick={e => handleOpenModalNewImages(eifid, eifhash)}>
                                                    <CloudUploadIcon className='iconsmall' />
                                                </IconButton>
                                            </Typography> */}
                                                </Grid>
                                                </div>
                                            }
                                            )}
                                        </CardContent>
                                    </Card>


                                </Grid>
                                <Grid item xs={10}>
                                    {(activeeventimagefoldername !== "") &&
                                        <div>
                                            <Typography color="text.secondary" className='potypeicon' sx={{ mb: 1 }}>
                                                <FolderIcon className='potexticonfolder' /> {activeeventimagefoldername}
                                                <IconButton color="primary" aria-label="Legg til filer" onClick={e => handleOpenModalImageUpload()}>
                                                    <CloudUploadIcon className='iconsmall' />
                                                </IconButton>
                                            </Typography>
                                        </div>

                                    }
                                    {eventimages &&
                                        <div>
                                            <Gallery
                                                images={eventimages}
                                                onClick={handleClick}
                                                enableImageSelection={false}
                                            />
                                            {!!currentImage && (
                                                /* @ts-ignore */
                                                <Lightbox
                                                    mainSrc={currentImage.original}
                                                    imageTitle={currentImage.caption}
                                                    mainSrcThumbnail={currentImage.src}
                                                    nextSrc={nextImage.original}
                                                    nextSrcThumbnail={nextImage.src}
                                                    prevSrc={prevImage.original}
                                                    prevSrcThumbnail={prevImage.src}
                                                    onCloseRequest={handleClose}
                                                    onMovePrevRequest={handleMovePrev}
                                                    onMoveNextRequest={handleMoveNext}
                                                />
                                            )}
                                        </div>
                                    }

                                </Grid>
                            </Grid>
                        </TabPanel>



                        <TabPanel value={TabValue} index={3}>
                            <Grid container spacing={2} p={2}>
                                <Grid item xs={8}>
                                    {eventfilelogs && eventfilelogs.map(({ eflid, efhash, eflhash, eflloguser, effiletypeid, efllogcategory, effilename, efluserid, eflclippublic, eflclipts, eflclip, efllogcategoryid, efltime, efldescription, efldescriptionpublic }: any, index: number) => {
                                        var cardpub = '';
                                        if (eflclippublic === 1) { cardpub = 'CardLogPublic'; }
                                        return <Card key={"loglist" + eflid} className={"CardLog " + cardpub} variant="outlined" sx={{ pb: 1, mb: 1 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                            {(efllogcategoryid === 1) && <StarIcon className='logicon loga' />}
                                                            {(efllogcategoryid === 2) && <StarIcon className='logicon logb' />}
                                                            {(efllogcategoryid === 3) && <StarIcon className='logicon logc' />}
                                                            {(efllogcategoryid === 4) && <InfoIcon className='logicon loginfo' />}
                                                            {(efllogcategoryid === 5) && <CelebrationIcon className='logicon logfun' />}
                                                            {(efllogcategoryid === 6) && <PersonIcon className='logicon' />}
                                                            {(efllogcategoryid === 7) && <PersonIcon className='logicon' />}
                                                            {(efllogcategoryid === 8) && <PersonIcon className='logicon' />}
                                                            {(efllogcategoryid === 9) && <PersonIcon className='logicon' />}
                                                            {(efllogcategoryid === 10) && <PhotoCameraFrontIcon className='logicon' />}
                                                            {(efllogcategoryid === 11) && <MoodIcon className='logicon' />}
                                                            <Link variant="body2" href={"/eventfile/" + efhash} underline="hover" sx={{ mr: 1 }}>{effilename}</Link> {efldescription}
                                                        </Typography>
                                                        <span>
                                                            {((eflclip === 1) && (eflclipts != null)) &&
                                                                <span>
                                                                    <IconButton color="primary" aria-label="Se klipp">
                                                                        <PlayCircleOutlineIcon onClick={e => handleOpenModalViewClip(eflhash, effiletypeid, effilename, efldescription)} />
                                                                    </IconButton>

                                                                    <IconButton color="primary" aria-label="Se klipp">
                                                                        <ShareIcon onClick={e => handleOpenModalShareClip(eflhash, effiletypeid, effilename, efldescription)} />
                                                                    </IconButton>
                                                                </span>
                                                            }
                                                        </span>
                                                    </Grid>
                                                    {(eflclippublic === 1) &&
                                                        <Alert severity="info">{efldescriptionpublic}</Alert>
                                                    }
                                                    <Typography variant="body2" color="text.secondary" className='logextras' sx={{ ml: 3, flex: 1, flexWrap: 'wrap' }}>
                                                        <TypeSpecimenIcon className='logextrasicon logicon' /> <span className='logareabox'>{efllogcategory}</span>
                                                        <HomeIcon className='logextrasicon logicon' />
                                                        {eventfilelogs[index]?.areas && eventfilelogs[index].areas.map(({ laid, laname }: any, index: number) => {
                                                            return <span key={eflid + "area" + eflid + "" + laid} className='logareabox'>{laname}
                                                            </span>
                                                        })
                                                        }
                                                        <PersonIcon className='logextrasicon logicon' />
                                                        {eventfilelogs[index]?.investigators && eventfilelogs[index].investigators.map(({ userid, username }: any, index: number) => {
                                                            return <span key={eflid + "loguser" + eflid + "" + userid} className='logareabox'>{username}
                                                            </span>
                                                        })
                                                        }
                                                        <TagIcon className='logextrasicon logicon' />
                                                        {eventfilelogs[index]?.tags && eventfilelogs[index].tags.map(({ ltid, ltname }: any, index: number) => {
                                                            return <span key={eflid + "logtag" + eflid + "" + ltid} className='logareabox'>#{ltname}
                                                            </span>
                                                        })
                                                        }
                                                        <AdminPanelSettingsIcon className='logextrasicon logicon' /> <span className='logareabox'>{eflloguser}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    })
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="efllogcategoryid"
                                                    options={eventfilelogtypes}
                                                    onChange={(e, v) => handleSelectFilterLogType(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Logg klasse"
                                                        name="efllogcategoryid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="areaid"
                                                    options={logAreas}
                                                    onChange={(e, v) => handleSelectFilterAreaId(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Område"
                                                        name="areaid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="filteruserid"
                                                    options={logUsers}
                                                    onChange={(e, v) => handleSelectFilterUser(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Person"
                                                        name="filteruserid"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <Autocomplete
                                                    fullWidth
                                                    id="checkboxes-logtags"
                                                    options={logTags}
                                                    onChange={(e, v) => handleSelectFilterTags(e, v)}
                                                    groupBy={(option) => option.taggroup}
                                                    getOptionLabel={(option) => option.label}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            {option.label}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Tags" />
                                                    )}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={TabValue} index={4}>

                        </TabPanel>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Dialog
                            fullScreen
                            open={isUploadingImages}
                        >
                            {
                                uploadimages && uploadimages.map(({ id, name, size, percent }: any) => {
                                    return <div key={"imgup" + id}>
                                        <Typography variant="h6">{name + " (" + size + ")"}</Typography>
                                        <LinearProgressWithLabel color="warning" value={percent} />
                                    </div>
                                })
                            }
                        </Dialog>
                        <Dialog
                            fullScreen
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isUploadingDropzone}
                        >
                            {
                                dropupload && dropupload.map(({ id, name, size, percent }: any) => {
                                    return <div key={"imgup" + id}>
                                        <Typography variant="h6">{name + " (" + size + ")"}</Typography>
                                        <LinearProgressWithLabel color="warning" value={percent} />
                                    </div>
                                })
                            }
                        </Dialog>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default Event;