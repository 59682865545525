import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Link, MenuItem, Snackbar, Switch, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PlaceIcon from '@mui/icons-material/Place';
import HomeIcon from '@mui/icons-material/Home';
import CakeIcon from '@mui/icons-material/Cake';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DiskSize from '../components/DiskSize';
import SecondsToHHMMSS from '../components/SecondsToHHMMSS';
import TimeHHMMSS from '../components/TimeHHMMSS';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../components/canvaspreview';
import { useDebounceEffect } from '../components/useDebounceEffect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';


function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const APIURL = process.env.REACT_APP_APIURL;
const APIFSURL = process.env.REACT_APP_APIFSURL;

const potoken = localStorage.getItem('potoken') || "";

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};






interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Contact() {
    const { contacthash } = useParams();
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(1 / 1);
    const [contactAccessId, setContactAccessId] = useState(null);
    const [contactAccessName, setContactAccessName] = useState("");


    

    const handleClickImageModalOpen = () => {
        setImageModal(true);
    };

    const handleClickImageModalClose = () => {
        setImageModal(false);
    };

    const [NewLocationId, setNewLocationId] = useState(0);

    const handleAddContactSelectLocation = (event: any, values: any) => {
        setNewLocationId(values['id']);
        console.log(values['id']);
    }



    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        setisLoading(true);
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const formData = new FormData();
            formData.append("token", potoken);
            formData.append("contacthash", contacthash || "");
            formData.append("file[]", blob);
            await axios.post("https://poimg01.paraoffice.com/contactimageupload.php", formData, { headers: { 'Content-Type': blob.type } })
                .then((response: any) => {
                    if (response.status === 200) {
                        getcontact();
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setisLoading(false);
                });
        })
        setImageModal(false);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )



    const [imageModal, setImageModal] = useState(false);
    const [EditContactModal, setEditContactModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [confirmLeaveTeam, setconfirmLeaveTeam] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [MyAssignedFiles, setMyAssignedFiles] = useState<any[]>([]);

    const [contactAccessList, setContactAccessList] = useState<any[]>([]);


    const handleOpenModalEditContact = () => {
        setEditContactModal(true);
    };

    const handleCloseModalEditContact = () => {
        setEditContactModal(false);
    };


    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function getAccess() {
        axios.get(APIURL + "/v4/contactaccess/options", { withCredentials: true })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setContactAccessList(response.data);
                }
                else {
                    console.log(response);
                }
            })
            .catch((e: any) => {
                if (e.response!.status === 403) {
                    window.location.assign("/logout");
                }
                console.log(e);
            });
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }



    const [isLoading, setisLoading] = useState(true);
    const [contact, setContact] = useState<any[]>([]);
    const [modalWipeContact, setmodalWipeContact] = useState(false);
    const [AddContactLocationModal, setAddContactLocationModal] = useState(false);

    const [locations, setLocations] = useState<any[]>([]);


    async function getcontact() {
        await axios.get(APIURL + "/v3/contact/" + contacthash, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setContact(response.data);
                    setContactAccessId(response.data[0].contactaccessid);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }


    async function getlocations() {
        await axios.get(APIURL + "/locationoptions", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setLocations(response.data);
            })
    }

    const submitWipeContact = () => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/contact/" + contacthash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    window.location.assign("/contacts");
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }


    const handleSubmitEditContact = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEditContactModal(false);
        setisLoading(true);
        var data = new FormData(e.currentTarget);
        var formdata = {
            contactname: data.get('contactname'),
            contacttitle: data.get('contacttitle'),
            contactbirthday: data.get('contactbirthday'),
            contactemail: data.get('contactemail'),
            contactphone: data.get('contactphone'),
            contactcell: data.get('contactcell'),
            contactaddress: data.get('contactaddress'),
            contactzip: data.get('contactzip'),
            contactcity: data.get('contactcity'),
            contactgeolat: data.get('contactgeolat'),
            contactgeolong: data.get('contactgeolong'),
            contactfacebook: data.get('contactfacebook'),
            contactinstagram: data.get('contactinstagram'),
            contactnick: data.get('contactnick'),
            contactdiscordid: data.get('contactdiscordid'),
            contactnote: data.get('contactnote'),
            contactaccessid: contactAccessId
        };
        axios.put(APIURL + "/v3/contact/" + contacthash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    getcontact();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }


    const handleSubmitContactNewLocation = () => {
        setisLoading(true);
        setAddContactLocationModal(false);
        var formdata = {
            locationid: NewLocationId,
        };
        axios.post(APIURL + "/v3/contactlocation/" + contacthash, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setisLoading(false);
                    getcontact();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    const submitWipeContactLocation = (locationhash: string) => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/contactlocation/" + contacthash + "/" + locationhash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getcontact();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const handleOpenModalWipeContact = async () => {
        setmodalWipeContact(true);
    };
    const handleCloseModalWipeContact = () => {
        setmodalWipeContact(false);
    };

    const handleOpenAddContactLocationModal = async () => {
        setAddContactLocationModal(true);
        getlocations();
    };
    const handleCloseAddContactLocationModal = () => {
        setAddContactLocationModal(false);
    };

    useEffect(() => {
        document.title = 'ParaOffice';
        getcontact();
        getAccess();
    }, []);

    // const handleSubmitContact = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     handleClickContactModalClose();
    //     var data = new FormData(event.currentTarget);
    //     var formdata = {
    //         userfullname: data.get('userfullname'),
    //     };
    //     axios.put(APIURL + "/contact/" + contacthash, formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
    //         .then((response: any) => {
    //             if (response.status === 200) {

    //                 setContact(response.data);
    //             }
    //             else {
    //             }
    //         })
    //         .catch((e: Error) => {
    //         });
    // }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={modalWipeContact}
                    onClose={handleCloseModalWipeContact}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Slett kontaktperson
                    </DialogTitle>
                    <Box component="form" onSubmit={submitWipeContact}>
                        <DialogContent>
                            Er du sikker på at du vil slette denne kontaktpersonen?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Bekreft
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalWipeContact}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    open={imageModal}
                    onClose={handleClickImageModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box>
                        <DialogTitle id="alert-dialog-title">
                            Last opp nytt bilde
                        </DialogTitle>
                        <DialogContent>


                            <input type="file" accept="image/*" onChange={onSelectFile} />
                            {!!imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                            {!!completedCrop && (
                                <>
                                    <div>
                                        <Typography>
                                            Forhåndsvisning:
                                        </Typography>
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                                border: '1px solid black',
                                                objectFit: 'contain',
                                                width: 350,
                                                height: 350,
                                            }}
                                        />
                                    </div>
                                </>
                            )}


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onDownloadCropClick}
                                size="small"
                                variant="outlined"
                                color="success"
                            >Last opp</Button>
                            <Button onClick={handleClickImageModalClose}
                                size="small"
                                variant="outlined"
                                color="error"
                            >Avbryt</Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        {!isLoading ?
                            <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                {/* <Snackbar open={profileUpdated} autoHideDuration={6000} onClose={handleCloseProfileUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <Alert onClose={handleCloseProfileUpdated} severity="success" sx={{ width: '100%' }}>
                                        Din profil ble oppdatert!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={passwordUpdated} autoHideDuration={6000} onClose={handleClosePasswordUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <Alert onClose={handleClosePasswordUpdated} severity="success" sx={{ width: '100%' }}>
                                        Passordet ditt ble oppdatert!
                                    </Alert>
                                </Snackbar> */}
                                <main>
                                    <Dialog
                                        fullWidth={true}
                                        maxWidth="lg"
                                        open={EditContactModal}
                                        onClose={handleCloseModalEditContact}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Endre kontaktperson
                                        </DialogTitle>
                                        <Box component="form" onSubmit={handleSubmitEditContact} noValidate>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                id="contactname"
                                                                label="Fullt navn"
                                                                name="contactname"
                                                                defaultValue={contact[0]?.contactname}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                id="contacttitle"
                                                                label="Tittel"
                                                                name="contacttitle"
                                                                defaultValue={contact[0]?.contacttitle}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                type="date"
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactbirthday"
                                                                label="Fødselsdato"
                                                                id="contactbirthday"
                                                                defaultValue={contact[0]?.contactbirthdaysql}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactemail"
                                                                label="E-post"
                                                                id="contactemail"
                                                                defaultValue={contact[0]?.contactemail}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactcell"
                                                                label="Mobiltelefon"
                                                                id="contactcell"
                                                                defaultValue={contact[0]?.contactcell}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactphone"
                                                                label="Telefon"
                                                                id="contactphone"
                                                                defaultValue={contact[0]?.contactphone}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactaddress"
                                                                label="Adresse"
                                                                id="contactaddress"
                                                                defaultValue={contact[0]?.contactaddress}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactzip"
                                                                label="Postnummer"
                                                                id="contactzip"
                                                                defaultValue={contact[0]?.contactzip}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactcity"
                                                                label="Sted"
                                                                id="contactcity"
                                                                defaultValue={contact[0]?.contactcity}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactgeolat"
                                                                label="Geolokasjon Latitude"
                                                                id="contactgeolat"
                                                                defaultValue={contact[0]?.contactgeolat}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactgeolong"
                                                                label="GeoLokasjon Longitude"
                                                                id="contactgeolong"
                                                                defaultValue={contact[0]?.contactgeolong}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactfacebook"
                                                                label="Facebook brukernavn"
                                                                id="contactfacebook"
                                                                defaultValue={contact[0]?.contactfacebook}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactinstagram"
                                                                label="Instagram brukernavn"
                                                                id="contactinstagram"
                                                                defaultValue={contact[0]?.contactinstagram}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactnick"
                                                                label="Kallenavn"
                                                                id="contactnick"
                                                                defaultValue={contact[0]?.contactnick}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactdiscordid"
                                                                label="Discord #ID"
                                                                id="contactdiscordid"
                                                                defaultValue={contact[0]?.contactdiscordid}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} pt={1}>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                fullWidth
                                                                value={(contactAccessList.find(contactAccessList => contactAccessList.id === contact[0]?.contactaccessid) || null)}
                                                                onChange={(event, newValue) => {
                                                                    setContactAccessName(newValue.label);
                                                                    setContactAccessId(newValue.id);
                                                                }}
                                                                ListboxProps={{ style: { maxHeight: 300 } }}
                                                                options={contactAccessList}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} size="small" label="Tilgangsnivå" />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                multiline
                                                                rows="2"
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                name="contactnote"
                                                                label="Notat"
                                                                id="contactnote"
                                                                defaultValue={contact[0]?.contactnote}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SaveIcon className='pobtnicon' />
                                                    Lagre
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleCloseModalEditContact}
                                                >
                                                    <CloseIcon className='pobtnicon' />
                                                    Avbryt
                                                </Button>
                                            </DialogActions>
                                        </Box>
                                    </Dialog>



                                    <Dialog
                                        fullWidth={true}
                                        maxWidth="lg"
                                        open={AddContactLocationModal}
                                        onClose={handleCloseAddContactLocationModal}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Legg til relevant lokasjon på kontaktperson
                                        </DialogTitle>
                                        <Box component="form" onSubmit={handleSubmitContactNewLocation}>
                                            <DialogContent style={{ height: '250px' }}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 200 } }}
                                                    disablePortal
                                                    id="eventlocationid"
                                                    options={locations}
                                                    onChange={(e, v) => handleAddContactSelectLocation(e, v)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Lokasjon"
                                                        name="eventlocationid"
                                                    />}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SaveIcon className='pobtnicon' />
                                                    Lagre
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleCloseAddContactLocationModal}
                                                >
                                                    <CloseIcon className='pobtnicon' />
                                                    Avbryt
                                                </Button>
                                            </DialogActions>
                                        </Box>
                                    </Dialog>


                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Card variant="outlined" sx={{ marginBottom: 2 }}>
                                                <Grid container spacing={2} p={2}>
                                                    <Grid item xs={3}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 250, display: { xs: 'block' }, paddingBottom: 2 }}
                                                            image={"https://poimg01.paraoffice.com/contact/" + contacthash + ".png?" + Date.now()}
                                                            alt={contact[0]?.contactname}
                                                        />
                                                        <Button variant="outlined" size="small" color="warning" onClick={handleClickImageModalOpen}><EditIcon className='pobtnicon' /> Endre bilde</Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Grid container>
                                                            <Grid item xs={8}>
                                                                <Grid container>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Fullt navn
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactname) ?
                                                                                contact[0].contactname
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Tittel
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contacttitle) ?
                                                                                contact[0].contacttitle
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Fødselsdato
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactbirthday) ?
                                                                                contact[0].contactbirthday
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            E-post
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactemail) ?
                                                                                contact[0].contactemail
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Mobiltelefon
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactcell) ?
                                                                                contact[0].contactcell
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Telefon
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactphone) ?
                                                                                contact[0].contactphone
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Adresse
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactaddress) ?
                                                                                contact[0].contactaddress
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Postnummer
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactzip) ?
                                                                                contact[0].contactzip
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Sted
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactcity) ?
                                                                                contact[0].contactcity
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            GeoLokasjon Latitude
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactgeolat) ?
                                                                                contact[0].contactgeolat
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            GeoLokasjon Longitude
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactgeolong) ?
                                                                                contact[0].contactgeolong
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Facebook
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactfacebook) ?
                                                                                contact[0].contactfacebook
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Instagram
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactinstagram) ?
                                                                                contact[0].contactinstagram
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Kallenavn
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactnick) ?
                                                                                contact[0].contactnick
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Discord ID
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactdiscordid) ?
                                                                                contact[0].contactdiscordid
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }

                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Lagt til av team
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {(contact[0]?.contactteamname) ?
                                                                                contact[0].contactteamname
                                                                                :
                                                                                <span className='potextmissing'>(mangler)</span>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Tilgangsnivå
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                            {contact[0]?.contactaccessname}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                            Notat
                                                                        </Typography>
                                                                        <Box className="pobox" p={1} mb={5}>
                                                                            {(contact[0]?.contactnote) ?
                                                                                contact[0].contactnote
                                                                                :
                                                                                <span className='potextmissing'><i>ingen notat</i></span>
                                                                            }
                                                                        </Box>
                                                                        <Button variant="outlined" size="small" color="primary" onClick={e => handleOpenModalEditContact()}><EditIcon className='pobtnicon' /> Endre opplysninger</Button>
                                                                        <Button variant="outlined" size="small" color="error" onClick={e => handleOpenModalWipeContact()} sx={{ ml: 1 }}><DeleteForeverIcon className='pobtnicon' /> Slett kontaktperson</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Box className='pobox' p={1}>
                                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Typography variant="overline" color="text.secondary" gutterBottom className='potypeicon'>
                                                                            <LocationOnIcon className='potextic potexticred' /> Tilknyttede lokasjoner
                                                                        </Typography>
                                                                        <IconButton color="primary" aria-label="Legg til event" onClick={handleOpenAddContactLocationModal}>
                                                                            <AddCircleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    {
                                                                        contact[0]?.locations && contact[0]?.locations.map(({ locationhash, locationname }: any) => {
                                                                            return <Box className='pobox' p={1}>
                                                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <Typography variant="overline" color="text.secondary" gutterBottom className='potypeicon'>
                                                                                        {locationname}
                                                                                    </Typography>
                                                                                    <IconButton color="error" aria-label="Legg til event" onClick={e => submitWipeContactLocation(locationhash)}>
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Box>
                                                                        })
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={9}>
                                                            <Typography variant="body1" gutterBottom>
                                                                {profile[0]?.userfullname}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <CakeIcon className='potexticon' /> {profile[0]?.userbirthdisp} ({profile[0]?.userage} år)
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <AlternateEmailIcon className='potexticon' /> {profile[0]?.useremail} &nbsp; {pubEmail ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <PhoneAndroidIcon className='potexticon' /> {profile[0]?.userphone} &nbsp; {pubPhone ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <HomeIcon className='potexticon' /> {profile[0]?.useraddress}, {profile[0]?.userzipcode} ({profile[0]?.usercity})
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                <PlaceIcon className='potexticon' /> {profile[0]?.usergeolat} / {profile[0]?.usergeolong}
                                                            </Typography>
                                                            <p></p>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                <i>Passord sist endret {profile[0]?.userpassworddate} ({profile[0]?.userpasswordage} dager siden)</i>
                                                            </Typography>
                                                            {profile[0]?.userpasswordage > 60 &&
                                                                <Alert severity="error">Passordet ditt er gammelt, og bør endres!</Alert>
                                                            }
                                                            <p></p>
                                                            <Button variant="outlined" size="small" color="warning" onClick={handleClickProfileModalOpen}><EditIcon className='pobtnicon' /> Endre opplysninger</Button> <Button variant="outlined" color="warning" size="small" onClick={handleClickPasswordModalOpen}><EditIcon className='pobtnicon' /> Endre passord</Button>
                                                        </Grid> */}
                                                    {/* <Grid item xs={12}>
                                                            <Divider />
                                                            <br />
                                                            <Grid container>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <WhatsAppIcon className='potexticonxl' /> {profile[0]?.userdiscordid}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <FacebookIcon className='potexticonxl' /> <Link href={"https://facebook.com/" + profile[0]?.userfacebook} target='_blank'>{profile[0]?.userfacebook}</Link> &nbsp; {pubFacebook ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <InstagramIcon className='potexticonxl' /> <Link href={"https://instagr.am/" + profile[0]?.userinstagram} target='_blank'>{profile[0]?.userinstagram}</Link> &nbsp; {pubInstagram ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                                                        <MonochromePhotosIcon className='potexticonxl' /> {profile[0]?.usersnapchat} &nbsp; {pubSnapChat ? <PublicIcon color="success" className='potexticon' /> : <VpnLockIcon color="error" className='potexticon' />}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} md={12}>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </main>
                            </> : <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default Contact;