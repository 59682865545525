import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Backdrop, Box, CircularProgress, createTheme, CssBaseline, Grid, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';

const potoken = localStorage.getItem('potoken') || "";

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function HelpLogging() {
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        document.title = 'ParaOffice';
        setisLoading(false);
    }, []);


    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        {!isLoading ?
                            <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Gjennomgang av opptak
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            * Finn en fil du skal gå igjennom, og trykk på reserver.<br />
                                            * Last ned filen<br />
                                            * Finne relevant informasjon om filen, og oppdatere filinformasjon. Se detaljer nedenfor.<br />
                                            * Gå igjennom filen og logg alle hendelser som skal logges (se klasser).<br />
                                            * Lag klipp av alle klasse A/B, og morsomme logger.<br />
                                            * Marker filen som ferdig<br />
                                            <p><br /></p>
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Lage klipp
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            * Det skal kun lages klipp av klasse A, klasse B og morsomme logger.<br />
                                            * Når du skal lage et klipp får du opp automatisk forslag til tidspunkt og varighet. Dette må du tilpasse til å bli relevant ved å endre starttidspunkt og lengde. Her er det en del individuelle vurderinger som må tas.<br />
                                            * Et klipp skal sjekkes, og kan genereres på nytt med nytt tidspunkt og varighet mange ganger for å tilpasse at det blir riktig.<br />
                                            * På morsomme tags er det ikke nødvendig med mye ekstra tid før/etter poenget med klippet. Mens på klasse A/B kan det hende man vil ha med litt mer tid før og etter hovedpoenget for å få med omstendighetene rundt.<br />
                                            * Et klipp kan deles på discord for å få vurderinger og innspill fra andre i teamet.<br />
                                        </Typography>
                                        <p><br /></p>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Filinformasjon
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            Klokkeslett Her skrives det inn klokkeslett på når filen starter. På CCTV filer fra systemet vi benytter nå så finner man klokkeslettet i filnavnet, for eksempel: cctv1-15-10-2021_15-34-06.mkv, det er klokkeslett: 15:34:06.<br />
                                            Fil kategori Velg kategori som beskriver filen. Eksempelvis CCTV for alle CCTV filer, Etterforskning for alle vanlige runder filmet med HD kamera. Produksjon er for filer som er filmet ekstra med hensyn til videoproduksjon.<br />
                                            Beskrivelse Fyll inn en beskrivelse av filen. Dette er som oftest informasjon om hvem som er involvert og hvor de befinner seg. På CCTV genereres det automatisk utifra navn på område.<br />
                                            Status Informasjon om gjennomgangsstatus på filen. Oppdateres automatisk til ferdig ved klikk på knappen "marker som ferdig".<br />
                                            Tildelt Person som eventuelt har reservert filen for gjennomgang.<br />
                                            Ved endring av informasjonen over må du huske å trykke oppdater knappen.
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Logg klasser
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            * Klasse A - Brukes på funn som er veldig spesielle og bra, og som alle oppfatter på opptaket. Det skal alltid lages klipp av disse. Det er også klasse A opptak som er aktuelle for publisering på nettsiden. <br />Eksempler: Stemmer som alle hører, utstyr som reagerer veldig konkret på spørsmål, eller bra visuelle opptak.<p></p>
                                            * Klasse B - Funn som høyst sannsynlig er noe spesielt og ikke naturlig. Det skal alltid lages klipp av disse. Er det stor usikkerhet rundt om det er noe paranormalt så skal klasse C benyttes istedenfor.<p></p>
                                            * Klasse C - Funn på opptak som det er stor tvil om er noe spesielt, men som likevel kan vise seg å være noe og derfor fortsatt er viktig å ha logget. For eksempel at man hører skritt eller stemmer, men hvor det med stor sannsynlighet er noen av de som er tilstede som er kilden. Det kan også være utstyr går av veldig mye, uten at det virker som konkret respons. Disse vil ikke bli gjennomgått i en oppsummering, og det blir ikke laget klipp av de. Hvis det er en reaksjon fra noen i teamet på noe de opplever, men som du ikke greier å se/høre på opptaket, så skal det tagges som personlig opplevelse og ikke klasse C.<p></p>
                                            * Personlig Opplevelse - Når en eller flere personer i teamet reagerer på en hendelse, men som ikke oppfattes på opptak så benyttes denne klassen. Dette kan være noe visuelt eller lyder, men gjelder også spesielt når noen kommenterer følelser som for eksempel berøring, temperaturforandringer, hodepine o.l. Hvis det er en reaksjon på noe som blir dokumentert på enten video eller lyd, så skal det velges en klasse A/B/C og ikke logges som en personlig opplevelse.<p></p>
                                            * Morsomt - Alt som er av morsomme uttalelser/hendelser. Rare ting som blir sagt, folk som snubler/skvetter/promper, eller andre rare hendelser som oppstår. Når man tagger folk på morsome tags så tag kun den/de som er relevante i forhold til at det er en morsom situasjon, ikke alle som er tilstede.<p></p>
                                            * Info - All som skjer som kan være relevante opplysninger, brukes til for eksempel til å finne frem til hendelser som ikke har noe med noe paranormalt å gjøre. Kan være til hjelp for andre i teamet, og ved eventuell videoproduksjon. Skal ikke benyttes ved paranormale hendelser, da brukes klasser eller personlig opplevelse.<p></p>
                                            * Produksjon - Brukes for å logge informasjon som er relevant i forbindelse med en videoproduksjon, slik at man enkelt kan finne frem til relevante ting.<p></p>
                                            <b>Spesielt for CCTV og statiske kamera</b><p></p>
                                            Person tagging skal alltid benyttes på CCTV og statiske kamera, men aldri på vanlige opptak fra runder med håndholdte kamera, eller lyd-opptak.
                                            Brukes for å kunne spore tidspunkt og klokkeslett når ting skjer og hvor folk er.<p></p>
                                            * Person Inn - Benyttes når en eller flere personer kommer inn i området som filmes. Hvis det er flere personer som kommer inn etter hverandre, så er det tidspunktet for når første person kommer inn som benyttes.<p></p>
                                            * Person Ut - Benyttes når en eller flere personer har vært tilstede og forlater området som filmes. Hvis det er flere personer som går ut etter hverandre, så er det tidspunktet for når siste person forlater området som benyttes.<p></p>
                                            * Person Passerer - Hvis en eller flere personer passerer området som filmes, men ikke oppholder seg der mer enn i 30 sekunder så kan man benytte denne klassen istedenfor å logge både inn og ut. Tidspunktet som benyttes er når personen kommer inn.<p></p>
                                            * Person Tilstede - Benyttes kun hvis det er personer tilstede i det opptaket starter. Skal kun brukes i kombinasjon med tidspunkt 00:00:00.
                                        </Typography>





                                    </Grid>
                                </Grid>
                            </> : <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </>
                        }
                    </Box >
                </Box >
            </Box >
        </ThemeProvider >
    );
}

export default HelpLogging;