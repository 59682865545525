import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Autocomplete, Backdrop, Box, Button, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab, Tabs, TextField, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import PersonIcon from '@mui/icons-material/Person';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navigator from '../components/Nav';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../components/canvaspreview';
import { useDebounceEffect } from '../components/useDebounceEffect';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddCircle from '@mui/icons-material/AddCircle';
import PublishIcon from '@mui/icons-material/Publish';

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const APIURL = process.env.REACT_APP_APIURL;

const potoken = localStorage.getItem('potoken') || "";
const pomod = localStorage.getItem('pomod') || 0;
const pouserid = localStorage.getItem('pouserid') || 0;

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};
 
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Instagram() {
    const [instagramModal, setInstagramModal] = useState(false);
    const [instagramEditModal, setinstagramEditModal] = useState(false);
    const [editInstagramHash, setEditInstagramHash] = useState("");
    const [instagram, setInstagram] = useState<any[]>([]);
    const [instagramwaiting, setInstagramWaiting] = useState<any[]>([]);
    const [instagramPublished, setInstagramPublished] = useState<any[]>([]);
    const [instagramWiped, setInstagramWiped] = useState<any[]>([]);


    const [instagramEdit, setInstagramEdit] = useState<any[]>([]);
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale] = useState(1)
    const [rotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(4 / 5)

    const aspectoptions = [4 / 5, 1 / 1, 1.91 / 1];

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        setisLoading(true);
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob(async (blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            var aspectsetting = aspect?.toString();
            const formData = new FormData();
            formData.append("token", potoken);
            formData.append("instagramhash", editInstagramHash || "");
            formData.append("imageaspect", aspectsetting || "");
            formData.append("file[]", blob);
            await axios.post("https://poimg01.paraoffice.com/instagramupload.php", formData, { headers: { 'Content-Type': blob.type } })
                .then((response: any) => {
                    if (response.status === 200) {
                        getinstagram();
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setisLoading(false);
                });
        })
        setImageModal(false);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )



    const [imageModal, setImageModal] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 1) {
            getinstagramwaiting();
        }
        if (newValue === 2) {
            getinstagrampublished();
        }
        if (newValue === 3) {
            getinstagramwiped();
        }

    };

    const handleClickInstagramModalOpen = () => {
        setInstagramModal(true);
    };

    const handleClickInstagramModalClose = () => {
        setInstagramModal(false);
    };

    const handleClickImageModalOpen = (instagramhash: string) => {
        setEditInstagramHash(instagramhash);
        setImageModal(true);
    };

    const handleClickImageModalClose = () => {
        setImageModal(false);
    };



    const handleClickInstagramEditModalOpen = async (instagramhash: string) => {
        setisLoading(true);
        setEditInstagramHash(instagramhash);
        await axios.get(APIURL + "/v3/instagrampost/" + instagramhash, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstagramEdit(response.data);
                    setisLoading(false);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);





        setinstagramEditModal(true);
    };

    const handleClickInstagramEditModalClose = () => {
        setinstagramEditModal(false);
    };


    const [isLoading, setisLoading] = useState(true);



    async function getinstagram() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/instagram", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstagram(response.data);
                    setisLoading(false);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        getinstagram();
    }, []);


    async function getinstagramwaiting() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/instagram/waiting", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setInstagramWaiting(response.data);
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        getinstagram();
    }, []);


    async function getinstagrampublished() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/instagram/published", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setInstagramPublished(response.data);
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    async function getinstagramwiped() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/instagram/wiped", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setInstagramWiped(response.data);
                    setisLoading(false);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    useEffect(() => {
        document.title = 'ParaOffice';
        getinstagram();
    }, []);

    const handleSubmitInstagram = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickInstagramModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            instagramtext: data.get('instagramtext'),
            instagramposttime: data.get('instagramposttime')
        };
        axios.post(APIURL + "/v3/instagram", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getinstagram();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    const handleClickInstagramPostOK = async (instagramhash: string) => {
        var formdata = {};
        axios.post(APIURL + "/v3/instagrampostok/" + instagramhash, formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getinstagram();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    const handleClickInstagramPostWipe = async (instagramhash: string) => {
        axios.delete(APIURL + "/v3/instagrampostwipe/" + instagramhash, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getinstagram();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    const handleClickInstagramPublished = async (instagramhash: string) => {
        handleClickInstagramModalClose();
        var formdata = {};
        axios.post(APIURL + "/v3/instagrampostpublished/" + instagramhash, formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getinstagram();
                    getinstagramwaiting();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }



    const handleSubmitEditInstagram = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleClickInstagramEditModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            instagramtext: data.get('instagramtext'),
            instagramposttime: data.get('instagramposttime'),
        };
        axios.put(APIURL + "/v3/instagrampost/" + editInstagramHash, formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getinstagram();
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    {isSmUp ? null : (
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    )}
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth, background: 'rgba(0, 0, 0, 0.3)' } }}
                        sx={{ display: { sm: 'block', xs: 'none' } }}
                    />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 4 }}>
                        {!isLoading ?
                            <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    aria-label="tabs"
                                >
                                    <Tab icon={<FiberNewIcon className="poiconnew" />} iconPosition="start" label={"Nye forslag (" + instagram[0]?.instagramnew + ")"} {...a11yProps(0)} />
                                    <Tab icon={<HourglassEmptyIcon className="pocolorinfo" />} iconPosition="start" label={"Venter publisering (" + instagram[0]?.instagramwaiting + ")"} {...a11yProps(1)} />
                                    <Tab icon={<CheckIcon className="pogreen" />} iconPosition="start" label={"Publisert (" + instagram[0]?.instagramposted + ")"} />
                                    <Tab icon={<DeleteForeverIcon className="pored" />} iconPosition="start" label={"Slettet (" + instagram[0]?.instagramwiped + ")"} {...a11yProps(3)} />
                                </Tabs>

                                <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    open={imageModal}
                                    onClose={handleClickImageModalClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box component="form" onSubmit={handleSubmitInstagram}>
                                        <DialogTitle id="alert-dialog-title">
                                            Last opp nytt instagrambilde
                                        </DialogTitle>
                                        <DialogContent>
                                            <Autocomplete
                                                sx={{ mt: 1, mb: 5 }}
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                disablePortal
                                                id="imageaspect"
                                                options={aspectoptions}
                                                onChange={(e, v) => setAspect(v || undefined)}
                                                defaultValue={0.8}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label="Bildeaspekt"
                                                    name="imageaspect"
                                                />}
                                            />

                                            <input type="file" accept="image/*" onChange={onSelectFile} />
                                            {!!imgSrc && (
                                                <ReactCrop
                                                    crop={crop}
                                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                                    onComplete={(c) => setCompletedCrop(c)}
                                                    aspect={aspect}
                                                >
                                                    <img
                                                        ref={imgRef}
                                                        alt="Crop me"
                                                        src={imgSrc}
                                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                                        onLoad={onImageLoad}
                                                    />
                                                </ReactCrop>
                                            )}
                                            {!!completedCrop && (
                                                <>
                                                    <div>
                                                        <Typography>
                                                            Forhåndsvisning:
                                                        </Typography>
                                                        <canvas
                                                            ref={previewCanvasRef}
                                                            style={{
                                                                border: '1px solid black',
                                                                objectFit: 'contain',
                                                                width: 216,
                                                                height: 270,
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}


                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={onDownloadCropClick}
                                                size="small"
                                                variant="outlined"
                                                color="success"
                                            >Last opp</Button>
                                            <Button onClick={handleClickImageModalClose}
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                            >Avbryt</Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>




                                <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    open={instagramModal}
                                    onClose={handleClickInstagramModalClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        Opprett ny instagram post (bildet legges til etterpå)
                                    </DialogTitle>
                                    <Box component="form" onSubmit={handleSubmitInstagram}>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            rows="10"
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="instagramtext"
                                                            label="Bildetekst"
                                                            name="instagramtext"
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        type="datetime-local"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="instagramposttime"
                                                        label="Ønsket tidspunkt for publisering"
                                                        name="instagramposttime"
                                                    />
                                                </Grid>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <SaveIcon className='pobtnicon' />
                                                Legg til
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={handleClickInstagramModalClose}
                                            >
                                                <CloseIcon className='pobtnicon' />
                                                Avbryt
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>



                                <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    open={instagramEditModal}
                                    onClose={handleClickInstagramEditModalClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        Endre instagrampost
                                    </DialogTitle>
                                    <Box component="form" onSubmit={handleSubmitEditInstagram}>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            rows="10"
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="instagramtext"
                                                            label="Bildetekst"
                                                            name="instagramtext"
                                                            defaultValue={instagramEdit[0]?.instagramtext}
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        type="datetime-local"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="instagramposttime"
                                                        label="Ønsket tidspunkt for publisering"
                                                        name="instagramposttime"
                                                        defaultValue={instagramEdit[0]?.instaposttimeformat}
                                                    />
                                                </Grid>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <SaveIcon className='pobtnicon' />
                                                Lagre
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={handleClickInstagramEditModalClose}
                                            >
                                                <CloseIcon className='pobtnicon' />
                                                Avbryt
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>






                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TabPanel value={tabValue} index={0}>
                                            <Grid container>
                                                <Grid item xs={12} pb={2}>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="success"
                                                        onClick={handleClickInstagramModalOpen}
                                                    >
                                                        <AddCircle className='pobtnicon' />
                                                        Legg til ny
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        {instagram[0]?.posts.map(({ instagramuid, instagramuser, instagramhash, instagramposttime, instaposttimedisp, instagramtext, instagramts }: any, index: number) => {
                                                            return <Grid item xs={3} p={1}>
                                                                <Box className='pobox'>
                                                                    <Box m={1}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            sx={{ display: { xs: 'block' } }}
                                                                            image={"https://poimg01.paraoffice.com/instagram/" + instagramhash + ".png?" + Date.now()}
                                                                        />
                                                                        <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo'>
                                                                            <PersonIcon className='potexticon' /> {instagramuser} &nbsp; <PublishIcon className='potexticon' /> {instaposttimedisp}
                                                                        </Typography>
                                                                        <Card variant="outlined">
                                                                            <CardContent>
                                                                                <Typography variant="caption" color="text.secondary">
                                                                                    {instagramtext?.split('\n').map((item: any, key: any) => {
                                                                                        return <span key={key}>{item}<br /></span>
                                                                                    })}
                                                                                </Typography>
                                                                            </CardContent>
                                                                        </Card>
                                                                        <p></p>
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color="warning"
                                                                            onClick={e => handleClickImageModalOpen(instagramhash)}
                                                                            sx={{ mr: 1 }}
                                                                        >
                                                                            <EditIcon className='pobtnicon' />
                                                                            Bilde
                                                                        </Button>
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color="warning"
                                                                            onClick={e => handleClickInstagramEditModalOpen(instagramhash)}
                                                                            sx={{ mr: 1 }}
                                                                        >
                                                                            <EditIcon className='pobtnicon' />
                                                                            Tekst
                                                                        </Button>
                                                                        {(pomod === "1") ?
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="success"
                                                                                onClick={e => handleClickInstagramPostOK(instagramhash)}
                                                                                sx={{ mr: 1 }}
                                                                            >
                                                                                <CheckIcon className='pobtnicon' />
                                                                                OK
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="success"
                                                                                sx={{ mr: 1 }}
                                                                                disabled
                                                                            >
                                                                                <CheckIcon className='pobtnicon' />
                                                                                OK
                                                                            </Button>
                                                                        }
                                                                        {((instagramuid.toString() === pouserid?.toString()) || (pomod === "0")) ?
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="error"
                                                                                onClick={e => handleClickInstagramPostWipe(instagramhash)}
                                                                                sx={{ mr: 1 }}
                                                                            >
                                                                                <DeleteForeverIcon className='pobtnicon' />
                                                                                Slett
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                color="error"
                                                                                sx={{ mr: 1 }}
                                                                                disabled
                                                                            >
                                                                                <DeleteForeverIcon className='pobtnicon' />
                                                                                Slett
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        })
                                                        }
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={1}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2} p={2}>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                {instagramwaiting?.map(({ instagramuid, instagramuser, instagramhash, instagramposttime, instaposttimedisp, instagramtext, instagramts }: any, index: number) => {
                                                                    return <Grid item xs={3} p={1}>
                                                                        <Box className='pobox'>
                                                                            <Box m={1}>
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    sx={{ display: { xs: 'block' } }}
                                                                                    image={"https://poimg01.paraoffice.com/instagram/" + instagramhash + ".png?" + Date.now()}
                                                                                />
                                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo'>
                                                                                    <PersonIcon className='potexticon' /> {instagramuser} &nbsp; <PublishIcon className='potexticon' /> {instaposttimedisp}
                                                                                </Typography>
                                                                                <Card variant="outlined">
                                                                                    <CardContent>
                                                                                        <Typography variant="caption" color="text.secondary">
                                                                                            {instagramtext?.split('\n').map((item: any, key: any) => {
                                                                                                return <span key={key}>{item}<br /></span>
                                                                                            })}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </Card>
                                                                                <p></p>
                                                                                {(pomod === "1") ?
                                                                                    <div>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="warning"
                                                                                            onClick={e => handleClickInstagramEditModalOpen(instagramhash)}
                                                                                            sx={{ mr: 1 }}
                                                                                        >
                                                                                            <EditIcon className='pobtnicon' />
                                                                                            Tekst
                                                                                        </Button>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="success"
                                                                                            onClick={e => handleClickInstagramPublished(instagramhash)}
                                                                                            sx={{ mr: 1 }}
                                                                                        >
                                                                                            <CheckIcon className='pobtnicon' />
                                                                                            Publisert
                                                                                        </Button>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="error"
                                                                                            onClick={e => handleClickInstagramPostWipe(instagramhash)}
                                                                                            sx={{ mr: 1 }}
                                                                                        >
                                                                                            <DeleteForeverIcon className='pobtnicon' />
                                                                                            Slett
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="warning"
                                                                                            sx={{ mr: 1 }}
                                                                                            disabled
                                                                                        >
                                                                                            <EditIcon className='pobtnicon' />
                                                                                            Tekst
                                                                                        </Button>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="success"
                                                                                            sx={{ mr: 1 }}
                                                                                            disabled
                                                                                        >
                                                                                            <CheckIcon className='pobtnicon' />
                                                                                            Publisert
                                                                                        </Button>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="error"
                                                                                            sx={{ mr: 1 }}
                                                                                            disabled
                                                                                        >
                                                                                            <DeleteForeverIcon className='pobtnicon' />
                                                                                            Slett
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                })
                                                                }
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </TabPanel>











                                        <TabPanel value={tabValue} index={2}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2} p={2}>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                {instagramPublished?.map(({ instagramuid, instagramuser, instagramhash, instagramposttime, instaposttimedisp, instagramtext, instagramts }: any, index: number) => {
                                                                    return <Grid item xs={3} p={1}>
                                                                        <Box className='pobox'>
                                                                            <Box m={1}>
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    sx={{ display: { xs: 'block' } }}
                                                                                    image={"https://poimg01.paraoffice.com/instagram/" + instagramhash + ".png?" + Date.now()}
                                                                                />
                                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo'>
                                                                                    <PersonIcon className='potexticon' /> {instagramuser} &nbsp; <PublishIcon className='potexticon' /> {instaposttimedisp}
                                                                                </Typography>
                                                                                <Card variant="outlined">
                                                                                    <CardContent>
                                                                                        <Typography variant="caption" color="text.secondary">
                                                                                            {instagramtext?.split('\n').map((item: any, key: any) => {
                                                                                                return <span key={key}>{item}<br /></span>
                                                                                            })}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </Card>
                                                                                <p></p>
                                                                                {(pomod === "1") ?
                                                                                    <div>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="error"
                                                                                            onClick={e => handleClickInstagramPostWipe(instagramhash)}
                                                                                            sx={{ mr: 1 }}
                                                                                        >
                                                                                            <DeleteForeverIcon className='pobtnicon' />
                                                                                            Slett
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            color="error"
                                                                                            sx={{ mr: 1 }}
                                                                                            disabled
                                                                                        >
                                                                                            <DeleteForeverIcon className='pobtnicon' />
                                                                                            Slett
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                })
                                                                }
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </TabPanel>







                                        <TabPanel value={tabValue} index={3}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2} p={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                {instagramWiped?.map(({ instagramuid, instagramuser, instagramhash, instagramposttime, instaposttimedisp, instagramtext, instagramts }: any, index: number) => {
                                                                    return <Grid item xs={3} p={1}>
                                                                        <Box className='pobox'>
                                                                            <Box m={1}>
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    sx={{ display: { xs: 'block' } }}
                                                                                    image={"https://poimg01.paraoffice.com/instagram/" + instagramhash + ".png?" + Date.now()}
                                                                                />
                                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo'>
                                                                                    <PersonIcon className='potexticon' /> {instagramuser} &nbsp; <PublishIcon className='potexticon' /> {instaposttimedisp}
                                                                                </Typography>
                                                                                <Card variant="outlined">
                                                                                    <CardContent>
                                                                                        <Typography variant="caption" color="text.secondary">
                                                                                            {instagramtext?.split('\n').map((item: any, key: any) => {
                                                                                                return <span key={key}>{item}<br /></span>
                                                                                            })}
                                                                                        </Typography>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                })
                                                                }
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </TabPanel>




                                    </Grid>
                                </Grid>








                            </> : <>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default Instagram;